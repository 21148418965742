import React from "react";
import { Input, InputRef } from "antd";
import { TextAreaProps } from "antd/es/input";   

import "./styles.scss";

const CustomTextArea = React.forwardRef<InputRef, TextAreaProps>(({ className, ...props }, ref) => {
  return <Input.TextArea ref={ref} className={`custom-input ${className ?? ""}`} {...props} />;
});

export default CustomTextArea;
