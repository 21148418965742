import { Button, Form } from "antd";
import { FormProps } from "antd/lib";
import { useEffect, useState } from "react";
import { REQUIRED } from "src/commons/messages/validate";
import MethodSubmitButton from "src/components/03.buttons/MethodSubmitButton";
import ModalWithLogo from "src/components/04.modals/ModalWithLogo";
import { useErc720Contract } from "src/web3/contracts/useErc720Contract";
import CustomInput from "src/components/07.inputs";
import { customToast } from "src/components/02.toasts";

import "./styles.scss";

type Props = {
  token: TokenType;
  address: string;
  open: boolean;
  onClose: () => void;
};

const FormItem = Form.Item<EditDocumentValues>;

const EditDocumentModal = (props: Props) => {
  const { address, open, onClose } = props;
  const [form] = Form.useForm<EditDocumentValues>();
  const [loading, setLoading] = useState(false);
  const { setDocumentUri, documentUri } = useErc720Contract(address);

  const onFinish = async (values: EditDocumentValues) => {
    if (!setDocumentUri) return;
    setLoading(true);
    try {
      const transaction = await setDocumentUri(values.document_url);
      await transaction?.wait(1);
      customToast.success("Update Document Successfully");
      onClose();
    } catch (error: any) {
      console.log({ error });
      const errors: StringObject = error.response?.data?.errors || { document_url: "Update Document Failed" };
      form.setFields(Object.entries(errors).map(([key, value]) => ({ name: key, errors: [value] })));
      form.getFieldInstance(Object.keys(errors)[0] || "document_url")?.focus();
      const errMessage = Object.values(errors)[0];
      customToast.error(typeof errMessage === "string" ? errMessage : "Update Document Failed");
    }
    setLoading(false);
  };

  const onFinishFailed: FormProps["onFinishFailed"] = errorInfo => {
    const namePath = errorInfo.errorFields[0].name?.[0];
    form.getFieldInstance(namePath)?.focus();
  };

  useEffect(() => {
    const getData = async () => {
      if (!documentUri) return;
      const document = await documentUri();
      form.resetFields();
      form.setFieldValue("document_url", document);
    };
    if (documentUri) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, documentUri]);

  return (
    <ModalWithLogo open={open} footer={false}>
      <Form
        form={form}
        className="form token-method-form"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        layout="horizontal"
        onFinish={onFinish}
        validateMessages={{ required: REQUIRED() }}
        onFinishFailed={onFinishFailed}
      >
        <div className="token-method-title">Edit Asset Document</div>
        <FormItem
          colon
          label="Document URL"
          name="document_url"
          rules={[
            {
              type: "url",
              message: "The URL format is invalid. Please ensure it starts with 'http://' or 'https://.",
            },
            {
              required: true,
              message: "Document URL is required.",
            },
          ]}
        >
          <CustomInput placeholder="https://" />
        </FormItem>
        <div className="form-submit">
          <Button className="cancel-button" onClick={onClose}>
            Back
          </Button>
          <MethodSubmitButton htmlType="submit" type="primary" loading={loading}>
            Submit
          </MethodSubmitButton>
        </div>
      </Form>
    </ModalWithLogo>
  );
};

export default EditDocumentModal;
