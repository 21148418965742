import { useWeb3React } from "@web3-react/core";
import { useEffect, useState } from "react";
import { useSelector } from "src/store";
import { useErc720Contract } from "../contracts/useErc720Contract";

export const useBalances = (address: string) => {
  const isAuth = useSelector((state: RootState) => state.auth.isAuthChecking);
  const selectedNetwork = useSelector((state: RootState) => state.system.selectedNetwork);
  const { account } = useWeb3React();
  const [balance, setBalance] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const { balanceOf } = useErc720Contract(address);

  useEffect(() => {
    setBalance(null);
    if (account && isAuth && selectedNetwork && balanceOf) {
      const getTokenBalance = async () => {
        setLoading(true);
        try {
          const balance = await balanceOf(account);
          setBalance(balance.toString());
          setError(null);
        } catch (error) {
          console.log(error);
          setError((error as any).messsage || "Cannot get native balance");
        }
        setLoading(false);
      };

      getTokenBalance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, isAuth, selectedNetwork, balanceOf]);

  return { balance, loading, error };
};
