import React from "react";
import { Form, Input } from "antd";
import { Rule } from "antd/lib/form";
import CurrencyInputField, { CurrencyInputProps } from "react-currency-input-field";
import CustomInput from ".";

import "./styles.scss";

interface Props extends Omit<CurrencyInputProps, "form" | "onChange"> {
  name: string;
  rules?: Rule[];
  onChange?: (value: string) => void;
  addonAfter?: React.ReactNode;
  addonBefore?: React.ReactNode;
  value?: string;
  dependencies?: string[];
}

const NumberInput = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    name,
    rules,
    className,
    decimalsLimit = 6,
    onChange,
    addonAfter,
    addonBefore,
    value,
    dependencies,
    ...inputProps
  } = props;

  return (
    <>
      <Form.Item dependencies={[...(dependencies || []), name]} noStyle>
        {({ getFieldValue, setFieldsValue, validateFields, getFieldError }) => (
          <span className="ant-input-wrapper ant-input-group">
            {addonBefore && <span className="ant-input-group-addon">{addonBefore}</span>}
            <CurrencyInputField
              ref={ref}
              value={getFieldValue(name) ?? value ?? ""}
              decimalsLimit={decimalsLimit}
              groupSeparator=","
              decimalSeparator="."
              onValueChange={(value?: string) => {
                setFieldsValue({ [name]: value !== undefined ? value : "" });
                if (rules) validateFields([name]);
                onChange?.(value ?? "");
              }}
              allowNegativeValue={false}
              className={`number-input ${className || ""} ${getFieldError(name)?.[0] ? "ant-input-status-error" : ""}`}
              customInput={CustomInput}
              {...inputProps}
            />
            {addonAfter && <span className="ant-input-group-addon">{addonAfter}</span>}
          </span>
        )}
      </Form.Item>
      <Form.Item name={name} className="input-error-only" rules={rules}>
        <Input hidden />
      </Form.Item>
    </>
  );
});

export default NumberInput;
