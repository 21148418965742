import { Route, Routes } from "react-router-dom";
import { routers } from "./commons/constants/routers";
import LandingPage from "./pages/LandingPage";
import NotFound from "./pages/NotFound";
import CreateTokenPage from "./pages/CreateTokenPage";
import ManageTokenPage from "./pages/ManageTokenPage";
import DistributeTokenPage from "./pages/DistributeTokenPage";
import CreateNFTPage from "./pages/CreateNFTPage";
import ManageNFTPage from "./pages/ManageNFTPage";
import NFTMintingPage from "./pages/NFTMintingPage";
import VestingTokenPage from "./pages/VestingTokenPage";
import ClaimVestingPage from "./pages/ClaimVestingPage";

const Routers: React.FC = () => {
  return (
    <Routes>
      <Route path={routers.DASHBOARD} element={<LandingPage />} />
      <Route path={routers.TOKEN_CREATE} element={<CreateTokenPage />} />
      <Route path={routers.TOKEN_MANAGER} element={<ManageTokenPage />} />
      <Route path={routers.TOKEN_DISTRIBUTE} element={<DistributeTokenPage />} />
      <Route path={routers.NFT_CREATE} element={<CreateNFTPage />} />
      <Route path={routers.NFT_MANAGER} element={<ManageNFTPage />} />
      <Route path={routers.NFT_MINTING} element={<NFTMintingPage />} />
      <Route path={routers.TOKEN_VESTING} element={<VestingTokenPage />} />
      <Route path={routers.TOKEN_CLAIM} element={<ClaimVestingPage />} />
      <Route path={routers.NOT_FOUND} element={<NotFound />} />
    </Routes>
  );
};

export default Routers;
