import { BaseService } from "./base-service";

export class AuthService extends BaseService {
  static path = "auth";

  static getPublicKey = () => this.get<GetPublicKeyResponse>(`/${this.path}/connect-wallet/public-key`);

  static postLoginSign = (body: LoginBody) => this.post<LoginSuccessResponse>(`/${this.path}/connect-wallet`, body);

  static getProfile = () => this.get<UserProfile>(`/${this.path}/me`);
}
