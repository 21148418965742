import ResizeIcon from "../08.resize-icon";
import {
  AddAddressIcon,
  AddLiquidityPoolIcon,
  BlacklistIcon,
  CanBurnIcon,
  CanMintIcon,
  ChangeTokenLimitIcon,
  EditDeflationSettingsIcon,
  EditSssetDocumentationIcon,
  EditTransactionFeeIcon,
  ForceTransactionIcon,
  ManualVerifyIcon,
  PauseIcon,
  RenounceOwnershipIcon,
} from "src/commons/resources/icons";
import { formatNumber } from "src/commons/utils/functions/formatNumber";
import { Col, Row, Spin } from "antd";
import MethodButton from "../03.buttons/MethodButton";
import { useCallback, useEffect, useState } from "react";
import MintTokenModal from "./methods/mint-token";
import { useErc720Contract } from "src/web3/contracts/useErc720Contract";
import ChangeOwnerModal from "./methods/change-owner";
import { useWeb3React } from "@web3-react/core";
import RenouncingOwnershipModal from "./methods/renoucing-ownership";
import BurnTokenModal from "./methods/burn-token";
import { useNavigate } from "react-router-dom";
import { PATHS } from "src/commons/constants/routers";
import ChangeLimitTokenModal from "./methods/change-limit-tokens";
import ManualVerifyModal from "./methods/manual-verify";
import { TokenService } from "src/services/token-service";
import ChangeTxsFeeModal from "./methods/change-txs-fee";
import { LoadingOutlined } from "@ant-design/icons";
import BlacklistAddressModal from "./methods/blacklist-address";
import WhitelistAddressModal from "./methods/whitelist-address";
import PauseTokenModal from "./methods/pause-token";
import TransferTokenModal from "./methods/transfer-token";
import ChangeBurnFeeModal from "./methods/change-burn-fee";
import AddToLiquidityPoolModal from "./methods/add-to-liquidity-pool";
import EditDocumentModal from "./methods/edit-document";
import { useSelector } from "react-redux";

import "./styles.scss";

type Props = {
  token: TokenType;
  address: string;
  refresh: () => void;
};

const methods: TokenMethod[] = [
  { key: "can_mint", icon: CanMintIcon, label: "Mint Token" },
  { key: "can_burn", icon: CanBurnIcon, label: "Burn Token" },
  { key: "owner_address", icon: CanMintIcon, label: "Change Owner" },
  { key: "renouce-ownership", icon: RenounceOwnershipIcon, label: "Renounce Ownership" },
  { key: "can_blacklist", icon: BlacklistIcon, label: "Blacklist Address" },
  { key: "can_pause", icon: PauseIcon, label: "Pause" },
  { key: "can_charge_transaction_fee", icon: EditTransactionFeeIcon, label: "Edit Transaction Fee" },
  { key: "is_apply_burn_fee", icon: EditDeflationSettingsIcon, label: "Edit Deflation Settings" },
  { key: "add-to-liquidity-pool", icon: AddLiquidityPoolIcon, label: "Add Liquidity Pool " },
  { key: "is_have_documentation", icon: EditSssetDocumentationIcon, label: "Edit Asset Documentation" },
  { key: "is_limited_tokens_per_address", icon: ChangeTokenLimitIcon, label: "Change Token Limit" },
  { key: "can_be_forced_transferred", icon: ForceTransactionIcon, label: "Force Transaction" },
];

const TokenDetail = ({ address, token, refresh }: Props) => {
  const {
    name,
    symbol,
    decimals,
    provider: { network_id },
  } = token;
  const { account } = useWeb3React();
  const [visible, setVisible] = useState<TokenMethod["key"]>(null);
  const [whitelists, setWhitelists] = useState<string[]>([]);
  const [verification, setVerification] = useState<TokenVerification | null>(null);
  const [loadingWhitelist, setLoadingWhitelist] = useState(false);
  const [loadingVerification, setLoadingVerification] = useState(false);
  const [totalSupply, setTotalSupply] = useState("0");
  const [userBalance, setUserBalance] = useState("0");
  const [maxToken, setMaxToken] = useState("0");

  const networks = useSelector(({ system }: RootState) => system.networks);

  const {
    totalSupply: contractTotalSupply,
    balanceOf,
    maxTokenAmountPerAddress,
    getWhitelistedAddresses,
  } = useErc720Contract(address);

  const navigate = useNavigate();

  const getTotalSupply = useCallback(async () => {
    try {
      if (!contractTotalSupply) return;
      const totalSupply = await contractTotalSupply();
      setTotalSupply(totalSupply.toString());
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, contractTotalSupply]);

  useEffect(() => {
    getTotalSupply();
  }, [getTotalSupply]);

  const getUserBalance = useCallback(async () => {
    try {
      if (!account || !balanceOf) return;
      const userBalance = await balanceOf(account);
      setUserBalance(userBalance.toString());
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, balanceOf]);

  const getBalanceOf = useCallback(
    async (address?: string) => {
      try {
        if (!address || !balanceOf) return null;
        const userBalance = await balanceOf(address);
        return userBalance;
      } catch (error) {
        console.log(error);
        return null;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [balanceOf]
  );

  useEffect(() => {
    getUserBalance();
  }, [getUserBalance]);

  const getMaxToken = useCallback(async () => {
    try {
      if (!maxTokenAmountPerAddress) return;
      const maxToken = await maxTokenAmountPerAddress();
      setMaxToken(Number(maxToken.toString()) ? maxToken.toString() : Infinity.toString());
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, maxTokenAmountPerAddress]);

  useEffect(() => {
    getMaxToken();
  }, [getMaxToken]);

  const getWhiteLists = useCallback(async () => {
    if (!getWhitelistedAddresses) return;
    setLoadingWhitelist(true);
    setWhitelists([]);
    try {
      const whitelists = await getWhitelistedAddresses();
      setWhitelists(whitelists);
    } catch (error) {
      console.log({ error });
    }
    setLoadingWhitelist(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, getWhitelistedAddresses]);

  useEffect(() => {
    getWhiteLists();
  }, [getWhiteLists]);

  useEffect(() => {
    setVisible(null);
    let unmount = false;
    const getERC20TokenVerification = async () => {
      setLoadingVerification(true);

      setVerification(null);
      try {
        const { data } = await TokenService.getERC20TokenVerification(address);
        if (!unmount) setVerification(data);
      } catch (error) {
        console.log({ error });
      }

      setLoadingVerification(false);
    };
    getERC20TokenVerification();
    return () => {
      unmount = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  const isEnable = (key: TokenMethod["key"]): boolean => {
    if (key === "manual-verify" || key === "renouce-ownership" || key === "owner_address") return true;
    if (!key || key === "add-to-liquidity-pool") return false;
    return !!token[key];
  };

  const handleClose = () => setVisible(null);

  const changeOwnerSuccess = () => {
    refresh();
    navigate(PATHS.managerToken());
  };

  const tokenNetwork = networks.find(item => item.network_id === network_id);

  return (
    <div className="token-detail">
      <div className="token-detail-header">
        <div className="token-detail-info">
          <div className="token-info-label">
            <div className="token-summary">
              {token.name} ({symbol})
            </div>
            <div className="token-address">{address}</div>
          </div>
          <div className="token-info-value">
            {!!tokenNetwork && (
              <div className="token-network">
                <img src={tokenNetwork.icon_urls[0]} alt={tokenNetwork.name} />
                <span>{tokenNetwork.name}</span>
              </div>
            )}
          </div>
        </div>
        <div className="token-detail-info">
          <div className="token-info-label">Token Name</div>
          <div className="token-info-value">{name}</div>
        </div>
        <div className="token-detail-info">
          <div className="token-info-label">Total Supply</div>
          <div className="token-info-value">{formatNumber(totalSupply, decimals)}</div>
        </div>
      </div>
      <div className="token-methods">
        <MethodButton
          className="manual-verify"
          onClick={() => setVisible("manual-verify")}
          loading={loadingVerification}
        >
          {!loadingVerification && <ResizeIcon icon={ManualVerifyIcon} height={16} />}
          Manual Verify
        </MethodButton>
        <Row>
          {methods.map(({ key, icon, label }, index) => (
            <Col key={index} span={24} lg={12} className="token-method-col">
              <MethodButton disabled={!isEnable(key)} onClick={() => setVisible(key)}>
                <ResizeIcon icon={icon} height={20} />
                {label}
              </MethodButton>
            </Col>
          ))}
        </Row>

        <div className="whitelist-container">
          <div className="whitelist-title">Whitelist Addresses</div>
          <div className={`whitelists ${loadingWhitelist ? "loading" : ""}`}>
            {loadingWhitelist && <Spin indicator={<LoadingOutlined size={24} />} />}
            {whitelists.map(item => (
              <div className="whitelist-item">{item}</div>
            ))}
          </div>
          <MethodButton
            disabled={!token.is_require_whitelist}
            className="add-whitelist"
            onClick={() => setVisible("is_require_whitelist")}
          >
            <ResizeIcon icon={AddAddressIcon} height={20} />
            Add Address
          </MethodButton>
        </div>
      </div>
      <ManualVerifyModal verification={verification} open={visible === "manual-verify"} onClose={handleClose} />
      <MintTokenModal
        address={address}
        token={token}
        open={visible === "can_mint"}
        totalSupply={totalSupply}
        getTotalSupply={getTotalSupply}
        maxToken={maxToken}
        userBalance={userBalance}
        getUserBalance={getUserBalance}
        onClose={handleClose}
      />
      <BurnTokenModal
        address={address}
        token={token}
        open={visible === "can_burn"}
        totalSupply={totalSupply}
        getTotalSupply={getTotalSupply}
        userBalance={userBalance}
        getUserBalance={getUserBalance}
        onClose={handleClose}
      />
      <ChangeOwnerModal
        address={address}
        token={token}
        open={visible === "owner_address"}
        totalSupply={totalSupply}
        onClose={handleClose}
        onSuccess={changeOwnerSuccess}
      />
      <RenouncingOwnershipModal
        address={address}
        open={visible === "renouce-ownership"}
        onClose={handleClose}
        onSuccess={changeOwnerSuccess}
      />
      <BlacklistAddressModal
        address={address}
        token={token}
        open={visible === "can_blacklist"}
        totalSupply={totalSupply}
        onClose={handleClose}
      />
      <PauseTokenModal
        address={address}
        token={token}
        open={visible === "can_pause"}
        totalSupply={totalSupply}
        onClose={handleClose}
      />
      <ChangeTxsFeeModal
        address={address}
        token={token}
        open={visible === "can_charge_transaction_fee"}
        totalSupply={totalSupply}
        onClose={handleClose}
      />
      <ChangeBurnFeeModal
        address={address}
        token={token}
        open={visible === "is_apply_burn_fee"}
        totalSupply={totalSupply}
        onClose={handleClose}
      />
      <AddToLiquidityPoolModal
        address={address}
        token={token}
        open={visible === "add-to-liquidity-pool"}
        totalSupply={totalSupply}
        onClose={handleClose}
      />
      <ChangeLimitTokenModal
        address={address}
        token={token}
        open={visible === "is_limited_tokens_per_address"}
        totalSupply={totalSupply}
        maxToken={maxToken}
        getMaxToken={getMaxToken}
        onClose={handleClose}
      />
      <TransferTokenModal
        address={address}
        token={token}
        open={visible === "can_be_forced_transferred"}
        totalSupply={totalSupply}
        getUserBalance={getUserBalance}
        onClose={handleClose}
        balanceOf={getBalanceOf}
      />
      <WhitelistAddressModal
        address={address}
        token={token}
        open={visible === "is_require_whitelist"}
        totalSupply={totalSupply}
        onClose={handleClose}
        getWhiteLists={getWhiteLists}
      />
      <EditDocumentModal
        address={address}
        token={token}
        open={visible === "is_have_documentation"}
        onClose={handleClose}
      />
    </div>
  );
};

export default TokenDetail;
