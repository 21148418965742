import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useConnectWallet } from "src/web3/hooks";
import { PATHS } from "src/commons/constants/routers";
import DisconnectWalletModal from "src/components/04.modals/DisconnectWalletModal";

import "./styles.scss";

export interface ProfileDetailContentProps {
  isOpenDropdownWrapper?: boolean;
}

const Content: React.FC<ProfileDetailContentProps> = ({ isOpenDropdownWrapper }) => {
  const isAuth = useSelector((state: RootState) => !!state.user.currentAccount);
  const { disconnectWallet } = useConnectWallet();
  const navigate = useNavigate();

  const [isOpenDisconnect, setIsOpenDisconnect] = useState<boolean>(false);

  const handleDisconnect = () => {
    disconnectWallet();
    navigate(PATHS.default);
  };

  return (
    <>
      {isOpenDropdownWrapper && isAuth && (
        <button className="logout-button" onClick={() => setIsOpenDisconnect(true)}>
          Disconnect
        </button>
      )}
      <DisconnectWalletModal
        isOpen={isOpenDisconnect}
        onClose={() => setIsOpenDisconnect(false)}
        onConfirm={handleDisconnect}
      />
    </>
  );
};

export default Content;
