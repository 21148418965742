import "./styles.scss";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Form, Row, Table } from "antd";
import GradientButton from "src/components/03.buttons/GradientButton";
import { PATHS } from "src/commons/constants/routers";
import ResizeIcon from "src/components/08.resize-icon";
import { IdeaIcon } from "src/commons/resources/icons";
import NotFound from "../NotFound";
import { useSelector } from "react-redux";
import { useWeb3React } from "@web3-react/core";
import { APIS } from "src/commons/constants/apis";
import { useFetch } from "src/commons/hooks/useFetch";
import useFetchList from "src/commons/hooks/useFetchList";
import CustomInput from "src/components/07.inputs";
import { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import { ellipseAddress } from "src/commons/utils/functions/ellipseAddress";
import ClaimTokenForm from "src/components/05.forms/ClaimTokenForm";
import { formatNumber } from "src/commons/utils/functions/formatNumber";
import { useClaimAbles } from "src/web3/hooks/useClaimAbles";
import { useVestingContract } from "src/web3/contracts/useVestingContract";

const ideas = [
  "Gain control over token release.",
  "Ensure a controlled and gradual token distribution",
  "Safeguard your project's sustainability.",
  "Build trust with secure and transparent vesting schedules",
];

const perPage = 10;

const ClaimVestingPage = () => {
  const [value, setValue] = useState("");
  const [onClaim, setOnClaim] = useState<ClaimItem | null>(null);
  const { address } = useParams<{ address?: string }>();
  const isAuth = useSelector(({ user }: RootState) => !!user.currentAccount);
  const chainId = useSelector(({ system }: RootState) => system.chainId);
  const selectedNetwork = useSelector(({ system }: RootState) => system.selectedNetwork);
  const { account } = useWeb3React();
  const logged = isAuth && account;

  const { data, loading, update } = useFetchList<ClaimItem>(logged ? APIS.CLAIM_LIST(address) : "", {
    perPage,
    beneficiary_address: account,
    network_id: chainId,
  });

  const { data: vesting, loading: loadingVesting } = useFetch<VestingItem>(APIS.VESTING_DETAIL(address));
  const { data: token } = useFetch<TokenType>(vesting?.token_address ? APIS.TOKEN_DETAIL(vesting.token_address) : "");

  const { getVestingSchedule } = useVestingContract(vesting?.address || "");
  const {
    data: claimables,
    loading: loadingClaimable,
    refetch,
    refetchAll,
  } = useClaimAbles(vesting?.address || "", data);
  const navigate = useNavigate();

  const handleSubmit = () => {
    navigate(PATHS.claimToken(value.split("/").pop()));
  };

  useEffect(() => {
    const interval = setInterval(() => refetchAll(), 60000);

    return () => {
      clearInterval(interval);
    };
  }, [refetchAll]);

  const onSuccess = async () => {
    if (!onClaim || !getVestingSchedule) return;
    const updateClaimed = async () => {
      try {
        const schedule = await getVestingSchedule(onClaim.schedule_id);
        update(data => {
          const index = data.findIndex(item => item.schedule_id === onClaim.schedule_id);
          if (index === -1) return data;

          data[index] = { ...data[index], claimed: schedule[9].toString() };
          return [...data];
        });
      } catch (error) {
        console.log(error);
      }
    };

    Promise.all([updateClaimed(), refetch(onClaim.schedule_id)]);
  };

  const columns: ColumnsType<ClaimItem> = [
    {
      title: "Name",
      dataIndex: "name",
      width: 150,
      render: (_, __, index) => (
        <span>
          Name {index < 9 ? 0 : ""}
          {index + 1}
        </span>
      ),
    },
    {
      title: "Claimed",
      dataIndex: "claimed",
      width: 150,
      render: (claimed: string, { vesting }) => <span>{formatNumber(claimed, vesting.erc20.decimals)}</span>,
    },
    {
      title: "Claimable",
      width: 150,
      dataIndex: "schedule_id",
      render: (schedule_id: string, { vesting, claimed }) => (
        <span>{formatNumber(claimables[schedule_id] ?? claimed, vesting.erc20.decimals)}</span>
      ),
    },
    {
      title: "Allocation",
      dataIndex: "total_amount",
      width: 150,
      render: (total_amount: string, { vesting }) => <span>{formatNumber(total_amount, vesting.erc20.decimals)}</span>,
    },
    {
      title: "Vesting Time",
      dataIndex: "start_date",
      width: 150,
      render: (start_date: string, { duration }: ClaimItem) => (
        <span>
          {dayjs(start_date).format("YYYY/MM/DD")} - {dayjs(start_date).add(duration, "s").format("YYYY/MM/DD")}
        </span>
      ),
    },
    {
      title: "Address",
      dataIndex: "vesting_address",
      width: 150,
      render: (vesting_address: string) => <span>{ellipseAddress(vesting_address, 5, 5)}</span>,
    },
    {
      title: "Status",
      width: 150,
      render: (_, { start_date, schedule_id }) => (
        <span>{dayjs().isBefore(start_date) ? "Incoming" : claimables[schedule_id] ? "Claiming" : "Claimed"}</span>
      ),
    },
    {
      title: "Action",
      width: 150,
      render: (_, row: ClaimItem) => (
        <GradientButton
          className="claim-button"
          onClick={() => setOnClaim(row)}
          disabled={!Number(claimables[row.schedule_id])}
        >
          Claim
        </GradientButton>
      ),
    },
  ];

  if (address && (!logged || (!loadingVesting && !vesting))) return <NotFound />;
  else if (loading && loadingVesting) return null;

  return (
    <div className="v">
      <div className="form-title">Token Claiming</div>
      {!address || !vesting ? (
        <Row>
          <Col span={24} order={2} lg={{ span: 16, order: 1 }}>
            <form className="form select-token-manager">
              <Form.Item label="Enter Vesting URL" required labelCol={{ span: 24 }}>
                <CustomInput
                  onChange={e => setValue(e.target.value)}
                  placeholder="Enter Vesting URL"
                  disabled={!logged}
                />
              </Form.Item>
              {!logged && <div className="danger-message">You have to Connect Wallet before enter a URL</div>}
              <GradientButton className="continue-button" disabled={!value || !logged} onClick={handleSubmit}>
                Continue
              </GradientButton>
            </form>
          </Col>
          <Col span={24} order={1} lg={{ span: 8, order: 2 }}>
            <ul className="token-ideas">
              {ideas.map((idea, index) => (
                <li key={index}>
                  <ResizeIcon icon={IdeaIcon} width={15} />
                  {idea}
                </li>
              ))}
            </ul>
          </Col>
        </Row>
      ) : (
        <form className="form select-token-manager">
          <Form.Item label="Enter Vesting URL" required labelCol={{ span: 24 }}>
            <CustomInput onChange={e => setValue(e.target.value)} placeholder="Enter Vesting URL" disabled={!logged} />
          </Form.Item>
          <div className="token-detail-header">
            <div className="token-detail-info">
              <div className="token-info-label">
                <div className="token-summary">
                  {token?.name} ({token?.symbol})
                </div>
                <div className="token-address">{address}</div>
              </div>
              <div className="token-info-value">
                {!!selectedNetwork && (
                  <div className="token-network">
                    <img src={selectedNetwork.icon_urls[0]} alt={selectedNetwork.name} />
                    <span>{selectedNetwork.name}</span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <Table rowKey={"id"} columns={columns} dataSource={data} loading={loading || loadingClaimable} />
        </form>
      )}
      {vesting && (
        <ClaimTokenForm
          data={onClaim}
          amount={onClaim ? claimables[onClaim.schedule_id] : "0"}
          onClose={() => setOnClaim(null)}
          open={!!onClaim}
          onSuccess={onSuccess}
        />
      )}
    </div>
  );
};

export default ClaimVestingPage;
