import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type AuthStates = {
  balances: TokenData[];
  isLoading: boolean;
};

const initialState: AuthStates = {
  balances: [],
  isLoading: true,
};

// slice
const userWalletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    setAccountBalances: (state, actions: PayloadAction<TokenData[]>) => {
      state.balances = actions.payload;
    },
    setLoading: (state, actions: PayloadAction<boolean>) => {
      state.isLoading = actions.payload;
    },
  },
});


// reducer
export const userWalletReducer = userWalletSlice.reducer;
