import { Button, ButtonProps } from "antd";
import React from "react";

import "./styles.scss";

interface Props extends ButtonProps {
  spinClassName?: string;
}

const MethodSubmitButton: React.FC<Props> = ({ className, children, loading, ...props }) => {
  return (
    <Button className={`method-submit-button ${className || ""}`} loading={loading} {...props}>
      {children}
    </Button>
  );
};

export default MethodSubmitButton;
