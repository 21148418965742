import { Web3Provider } from "@ethersproject/providers";
import { useWeb3React } from "@web3-react/core";
import { useDispatch, useSelector } from "src/store";
import { baseQueryApi } from "src/store/baseQueryApi";
import { setIsAuth } from "src/store/slices/auth";
import { clearAccessToken, clearCurrentAccount, clearWallet, setWallet } from "src/store/slices/user";
import { ConnectorKey } from "src/web3/connectors";
import { signMessage } from "../helpers";
import { setChainId, setRequesting } from "src/store/slices/system";
import { convertDecimalToHex } from "src/commons/utils/converters/convertDecimalToHex";
import { customToast } from "src/components/02.toasts";
import { convertExplorerUrls } from "src/commons/utils/converters/converterExplorerUrls";
import { CHAIN_ID_KEY } from "../constants";
/**
 * Hook for connect/disconnect to a wallet
 * @returns `connectWallet` and `disconnectWallet` functions .
 */
export const useConnectWallet = () => {
  const publicKey = useSelector(({ system }: RootState) => system.publicKey);
  const selectedNetwork = useSelector(({ system }: RootState) => system.selectedNetwork);
  const connectors = useSelector(({ system }: RootState) => system.connectors);
  const networks = useSelector(({ system }: RootState) => system.networks);
  const chainId = useSelector(({ system }: RootState) => system.chainId);
  const wallet = useSelector(({ user }: RootState) => user.wallet);
  const dispatch = useDispatch();
  const { connector: appConnector } = useWeb3React();

  const getAccountConnected = async (provider: Web3Provider) => {
    const signer = provider.getSigner();
    const account = await signer?.getAddress();
    return account;
  };

  const getSignature = async (provider: Web3Provider) => {
    const message = publicKey;
    const signer = provider?.getSigner();
    const signature = await signMessage(signer, message);
    return {
      message,
      signature,
    };
  };

  const connectWallet = async (connectorKey: ConnectorKey) => {
    const connector = connectors[connectorKey];
    if (!selectedNetwork) return customToast.error("Unsupported network!");

    try {
      const objAddNetWork =
        connectorKey === ConnectorKey.metaMask
          ? ({
              chainId: Number(selectedNetwork.network_id),
              chainName: selectedNetwork.name,
              nativeCurrency: selectedNetwork.native_currency,
              rpcUrls: selectedNetwork.rpc_urls,
              blockExplorerUrls: [convertExplorerUrls(selectedNetwork).explorer],
            } as any)
          : Number(selectedNetwork.network_id);
      await connector?.activate(objAddNetWork);

      dispatch(setWallet(connectorKey));
    } catch (error: any) {
      console.log(error);
      if (error.message === "User rejected the request." || error.code === 4001) {
        customToast.error("User rejected the request!");
      } else {
        try {
          if (!selectedNetwork || !connector) return;
          const { name, rpc_urls, native_currency, explorer_urls } = selectedNetwork;
          const chainIdHex = convertDecimalToHex(Number(selectedNetwork.network_id));
          await connector.provider?.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainName: name,
                rpcUrls: rpc_urls,
                nativeCurrency: native_currency,
                blockExplorerUrls: [explorer_urls[0]],
                chainId: chainIdHex,
              },
            ],
          });
        } catch (addError) {
          console.log(addError);
          customToast.error("User rejected the request!");
        }
      }
      throw error;
    }
  };

  const requestChangeNetwork = async (chainId: number) => {
    const selectedNetwork = networks.find(item => item.network_id === chainId.toString());
    if (!wallet) return dispatch(setChainId(chainId));

    const connector = connectors[wallet];
    if (!connector?.provider) return;
    if (!selectedNetwork) return customToast.error("Unsupported network!");
    const chainIdHex = convertDecimalToHex(chainId);
    try {
      dispatch(setRequesting(true));

      await connector.provider?.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: chainIdHex }],
      });
      dispatch(setChainId(chainId));
    } catch (error: any) {
      console.log(error);
      if (error.message === "User rejected the request." || error.code === 4001) {
        customToast.error("User rejected the request!");
      } else {
        try {
          const { name, rpc_urls, native_currency, explorer_urls } = selectedNetwork;
          await connector.provider.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainName: name,
                rpcUrls: rpc_urls,
                nativeCurrency: native_currency,
                blockExplorerUrls: [explorer_urls[0]],
                chainId: chainIdHex,
              },
            ],
          });
          dispatch(setChainId(chainId));
        } catch (addError) {
          console.log(addError);
          customToast.error("User rejected the request!");
        }
      }
    }
    dispatch(setRequesting(false));
  };

  const clearWalletConnect = () => {
    connectors[ConnectorKey.walletConnect]?.resetState();
    connectors[ConnectorKey.walletConnect]?.deactivate?.();
    localStorage.clear();
    localStorage.setItem(CHAIN_ID_KEY, chainId.toString());
  };

  const connectWalletAndSignMessage = async (connectorKey: ConnectorKey) => {
    if (connectorKey === ConnectorKey.walletConnect) {
      clearWalletConnect();
    }

    await connectWallet(connectorKey);
    const connector = connectors[connectorKey];
    if (!connector?.provider) {
      throw new Error("No provider found");
    }
    const provider = new Web3Provider(connector?.provider);
    const account = await getAccountConnected(provider);
    if (!account) {
      throw new Error("Account not found");
    }
    const { message, signature } = await getSignature(provider);
    return {
      message,
      signature,
      account,
    };
  };

  function disconnectWallet(removeCache = true) {
    dispatch(clearWallet());
    dispatch(clearAccessToken());
    dispatch(clearCurrentAccount());
    dispatch(setIsAuth(false));
    appConnector.resetState();
    appConnector?.deactivate && appConnector.deactivate();
    if (removeCache) {
      dispatch(baseQueryApi.util.resetApiState());
    }
  }

  return { connectWallet, disconnectWallet, connectWalletAndSignMessage, requestChangeNetwork };
};
