export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT as string;

export const TOKEN_KEY = "token";
export const REFRESH_TOKEN_KEY = "refresh_token";

export enum PROVIDER {
  METAMASK = 1,
  WALLET_CONENCT = 2,
}

export const DEFAULT_MODAL_WIDTH = 448; //px

export enum SOCIAL_KEY {
  TOKEN_FACTORY_PARAMETER_CONFIG_TELEGRAM = "TOKEN_FACTORY_PARAMETER_CONFIG_TELEGRAM",
  TOKEN_FACTORY_PARAMETER_CONFIG_TWITTER = "TOKEN_FACTORY_PARAMETER_CONFIG_TWITTER",
  TOKEN_FACTORY_PARAMETER_CONFIG_LINKEDIN = "TOKEN_FACTORY_PARAMETER_CONFIG_LINKEDIN",
  TOKEN_FACTORY_PARAMETER_CONFIG_YOUTUBE = "TOKEN_FACTORY_PARAMETER_CONFIG_YOUTUBE",
  TOKEN_FACTORY_PARAMETER_CONFIG_DISCORD = "TOKEN_FACTORY_PARAMETER_CONFIG_DISCORD",
  TOKEN_FACTORY_PARAMETER_CONFIG_CONTACT_US = "TOKEN_FACTORY_PARAMETER_CONFIG_CONTACT_US",
}
