import "./styles.scss";
import { Button, Form } from "antd";
import { FormProps } from "antd/lib";
import { useEffect, useState } from "react";
import { REQUIRED } from "src/commons/messages/validate";
import MethodSubmitButton from "src/components/03.buttons/MethodSubmitButton";
import CustomInput from "src/components/07.inputs";
import ModalWithLogo from "src/components/04.modals/ModalWithLogo";
import { isAddress, isNotSame } from "src/commons/utils/functions/validates";
import { formatNumber } from "src/commons/utils/functions/formatNumber";
import { useErc720Contract } from "src/web3/contracts/useErc720Contract";
import { TokenService } from "src/services/token-service";
import { customToast } from "src/components/02.toasts";
import { useWeb3React } from "@web3-react/core";

type Props = {
  token: TokenType;
  address: string;
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  totalSupply: string;
};

const FormItem = Form.Item<ChangeOwnerValues>;

const ChangeOwnerModal = ({ token, address, open, totalSupply, onClose, onSuccess }: Props) => {
  const [form] = Form.useForm<ChangeOwnerValues>();
  const [loading, setLoading] = useState(false);
  const { transferOwnership } = useErc720Contract(address);
  const { account } = useWeb3React();

  const { name, owner_address, decimals } = token;

  const onFinish = async (values: ChangeOwnerValues) => {
    if (!transferOwnership) return;
    setLoading(true);
    try {
      const { new_owner } = values;
      const transaction = await transferOwnership(new_owner);
      const data = await transaction?.wait(1);
      try {
        await TokenService.updateERC20TokenOwner(address, { new_owner, status: data.status || 1, payload: data });
      } catch (error) {
        console.log(error);
      }
      customToast.success("Change Owner Successfully");
      onClose();
      onSuccess();
    } catch (error: any) {
      console.log({ error });
      const errors: StringObject = error.response?.data?.errors || { name: "Change Owner Failed" };
      form.setFields(Object.entries(errors).map(([key, value]) => ({ name: key, errors: [value] })));
      form.getFieldInstance(Object.keys(errors)[0] || "name")?.focus();
      const errMessage = Object.values(errors)[0];
      customToast.error(typeof errMessage === "string" ? errMessage : "Change Owner Failed");
    }
    setLoading(false);
  };

  const onFinishFailed: FormProps["onFinishFailed"] = errorInfo => {
    const namePath = errorInfo.errorFields[0].name?.[0];
    form.getFieldInstance(namePath)?.focus();
  };

  const handleReset = () => {
    form.resetFields();
  };

  useEffect(() => {
    handleReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <ModalWithLogo open={open} footer={false}>
      <Form
        form={form}
        className="form token-method-form"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        layout="horizontal"
        onFinish={onFinish}
        validateMessages={{ required: REQUIRED() }}
        onFinishFailed={onFinishFailed}
      >
        <div className="token-method-title">Change Owner</div>
        <div className="token-detail-info">
          <div className="token-info-label">Token Name</div>
          <div className="token-info-value">{name}</div>
        </div>
        <div className="token-detail-info">
          <div className="token-info-label">Total Supply</div>
          <div className="token-info-value">{formatNumber(totalSupply, decimals)}</div>
        </div>
        <div className="token-detail-info">
          <div className="token-info-label">Current Owner</div>
          <div className="token-info-value">{owner_address}</div>
        </div>

        <FormItem
          colon
          label="Owner"
          name="new_owner"
          rules={[
            { required: true, message: "Recipient address is required" },
            { validator: isAddress("owner ") },
            { validator: isNotSame(account, "Owner address must be different from creator address.") },
          ]}
        >
          <CustomInput placeholder="Enter Owner Address" />
        </FormItem>

        <div className="form-submit">
          <Button className="cancel-button" onClick={onClose}>
            Back
          </Button>
          <MethodSubmitButton htmlType="submit" type="primary" loading={loading}>
            Confirm
          </MethodSubmitButton>
        </div>
      </Form>
    </ModalWithLogo>
  );
};

export default ChangeOwnerModal;
