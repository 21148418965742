import React from "react";
import { Switch, SwitchProps } from "antd";

import "./styles.scss";

const CustomSwitch = React.forwardRef<HTMLElement, SwitchProps>(({ className, ...props }, ref) => {
  return <Switch ref={ref} className={`custom-switch ${className ?? ""}`} {...props} />;
});

export default CustomSwitch;
