import "./styles.scss";
import { useState } from "react";
import { Col, Form, Row, Select } from "antd";
import CustomSelect from "src/components/12.select";
import GradientButton from "src/components/03.buttons/GradientButton";
import ResizeIcon from "src/components/08.resize-icon";
import { IdeaIcon } from "src/commons/resources/icons";
import { useSelector } from "react-redux";
import { useWeb3React } from "@web3-react/core";
import VestingTokenForm from "src/components/05.forms/VestingTokenForm";
import useFetchInfinit from "src/commons/hooks/useFetchInfinit";
import { APIS } from "src/commons/constants/apis";
import { useDebounce } from "src/commons/hooks/useDebounce";
import { isAddress } from "ethers/lib/utils";
import { isSameAddress } from "src/web3/helpers";

const defaultIdeals = [
  "Gain control over token release.",
  "Ensure a controlled and gradual token distribution",
  "Safeguard your project's sustainability.",
  "Build trust with secure and transparent vesting schedules",
];

const selectedIdeas = [
  "Effortless, speedy, and user-friendly Token Minter.",
  "Effortless Smart Contracts",
  "Gain complete ownership of the Token you create",
];

const perPage = 10;

const VestingTokenPage = () => {
  const [value, setValue] = useState("");
  const [search, setSearch] = useState("");
  const [token, setToken] = useState<TokenType | null>(null);
  const isAuth = useSelector(({ user }: RootState) => !!user.currentAccount);
  const { account } = useWeb3React();
  const network_id = useSelector(({ system }: RootState) => system.chainId);
  const logged = isAuth && account;
  const { data, loading, hasMore, next } = useFetchInfinit<TokenItem>(logged ? APIS.TOKEN_LIST : "", {
    perPage,
    search_value: search,
    network_id,
    account,
  });

  const handleSearch = useDebounce((search: string) => setSearch(search));

  const handleSubmit = () => setToken(data.find(item => item.address === value) || null);

  if (loading) return null;

  return (
    <div>
      <div className="form-title">{token ? `${token.symbol} Vesting Contract Creation` : "Token Vesting"}</div>

      <Row>
        <Col span={24} order={2} lg={{ span: 16, order: 1 }}>
          {value && token ? (
            <div className="select-token-manager">
              <VestingTokenForm token={token} address={value} />
            </div>
          ) : (
            <div className="select-token-manager">
              <Form.Item label="Token Contract Address" required labelCol={{ span: 24 }}>
                <CustomSelect
                  showSearch
                  onChange={(value: string) => setValue(value)}
                  placeholder="Enter Token Address"
                  disabled={!logged}
                  onSearch={handleSearch}
                  loading={loading}
                  next={next}
                  hasMore={hasMore}
                >
                  {data.map(({ address, symbol }) => (
                    <Select.Option key={address} value={address}>
                      <div className="token-select-item">
                        <span>{address}</span> <span className="token-select-symbol">{symbol}</span>
                      </div>
                    </Select.Option>
                  ))}
                  {!data.find(item => isSameAddress(item.address, search)) && isAddress(search) && (
                    <Select.Option value={search}>
                      <div className="token-select-item">
                        <span>{search}</span>
                      </div>
                    </Select.Option>
                  )}
                </CustomSelect>
              </Form.Item>
              {!logged && <div className="danger-message">You have to Connect Wallet before select a token</div>}
              <GradientButton className="continue-button" disabled={!value || !logged} onClick={handleSubmit}>
                Continue
              </GradientButton>
            </div>
          )}
        </Col>
        <Col span={24} order={1} lg={{ span: 8, order: 2 }}>
          <ul className="token-ideas">
            {(value ? selectedIdeas : defaultIdeals).map((idea, index) => (
              <li key={index}>
                <ResizeIcon icon={IdeaIcon} width={15} />
                {idea}
              </li>
            ))}
          </ul>
        </Col>
      </Row>
    </div>
  );
};

export default VestingTokenPage;
