import { Collapse } from "antd";
import React from "react";

import "./styles.scss";

type Props = {
  children: React.ReactNode;
  active: boolean;
};

const CustomCollapse = ({ children, active }: Props) => {
  return <Collapse activeKey={[active ? "1" : "0"]} ghost className="collapse-group" items={[{ children }]} />;
};

export default CustomCollapse;
