import FactoryABI from "src/web3/abis/Factory.json";
import { useContract } from "./useContract";
import { BaseContract } from "ethers";
import { useSelector } from "react-redux";

interface FactoryContract {
  deploySmartContractRaw?: (payload: string, salt: string) => Promise<TransactionResponse>;
  getAddress?: (address: string, salt: string) => Promise<string>;
}

export const useFactoryContract = (): FactoryContract => {
  const selectedNetwork = useSelector(({ system }: RootState) => system.selectedNetwork);

  const factoryContract = useContract<BaseContract & FactoryContract>(
    FactoryABI,
    selectedNetwork?.erc20_generator_address || ""
  );

  return factoryContract || {};
};
