import { Button, Form } from "antd";
import { FormProps } from "antd/lib";
import { useEffect, useState } from "react";
import { REQUIRED } from "src/commons/messages/validate";
import MethodSubmitButton from "src/components/03.buttons/MethodSubmitButton";
import NumberInput from "src/components/07.inputs/NumberInput";
import ModalWithLogo from "src/components/04.modals/ModalWithLogo";
import { isBetWeenMinMax } from "src/commons/utils/functions/validates";
import { formatNumber } from "src/commons/utils/functions/formatNumber";
import { useErc720Contract } from "src/web3/contracts/useErc720Contract";
import { customToast } from "src/components/02.toasts";

import "./styles.scss";

type Props = {
  token: TokenType;
  address: string;
  open: boolean;
  onClose: () => void;
  totalSupply: string;
};

const FormItem = Form.Item<ChangeBurnFeeValues>;

const ChangeBurnFeeModal = ({ token, address, open, totalSupply, onClose }: Props) => {
  const [form] = Form.useForm<ChangeBurnFeeValues>();
  const [loading, setLoading] = useState(false);
  const [getLoading, setGetLoading] = useState(false);
  const { setDeflationConfig, deflationBPS } = useErc720Contract(address);

  const { name, decimals } = token;

  const onFinish = async (values: ChangeBurnFeeValues) => {
    if (!setDeflationConfig) return;
    setLoading(true);
    try {
      const { _deflationBPS } = values;
      const transaction = await setDeflationConfig((Number(_deflationBPS) * 100).toString());
      await transaction?.wait(1);
      customToast.success("Change fee transaction Successfully");
      onClose();
    } catch (error: any) {
      console.log({ error });
      const errors: StringObject = error.response?.data?.errors || { name: "Change fee transaction Failed" };
      form.setFields(Object.entries(errors).map(([key, value]) => ({ name: key, errors: [value] })));
      form.getFieldInstance(Object.keys(errors)[0] || "name")?.focus();
      const errMessage = Object.values(errors)[0];
      customToast.error(typeof errMessage === "string" ? errMessage : "Change fee transaction Failed");
    }
    setLoading(false);
  };

  const onFinishFailed: FormProps["onFinishFailed"] = errorInfo => {
    const namePath = errorInfo.errorFields[0].name?.[0];
    form.getFieldInstance(namePath)?.focus();
  };

  useEffect(() => {
    const getDefault = async () => {
      if (!deflationBPS) return;
      setGetLoading(true);
      try {
        const _deflationBPS = await deflationBPS();
        form.setFieldsValue({ _deflationBPS: Number(_deflationBPS) / 100 });
      } catch (error) {
        console.log(error);
      }
      setGetLoading(false);
    };
    if (open) getDefault();
  }, [open, deflationBPS, form, setGetLoading]);

  return (
    <ModalWithLogo open={open} footer={false}>
      <Form
        form={form}
        className="form token-method-form"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        layout="horizontal"
        onFinish={onFinish}
        validateMessages={{ required: REQUIRED() }}
        onFinishFailed={onFinishFailed}
      >
        <div className="token-method-title">Burn Fee ( Deflationary Token)</div>
        <div className="token-detail-info">
          <div className="token-info-label">Token Name</div>
          <div className="token-info-value">{name}</div>
        </div>
        <div className="token-detail-info">
          <div className="token-info-label">Total Supply</div>
          <div className="token-info-value">{formatNumber(totalSupply, decimals)}</div>
        </div>
        <FormItem
          colon
          label="Burn Fee on transaction"
          name="_deflationBPS"
          rules={[
            { required: true, message: "The fee percentage field is required" },
            { validator: isBetWeenMinMax(0, 100, "", "Transaction fee percentage is out of the allowed range") },
          ]}
        >
          <NumberInput
            name="_deflationBPS"
            rules={[
              { required: true, message: "The fee percentage field is required" },
              { validator: isBetWeenMinMax(0, 100, "", "Transaction fee percentage is out of the allowed range") },
            ]}
            decimalsLimit={2}
            placeholder="e.g: 0.1%"
          />
        </FormItem>
        <div className="description-input">
          If activated, the specified burn fee as a portion of every transfer of the token will be burnt
        </div>

        <div className="form-submit">
          <Button className="cancel-button" onClick={onClose}>
            Back
          </Button>
          <MethodSubmitButton htmlType="submit" type="primary" loading={loading || getLoading}>
            Submit
          </MethodSubmitButton>
        </div>
      </Form>
    </ModalWithLogo>
  );
};

export default ChangeBurnFeeModal;
