import React from "react";
import CustomModal from "..";
import ButtonContained from "../../03.buttons/ButtonContained";

import "./styles.scss";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const DisconnectWalletModal: React.FC<Props> = ({ isOpen, onClose, onConfirm }: Props) => {
  return (
    <CustomModal title="Disconnect Wallet" open={isOpen} onCancel={onClose} wrapClassName={`disconnect-wallet-modal`}>
      <div className="modal-disconnect">
        <div className="modal-disconnect__content">
          <span>You have to connect your wallet again to trade.</span> <span>Are you sure?</span>
        </div>
        <ButtonContained fullWidth className="button-confirm" onClick={onConfirm}>
          Confirm
        </ButtonContained>
      </div>
    </CustomModal>
  );
};

export default DisconnectWalletModal;
