export const WALLET_CONNECT_ID = process.env.REACT_APP_WALLET_CONNECT_ID as string;

export const DEFAULT_CHAIN_ID = Number(process.env.REACT_APP_DEFAULT_CHAIN_ID || 0);

export const CHAIN_ID_KEY = "chainId";

export enum TokenStatus {
  PENDING = 1,
  SIGNED = 2,
  SUBMITTED = 3,
  SUCCEEDED = 4,
}

export enum ConnectError {
  user_reject = "user_reject",
  un_support_chain = "un_support_chain",
  no_eth_provider = "no_eth_provider",
  metamask_lock = "metamask_lock",
}
