import { Button, Form } from "antd";
import { FormProps } from "antd/lib";
import { useEffect, useState } from "react";
import { REQUIRED } from "src/commons/messages/validate";
import MethodSubmitButton from "src/components/03.buttons/MethodSubmitButton";
import NumberInput from "src/components/07.inputs/NumberInput";
import ModalWithLogo from "src/components/04.modals/ModalWithLogo";
import { formatNumber } from "src/commons/utils/functions/formatNumber";
import { useErc720Contract } from "src/web3/contracts/useErc720Contract";
import { multipleDecimals } from "src/commons/utils/functions/multipleDecimals";
import { divDecimals } from "src/commons/utils/functions/divDecimals";
import { isBetWeenMinMax, isGreateThan0 } from "src/commons/utils/functions/validates";
import { customToast } from "src/components/02.toasts";

import "./styles.scss";

type Props = {
  token: TokenType;
  address: string;
  open: boolean;
  onClose: () => void;
  totalSupply: string;
  getTotalSupply: () => void;
  userBalance: string;
  getUserBalance: () => void;
};

const FormItem = Form.Item<BurnTokenValues>;

const BurnTokenModal = (props: Props) => {
  const { token, address, open, totalSupply, getTotalSupply, userBalance, getUserBalance, onClose } = props;
  const [form] = Form.useForm<BurnTokenValues>();
  const [loading, setLoading] = useState(false);
  const { burn } = useErc720Contract(address);

  const { name, decimals } = token;

  const onFinish = async (values: BurnTokenValues) => {
    if (!burn) return;
    setLoading(true);
    try {
      const amount = multipleDecimals(values.amount, decimals);
      const transaction = await burn(amount);
      await transaction?.wait(1);
      customToast.success("Burn Token Successfully");
      getTotalSupply();
      getUserBalance();
      onClose();
    } catch (error: any) {
      console.log({ error });
      const errors: StringObject = error.response?.data?.errors || { name: "Burn Token Failed" };
      form.setFields(Object.entries(errors).map(([key, value]) => ({ name: key, errors: [value] })));
      form.getFieldInstance(Object.keys(errors)[0] || "name")?.focus();
      const errMessage = Object.values(errors)[0];
      customToast.error(typeof errMessage === "string" ? errMessage : "Burn Token Failed");
    }
    setLoading(false);
  };

  const onFinishFailed: FormProps["onFinishFailed"] = errorInfo => {
    const namePath = errorInfo.errorFields[0].name?.[0];
    form.getFieldInstance(namePath)?.focus();
  };

  const handleReset = () => {
    form.resetFields();
  };

  useEffect(() => {
    handleReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <ModalWithLogo open={open} footer={false}>
      <Form
        form={form}
        className="form token-method-form"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        layout="horizontal"
        onFinish={onFinish}
        validateMessages={{ required: REQUIRED() }}
        onFinishFailed={onFinishFailed}
      >
        <div className="token-method-title">Burn Token</div>
        <div className="token-detail-info">
          <div className="token-info-label">Token Name</div>
          <div className="token-info-value">{name}</div>
        </div>
        <div className="token-detail-info">
          <div className="token-info-label">Total Supply</div>
          <div className="token-info-value">{formatNumber(totalSupply, decimals)}</div>
        </div>
        <FormItem
          colon
          label="Number Of Tokens"
          name="amount"
          rules={[
            { required: true },
            { validator: isGreateThan0("The number of tokens burnt") },
            {
              validator: isBetWeenMinMax(
                0,
                Number(divDecimals(userBalance, decimals)),
                "",
                "You do not have a sufficient token balance to burn this amount"
              ),
            },
          ]}
        >
          <NumberInput
            name="amount"
            rules={[
              { required: true },
              { validator: isGreateThan0("The number of tokens burnt") },
              {
                validator: isBetWeenMinMax(
                  0,
                  Number(divDecimals(userBalance, decimals)),
                  "",
                  "You do not have a sufficient token balance to burn this amount"
                ),
              },
            ]}
            decimalsLimit={decimals}
            placeholder="e.g: 10 000 000"
          />
        </FormItem>
        <div className="form-submit">
          <Button className="cancel-button" onClick={onClose}>
            Back
          </Button>
          <MethodSubmitButton htmlType="submit" type="primary" loading={loading}>
            Burn
          </MethodSubmitButton>
        </div>
      </Form>
    </ModalWithLogo>
  );
};

export default BurnTokenModal;
