import { useEffect, useState } from "react";

export const useDebounceValue = <T>(value: T, time = 500) => {
  const [debonceValue, setDebonceValue] = useState(value);

  useEffect(() => {
    const timeout = setTimeout(() => setDebonceValue(value), time);
    return () => clearTimeout(timeout);
  }, [value, time, setDebonceValue]);

  return debonceValue;
};
