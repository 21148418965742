import React, { InputHTMLAttributes, useEffect, useState } from "react";
import ResizeIcon from "../08.resize-icon";
import { UploadIcon } from "src/commons/resources/icons";
import { Form, Input, Spin } from "antd";
import { EXCEEDS_SIZE_LIMIT, INCORRECT_FORMAT } from "src/commons/messages/validate";
import { FormInstance } from "antd/lib";
import { LoadingOutlined } from "@ant-design/icons";
import CustomCollapse from "../09.collaspe";

export interface UploadRef {
  reset: () => void;
}

interface Props extends Omit<InputHTMLAttributes<HTMLInputElement>, "onChange" | "accepts" | "placeholder"> {
  name: string;
  loading?: boolean;
  onChange?: (file: File) => void;
  accepts: string[];
  placeholder: React.ReactNode;
  limit?: number;
  preview?: boolean;
  setRef?: (ref: UploadRef | null) => void;
  invalidMessage?: string;
}
const PREVIEW_ALLOWS = ["image/png", "image/jpeg", "image/gif", "video/mp4"];

export default function Upload(props: Props) {
  const { name, loading, onChange, accepts, placeholder, limit, preview, setRef, invalidMessage } = props;
  const [showLoading, setShowLoading] = useState(false);
  const [url, setUrl] = useState("");
  const [fileName, setFileName] = useState("");

  const handlePreview = (file: File) => {
    if (file.type === "video/mp4") {
      return setUrl(URL.createObjectURL(file));
    }

    setShowLoading(true);
    const reader = new FileReader();
    reader.onload = () => {
      setShowLoading(false);
      setUrl(reader.result?.toString() || "");
    };
    reader.onerror = () => {
      setShowLoading(false);
    };
    if (PREVIEW_ALLOWS.includes(file.type)) reader.readAsDataURL(file);
  };

  const onChooseFile = (file: File, setFields: FormInstance["setFields"]) => {
    if (!accepts.includes(file.type || ""))
      return setFields([{ name, value: file, errors: [invalidMessage || INCORRECT_FORMAT(name)] }]);
    if (file.size > (limit || Infinity)) return setFields([{ name, value: file, errors: [EXCEEDS_SIZE_LIMIT(name)] }]);
    setFields([{ name, value: file, errors: [] }]);
    onChange?.(file);
    setFileName(file.name);
    preview && handlePreview(file);
  };

  useEffect(() => {
    setRef?.({
      reset: () => {
        setShowLoading(false);
        setUrl("");
        setFileName("");
      },
    });

    return () => {
      setRef?.(null);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <CustomCollapse active={!url}>
        <div className={`file-upload-preview ${url ? "active" : ""}`}>
          {(loading || showLoading) && (
            <div className="loading">
              <Spin indicator={<LoadingOutlined />} size="large" />
            </div>
          )}
          {url ? (
            fileName.slice(-4).toLowerCase() === ".mp4" || url.slice(-4).toLowerCase() === ".mp4" ? (
              <video controls key={url}>
                <source src={url} />
              </video>
            ) : (
              <img src={url} alt="preview" />
            )
          ) : (
            ""
          )}
        </div>
      </CustomCollapse>
      <Form.Item dependencies={[name]} noStyle>
        {({ setFields }) => (
          <label
            className={`file-upload ${loading ? "loading" : ""}`}
            htmlFor={`file-upload-${name}`}
            onDrop={e => {
              if (loading) return;
              e.preventDefault();
              const file = e.dataTransfer.files?.[0];
              onChooseFile(file, setFields);
            }}
            onDragOver={e => e.preventDefault()}
          >
            <ResizeIcon icon={UploadIcon} />
            <div className="upload-placeholder">{fileName || placeholder}</div>
            <input
              id={`file-upload-${name}`}
              type="file"
              accept={accepts.join(",")}
              name={`file-upload-${name}`}
              hidden
              onChange={e => {
                const file = e.target.files?.[0];
                e.target.value = "";
                if (!file || loading) return;
                onChooseFile(file, setFields);
              }}
            />
          </label>
        )}
      </Form.Item>
      <Form.Item name={name} className="input-error-only">
        <Input hidden />
      </Form.Item>
    </>
  );
}
