import React from "react";
import { Result } from "antd";
import { useNavigate } from "react-router-dom";
import ButtonContained from "src/components/03.buttons/ButtonContained";
import { PATHS } from "src/commons/constants/routers";

import "./styles.scss";

const NotFound: React.FC = () => {
  const naivigate = useNavigate();

  return (
    <div className="not-found-wrapper">
      <Result
        status="404"
        title="Page not found"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <ButtonContained mode="medium" onClick={() => naivigate(PATHS.default)}>
            Back Home
          </ButtonContained>
        }
      />
    </div>
  );
};

export default NotFound;
