import { Button, ButtonProps, Spin } from "antd";
import React from "react";

import "./styles.scss";
import { LoadingOutlined } from "@ant-design/icons";

interface IButtonContainedProps extends ButtonProps {
  buttonType?: "type1" | "type2";
  mode?: "large" | "medium" | "small";
  fullWidth?: boolean;
}

const ButtonContained: React.FC<IButtonContainedProps> = ({
  className,
  children,
  buttonType = "type1",
  mode = "large",
  fullWidth,
  loading,
  ...props
}) => {
  return (
    <Button
      className={`contained-default--${buttonType} ${className || ""} btn-${mode} ${fullWidth ? "w-full" : ""}`}
      {...props}
    >
      {loading && <Spin indicator={<LoadingOutlined />} size="small" />}
      {children}
    </Button>
  );
};

export default ButtonContained;
