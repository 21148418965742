
export const REQUIRED = (name?: string) => `${name ?? "This field"} is required.`;


export const ALLOW_STRING_NUMBER_ONLY = (name?: string) =>
  `${name ?? "This field"} contains invalid characters. Please use only uppercase letters (A-Z) and numbers (0-9).`;

export const ALLOW_STRING_NUMBER_SPECIAL_ONLY = (name?: string) =>
  `${name ?? "This field"} should only contain alphanumeric characters, spaces, and "-" or "_".`;

export const BETWEEN_MIN_MAX = (min: number, max: number, name?: string) =>
  `${name ?? "This field"} value is outside the allowed range. Please choose a value between ${min} and ${max}.`;

export const MUST_BE_ADDRESS = (name?: string) => `Invalid ${name ?? ""}address format.`;


export const INCORRECT_FORMAT = (name?: string) => `${name ?? "This field"} incorrect format.`;

export const MUST_BE_NUMBER = (name?: string) => `${name ?? "This field"} must be a number.`;

export const MUST_BE_POSITIVE_NUMBER = (name?: string) =>
  `${name ?? "This field"} must be a positive integer greater than zero`;

export const MUST_BE_GREATE_THAN = (min: number | string, name?: string) =>
  `${name ?? "This field"} must be greater than ${min}`;

export const EXCEEDS_SIZE_LIMIT = (name?: string) => `${name ?? "This field"} exceeds the size limit.`;

export const EXCEEDS_LENGTH_LIMIT = (max: number, name?: string, lastName?: string) => `${name ?? "This field"
  } exceeds the maximum character limit. Please limit the ${lastName ?? "name"} to ${max} characters or fewer"
`;

export const LENGTH_MIN_MAX = (min: number, max: number, name?: string) =>
  `${name ?? "This field"} length must be ${min ? `between ${min} and` : "greater than"} ${max} characters.`;

export const MUST_BE_AFTER_NOW = (name?: string) => `${name ?? "This field"} must be after current time.`;
