import ERC20Token from "src/web3/abis/FullFeatureToken.json";
import { useContract } from "./useContract";
import { BaseContract, BigNumber as EtherBigNumber } from "ethers";

interface ERC720Contract {
  balanceOf?: (address: string) => Promise<EtherBigNumber>;
  decimals?: () => Promise<EtherBigNumber>;
  mint?: (to: string, amount: string) => Promise<TransactionResponse>;
  burn?: (amount: string) => Promise<TransactionResponse>;
  transferOwnership?: (newOwner: string) => Promise<TransactionResponse>;
  renounceOwnership?: () => Promise<TransactionResponse>;
  blackList?: (address: string) => Promise<TransactionResponse>;
  removeFromBlacklist?: (address: string) => Promise<TransactionResponse>;
  paused?: () => Promise<boolean>;
  pause?: () => Promise<TransactionResponse>;
  unpause?: () => Promise<TransactionResponse>;
  setDeflationConfig?: (_deflationBPS: string) => Promise<TransactionResponse>;
  deflationBPS?: () => Promise<number>;
  // Missing Add Liquidity Pool
  documentUri?: () => Promise<string>;
  setDocumentUri?: (newDocumentUri: string) => Promise<TransactionResponse>;
  decreaseAllowance?: (to: string, amount: string) => Promise<TransactionResponse>;
  setTaxConfig?: (_taxAddress: string, _taxBPS: string) => Promise<TransactionResponse>;
  taxBPS?: () => Promise<number>;
  taxAddress?: () => Promise<string>;
  setMaxTokenAmountPerAddress?: (newMaxTokenAmount: string) => Promise<TransactionResponse>;
  maxTokenAmountPerAddress?: () => Promise<EtherBigNumber>;
  getWhitelistedAddresses?: () => Promise<string[]>;
  getBlacklistedAddresses?: () => Promise<string[]>;
  updateWhitelist?: (updatedAddresses: string[]) => Promise<TransactionResponse>;
  transferFrom?: (from: string, to: string, amount: string) => Promise<TransactionResponse>;
  totalSupply?: () => Promise<EtherBigNumber>;
  approve?: (address: string, amount: string) => Promise<TransactionResponse>;
}

export const useErc720Contract = (address: string): ERC720Contract => {
  const erc720Contract = useContract<BaseContract & ERC720Contract>(ERC20Token, address);

  return erc720Contract || {};
};
