import { multipleDecimals } from "../functions/multipleDecimals";

export const convertToNFTBody = (values: CreateNFTValues, selectedNetwork: NetworkData): CreateNFTBody => {
  const {
    mint_price,
    max_nft_mint_amount_per_user,
    global_max_nft_mint,
    start_date,
    end_date,
    description = "",
    media_url = null,
    enable_public_minting = false,
    file,
    ...rest
  } = values;

  return {
    ...rest,
    media_url,
    description,
    enable_public_minting,
    mint_price: multipleDecimals(mint_price, selectedNetwork.native_currency.decimals || 0),
    max_nft_mint_amount_per_user: (max_nft_mint_amount_per_user || 0).toString(),
    global_max_nft_mint: (global_max_nft_mint || 0).toString(),
    start_date: start_date?.toISOString() || null,
    end_date: end_date?.toISOString() || null,
    provider: {
      network_id: selectedNetwork.network_id.toString(),
      name: selectedNetwork?.name || "",
    },
    meta: {},
  };
};
