import "./styles.scss";

import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Col, Form, Row, Select, Spin } from "antd";
import CustomSelect from "src/components/12.select";
import GradientButton from "src/components/03.buttons/GradientButton";
import { PATHS, routers } from "src/commons/constants/routers";
import ResizeIcon from "src/components/08.resize-icon";
import { IdeaIcon } from "src/commons/resources/icons";
import NFTDetail from "src/components/nft-detail/NFTDetail";
import NotFound from "../NotFound";
import { useSelector } from "react-redux";
import { useWeb3React } from "@web3-react/core";
import { isSameAddress } from "src/web3/helpers";

import useFetchInfinit from "src/commons/hooks/useFetchInfinit";
import { APIS } from "src/commons/constants/apis";
import { useFetch } from "src/commons/hooks/useFetch";
import { useDebounce } from "src/commons/hooks/useDebounce";
import { LoadingOutlined } from "@ant-design/icons";
import { isAddress } from "ethers/lib/utils";

const defaultIdeals = [
  "Review NFT configuration",
  "Add metadata",
  "Prepare NFT batch minting",
  "Add and remove addresses to the minter whitelist",
  "Works with all NFTs that were created with Token Tool",
];

const perPage = 10;

const ManageNFTPage = () => {
  const [value, setValue] = useState("");
  const [search, setSearch] = useState("");
  const { address } = useParams<{ address?: string }>();
  const isAuth = useSelector(({ user }: RootState) => !!user.currentAccount);
  const network_id = useSelector(({ system }: RootState) => system.chainId);
  const { account } = useWeb3React();
  const logged = isAuth && account;
  const { data, loading, hasMore, next } = useFetchInfinit<NFTItem>(logged ? APIS.NFT_LIST : "", {
    perPage,
    search_value: search,
    network_id,
  });
  const { data: nft, loading: loadingNFT, error } = useFetch<NFTType>(APIS.NFT_DETAIL(address));

  const navigate = useNavigate();

  const handleSearch = useDebounce((search: string) => setSearch(search));

  const handleSubmit = () => navigate(PATHS.managerNFT(value));

  if (address && error) return <NotFound />;
  if (loading || (address && loadingNFT))
    return (
      <div className="loading">
        <Spin indicator={<LoadingOutlined />} size="large" />
      </div>
    );

  return (
    <div>
      <div className="form-title">Manage NFT</div>
      {address && nft ? (
        <NFTDetail address={address} nft={nft} />
      ) : (
        <Row>
          <Col span={24} order={2} lg={{ span: 16, order: 1 }}>
            <form className="form select-token-manager">
              <Form.Item label="NFT Contract Address" required labelCol={{ span: 24 }}>
                <CustomSelect
                  showSearch
                  onChange={(value: string) => setValue(value)}
                  placeholder="Enter NFT Address"
                  disabled={!logged}
                  onSearch={handleSearch}
                  loading={loading}
                  next={next}
                  hasMore={hasMore}
                >
                  {data.map(({ address, symbol }) => (
                    <Select.Option key={address} value={address}>
                      <div className="token-select-item">
                        <span>{address}</span> <span className="token-select-symbol">{symbol}</span>
                      </div>
                    </Select.Option>
                  ))}
                  {!data.find(item => isSameAddress(item.address, search)) && isAddress(search) && (
                    <Select.Option value={search}>
                      <div className="token-select-item">
                        <span>{search}</span>
                      </div>
                    </Select.Option>
                  )}
                </CustomSelect>
              </Form.Item>
              {!logged && <div className="danger-message">You have to Connect Wallet before select a token</div>}
              <GradientButton className="continue-button" disabled={!value || !logged} onClick={handleSubmit}>
                Continue
              </GradientButton>
              Or{" "}
              <Link className="create-navigate" to={routers.NFT_CREATE}>
                Create NFT
              </Link>
            </form>
          </Col>
          <Col span={24} order={1} lg={{ span: 8, order: 2 }}>
            <ul className="token-ideas">
              {defaultIdeals.map((idea, index) => (
                <li key={index}>
                  <ResizeIcon icon={IdeaIcon} width={15} />
                  {idea}
                </li>
              ))}
            </ul>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default ManageNFTPage;
