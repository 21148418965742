import React, { HTMLAttributes, useEffect, useRef, useState } from "react";

import "./styles.scss";

interface Props extends HTMLAttributes<HTMLDivElement> {
  /**
   * Import default from svg, eg: import { ReactComponent as ProfileIcon } from "./profile.svg";
   */
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }>;
  /**
   * If not set width, icon will invisible when first load;
   */
  width?: number;
  /**
   * If not set height, icon will invisible when first load;
   */
  height?: number;
  /**
   * If not set originHeight, icon will invisible when first load;
   */
}

const ResizeIcon = React.forwardRef<HTMLDivElement, Props>((props, boxRef) => {
  const { id, icon: Icon, width, height, className, ...otherProps } = props;
  const ref = useRef<SVGSVGElement | null>(null);
  const [svgWidth, setSvgWidth] = useState<number | undefined>();
  const [svgHeight, setSvgWheight] = useState<number | undefined>();
  const [mounted, setMounted] = useState<boolean>(false);

  useEffect(() => {
    if (ref.current) {
      setSvgWidth(ref.current?.width?.baseVal?.value);
      setSvgWheight(ref.current?.height?.baseVal?.value);
      setMounted(true);
    }
  }, []);
  const scaleX = svgWidth && (width || 0) / svgWidth;
  const scaleY = svgHeight && (height || 0) / svgHeight;

  return (
    <div
      ref={boxRef}
      className={`custom-icon-box ${className ?? ""}`}
      style={{
        width: width || svgWidth,
        height: height || svgHeight,
        visibility: mounted ? "visible" : "hidden",
      }}
      {...otherProps}
    >
      <Icon
        ref={ref}
        style={{ transform: scaleX || scaleY ? `scale(${scaleX || scaleY},${scaleY || scaleX})` : "none" }}
      />
    </div>
  );
});

export default ResizeIcon;
