import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useSelector } from "react-redux";
import { divDecimals } from "src/commons/utils/functions/divDecimals";
import { formatNumber } from "src/commons/utils/functions/formatNumber";
import { useGetBalances } from "src/web3/hooks/useGetBalances";

import "./styles.scss";

interface DataType {
  name: string;
  value: string;
}

interface Props {
  data: DistributeTokenItem[];
  token: TokenItem;
}

const DistributeTokenSumaryTable = ({ data, token }: Props) => {
  const selectedNetwork = useSelector(({ system }: RootState) => system.selectedNetwork);
  const { address, decimals } = token;

  const { nativeBalance, tokenBalance } = useGetBalances(address);

  const sumary: DataType[] = [
    { name: "Total number of address", value: data.length.toString() },
    { name: "Total number of token distributed", value: formatNumber(data.reduce((s, i) => s + Number(i.amount), 0)) },
    { name: "Total number of transactions", value: Math.ceil(data.length / 200).toString() },
    { name: "Your Token Balance", value: tokenBalance ? formatNumber(divDecimals(tokenBalance, decimals)) : "" },
    {
      name: "Transaction Cost",
      value: `${selectedNetwork?.native_currency.symbol} ${formatNumber(Math.ceil(data.length / 200) * 0.00002)}`,
    },
    {
      name: `Your ${selectedNetwork?.native_currency.symbol} balance`,
      value: nativeBalance
        ? `${selectedNetwork?.native_currency.symbol} ${formatNumber(
            divDecimals(nativeBalance, selectedNetwork?.native_currency.decimals || 0)
          )}`
        : "",
    },
  ];
  const columns: ColumnsType<DataType> = [
    {
      dataIndex: "name",
      key: "name",
      width: "60%",
      render: (address: string) => <div className="name">{address}</div>,
    },
    {
      dataIndex: "value",
      key: "value",
      width: "60%",
      render: (value: string) => <div className="value">{value}</div>,
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={sumary}
      showHeader={false}
      pagination={false}
      className="distribute-token-sumary-table"
      rowKey="name"
    />
  );
};

export default DistributeTokenSumaryTable;
