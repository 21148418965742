export const EXPAND_CREATE_TOKEN_CAN_BURN = "Enables burning after creation to decrease total supply.";

export const EXPAND_CREATE_TOKEN_CAN_MINT = "Enable minting to add more token to total supply after creation.";

export const EXPAND_CREATE_TOKEN_CAN_PAUSE =
  "Enable pausing tokens. When paused, all activities related to the token will be halted.";

export const EXPAND_CREATE_TOKEN_CAN_BLACKLIST =
  "Specifies specific addresses be blacklisted after initial token creation. Blacklisted wallet will not be able to participate in token's operation.";

export const EXPAND_CREATE_TOKEN_CAN_TRANSFERRED =
  "Enable token creator to enforce token transfers without approval or signature of the token holder. Cannot be deactivated after token creation.";

export const EXPAND_CREATE_TOKEN_REQUIRED_WHITELIST = "Enable token transfers to only whitelisted addresses.";

export const EXPAND_CREATE_TOKEN_CAN_CHARGE_TX_FEE =
  "If enabled, the portion of a token transfer will go to the tax wallet. It may make your token incompatible with some protocols. Cannot be deactivated after initial token creation.";

export const EXPAND_CREATE_TOKEN_APPLY_BURN_FEE =
  "If enabled , the portion of a token will be burnt at each transfer. It may make your token incompatible with some protocols. Cannot be deactivated after initial token creation.";

export const EXPAND_CREATE_TOKEN_DIFFIRENT_OWNER =
  "Token Smart Contract will belong to a different address. Creator address might not be able to edit token after token creation.";

export const EXPAND_CREATE_TOKEN_LIMIT_TOKEN =
  "Enable maximum token balance per address. Limit can only be increased after initial token creation.";

export const EXPAND_CREATE_TOKEN_DOCUMENT =
  "An URL can be used to reference documentation of tokens. Can be changed after initial token creation.";

export const EXPAND_CREATE_NFT_PUBLIC_MINTING = "Enable to give minting permission to everybody.";

export const EXPAND_VESTING_TOKEN_LOCK_PERIOD =
  "Locking tokens allow projects to lock  tokens in a time-locked decentralized smart contract vault. User cannot claim or change these tokens until the end of the locked period.";
