import { BaseService } from "./base-service";

export class UploadService extends BaseService {
  static path = "storages";

  static upload = (file: File) => {
    const formData = new FormData();
    formData.append("file", file);
    return this.postUpload<UploadResponse>(`/${this.path}/upload`, formData);
  };
}
