import { Button, Modal } from "antd";
import { LogoIcon } from "src/commons/resources/images";
import ResizeIcon from "src/components/08.resize-icon";
import { CheckedIcon, MetamaskIcon } from "src/commons/resources/icons";
import { Link } from "react-router-dom";
import { PATHS } from "src/commons/constants/routers";
import CopyButton from "src/components/11.copy-button";
import { customToast } from "src/components/02.toasts";
import { useSelector } from "react-redux";
import { convertExplorerUrls } from "src/commons/utils/converters/converterExplorerUrls";

import "./styles.scss";

interface Props {
  onClose: () => void;
  nft: NFTCreated | null;
}

const CreatedNFTModal = ({ onClose, nft }: Props) => {
  const selectedNetwork = useSelector(({ system }: RootState) => system.selectedNetwork);
  const href = convertExplorerUrls(selectedNetwork).address + nft?.address;

  return (
    <Modal centered closeIcon={null} width={845} footer={null} className={"created-modal"} open={!!nft}>
      <div className="created-modal-header">
        <ResizeIcon icon={LogoIcon} width={210} />
      </div>
      <div className="created-modal-title">NFT Successfully Created</div>
      <div className="created-modal-icon">
        <ResizeIcon icon={CheckedIcon} width={130} />
      </div>
      <div className="created-modal-description">
        NFT address:{" "}
        <a href={href} className="highlight-text">
          {nft?.address}
        </a>{" "}
        <CopyButton text={nft?.address} />
      </div>
      <div className="created-modal-description">
        NFT Minting Link:{" "}
        <Link to={PATHS.mintingNFT(nft?.address)} className="highlight-text">
          {window.location.origin + PATHS.mintingNFT(nft?.address)}
        </Link>{" "}
        <CopyButton text={PATHS.mintingNFT(nft?.address)} />
      </div>
      <Link className="goto-manager" to={PATHS.managerNFT(nft?.address)}>
        Go to manage NFT
      </Link>
      <Button className="close-to-more" type="link" onClick={onClose}>
        Create more NFT
      </Button>
    </Modal>
  );
};

export default CreatedNFTModal;
