import "./styles.scss";

import { Button, Modal } from "antd";
import { LogoIcon } from "src/commons/resources/images";
import ResizeIcon from "src/components/08.resize-icon";
import { CheckedIcon } from "src/commons/resources/icons";
import { PATHS } from "src/commons/constants/routers";
import CopyButton from "src/components/11.copy-button";
import { useNavigate } from "react-router-dom";

interface Props {
  data: VestingData[] | null;
  address?: string;
}

const VestedTokenModal = ({ data, address }: Props) => {
  const navigate = useNavigate();

  return (
    <Modal centered closeIcon={null} width={845} footer={null} className={"vested-modal"} open={!!data}>
      <div className="vested-modal-header">
        <ResizeIcon icon={LogoIcon} width={210} />
      </div>
      <div className="vested-modal-title">Vesting Contract Successfully deployed</div>
      <div className="vested-modal-icon">
        <ResizeIcon icon={CheckedIcon} width={130} />
      </div>
      <Button className="goto-vesting-site" onClick={() => navigate(PATHS.claimToken(address))}>
        Go to vesting site
      </Button>
      <div className="vested-modal-description">
        <CopyButton text={window.location.origin + PATHS.claimToken(address)}>Copy Vesting URL</CopyButton>
      </div>
    </Modal>
  );
};

export default VestedTokenModal;
