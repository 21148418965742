import React from "react";
import { Input, InputProps, InputRef } from "antd";

import "./styles.scss";

const CustomInput = React.forwardRef<InputRef, InputProps>(({ className, ...props }, ref) => {
  return <Input ref={ref} className={`custom-input ${className ?? ""}`} {...props} />;
});

export default CustomInput;
