import Icon from "@ant-design/icons";
import { Modal, ModalProps } from "antd";
import React from "react";
import { CloseIcon } from "src/commons/resources/icons";
import { DEFAULT_MODAL_WIDTH } from "src/commons/constants";

import "./styles.scss";

export interface ICustomModalProps extends ModalProps {}

const CustomModal: React.FC<ModalProps> = ({ children, footer, width, className, ...props }: ModalProps) => {
  return (
    <Modal
      centered
      closeIcon={<Icon className="close-modal-icon" component={CloseIcon} />}
      width={width ? width : DEFAULT_MODAL_WIDTH}
      footer={footer ? footer : null}
      destroyOnClose={true}
      className={`custom-modal ${className ? className : ""}`}
      {...props}
    >
      {children}
    </Modal>
  );
};

export default CustomModal;
