import { useCallback, useEffect, useState } from "react";
import { useVestingContract } from "../contracts/useVestingContract";

export const useClaimAbles = (address: string, schedules: ClaimItem[]) => {
  const [data, setData] = useState<{ [scheduleId: string]: string }>({});
  const [loading, setLoading] = useState(false);

  const { computeReleasableAmount } = useVestingContract(address);

  const getClaimAble = useCallback(
    async (scheduleId: string) => {
      if (computeReleasableAmount) {
        try {
          setLoading(loading);
          const amount = await computeReleasableAmount(scheduleId);
          setData(data => ({ ...data, [scheduleId]: amount.toString() }));
        } catch (error) {
          console.log(error);
        }
      }
    },
    [computeReleasableAmount, loading]
  );

  const getClaimAbles = useCallback(async () => {
    schedules.map(item => getClaimAble(item.schedule_id));
  }, [schedules, getClaimAble]);

  useEffect(() => {
    getClaimAbles();
  }, [getClaimAbles]);

  return { loading, data, refetch: getClaimAble, refetchAll: getClaimAbles };
};
