import React, { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { CheckCircleOutlined } from "@ant-design/icons";
import { CopyIcon } from "src/commons/resources/icons";
import { Tooltip } from "antd";

import "./styles.scss";

interface CopyButtonProps {
  text?: string;
  className?: string;
  children?: React.ReactNode;
}

const CopyButton: React.FC<CopyButtonProps> = ({ text = "", className, children, ...props }) => {
  const [copied, setCopied] = useState<boolean>(false);

  useEffect(() => {
    if (copied) {
      const timeout = setTimeout(() => {
        setCopied(false);
      }, 2000);
      return () => clearTimeout(timeout);
    }
  }, [copied]);

  return (
    <CopyToClipboard text={text} onCopy={() => !copied && setCopied(true)}>
      <span className={`copy-button ${className ?? ""}`} {...props}>
        <Tooltip placement="top" title={copied ? "Copied" : "Copy"}>
          {children ?? (copied ? <CheckCircleOutlined /> : <CopyIcon />)}
        </Tooltip>
      </span>
    </CopyToClipboard>
  );
};

export default CopyButton;
