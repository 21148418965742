import "./styles.scss";

import { Form, Select, Spin } from "antd";
import { ChangeEvent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import * as XLSX from "xlsx";
import { REQUIRED } from "src/commons/messages/validate";
import CustomCollapse from "../09.collaspe";
import { FormProps } from "antd/lib";
import GradientButton from "../03.buttons/GradientButton";
import { isDistributeTemplateToken } from "src/commons/utils/functions/validates";
import CustomSelect from "../12.select";
import { routers } from "src/commons/constants/routers";
import CustomTextArea from "../07.inputs/TextArea";
import Upload from "../07.inputs/Upload";
import DistributeTokenRecientTable from "../14.tables/DistributeTokenRecientTable";
import {
  convertCSVToTokenDistribute,
  convertCSVToTokenDistributeParams,
} from "src/commons/utils/converters/tokenConverter";
import DistributeTokenSumaryTable from "../14.tables/DistributeTokenSumaryTable";
import ApproveButton from "../approve-button";
import { multipleDecimals } from "src/commons/utils/functions/multipleDecimals";
import { useDistributeContract } from "src/web3/contracts/useDistributeContract";
import { customToast } from "../02.toasts";

import useFetchInfinit from "src/commons/hooks/useFetchInfinit";
import { APIS } from "src/commons/constants/apis";
import { useDebounceValue } from "src/commons/hooks/useDebounceValue";
import { useDecimals } from "src/web3/hooks/useDecimals";
import { LoadingOutlined } from "@ant-design/icons";
import { isSameAddress } from "src/web3/helpers";
import { isAddress } from "ethers/lib/utils";
import { useBalances } from "src/web3/hooks/useBalances";

const FormItem = Form.Item<DistributeTokenValues>;

const EXCELS = ["application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
const ACCEPTEDS = ["text/csv", "text/plain", ...EXCELS];

const perPage = 10;

interface Props {
  onSelect: (address: string) => void;
  onSubmit: (transactions: TransactionResponse[]) => void;
}

const DistributeTokenForm = ({ onSelect, onSubmit }: Props) => {
  const [value, setValue] = useState("");
  const [search, setSearch] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);
  const [submited, setSubmited] = useState(false);
  const [approved, setApproved] = useState(false);
  const isAuth = useSelector(({ user }: RootState) => !!user.currentAccount);
  const network_id = useSelector(({ system }: RootState) => system.chainId);
  const selectedNetwork = useSelector(({ system }: RootState) => system.selectedNetwork);
  const { account } = useWeb3React();
  const logged = isAuth && account;
  const search_value = useDebounceValue(search);
  const { data, loading, hasMore, next } = useFetchInfinit<TokenItem>(logged ? APIS.TOKEN_LIST : "", {
    perPage,
    search_value,
    network_id,
    account,
  });
  const { batchDistribute } = useDistributeContract();
  const { balance, loading: loadingBalance, error: errorBalance } = useBalances(value);
  const { decimals, loading: loadingDecimals, error: errorDecimals } = useDecimals(value);

  const [form] = Form.useForm<DistributeTokenValues>();

  const handleChange = (address: string) => {
    onSelect(address);
    setValue(address);
    setSearch("");
  };

  useEffect(() => {
    setValue("");
  }, [network_id]);

  useEffect(() => {
    if (form.getFieldValue("input")) form.validateFields(["input"]);
  }, [balance, form]);

  const onFinish = async (values: DistributeTokenValues) => {
    if (!batchDistribute) return;
    setSubmitting(true);
    try {
      if (decimals === null || !value) return;
      const { address, input } = values;
      const params = convertCSVToTokenDistributeParams(input, decimals);
      const transactions = await Promise.all(
        params.map(async ({ addresses, amounts }) => batchDistribute(address, addresses, amounts))
      );
      onSubmit(transactions as TransactionResponse[]);
      customToast.success("Distribute token successfully");
    } catch (error: any) {
      console.log({ error });
      const errors: StringObject = error.response?.data?.errors || { name: "Distribute token Failed" };
      form.setFields(Object.entries(errors).map(([key, value]) => ({ name: key, errors: [value] })));
      form.getFieldInstance(Object.keys(errors)[0] || "name")?.focus();
      const errMessage = Object.values(errors)[0];
      customToast.error(typeof errMessage === "string" ? errMessage : "Distribute token Failed");
    }
    setSubmitting(false);
  };

  const onFinishFailed: FormProps["onFinishFailed"] = errorInfo => {
    const namePath = errorInfo.errorFields[0].name?.[0];
    form.getFieldInstance(namePath)?.focus();
  };

  const readAndSaveFile = (file: File, e?: ChangeEvent<HTMLInputElement>) => {
    setFileLoading(true);
    const reader = new FileReader();
    reader.onload = () => {
      setFileLoading(false);
      let data = reader.result?.toString() || "";
      if (EXCELS.includes(file.type)) {
        const wb = XLSX.read(reader.result, { type: "binary" });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        data = XLSX.utils.sheet_to_csv(ws);
      }
      form.setFieldValue("file", data);
      form.setFieldValue("input", data);
      form.validateFields(["input"]);
      if (e) e.target.value = "";
    };
    reader.onerror = () => {
      setFileLoading(false);
    };
    reader.readAsBinaryString(file);
  };

  const onApproved = () => {
    setApproved(true);
    customToast.success("Approved successfully");
  };

  const onFailed = () => {
    customToast.error("User Reject");
  };

  const handleValidate = async () => {
    try {
      await form.validateFields();
      setSubmited(true);
    } catch (error) {}
  };

  const loadingToken = loadingDecimals || loadingBalance;
  const invalidToken = value && (errorBalance || errorDecimals);

  return (
    <Form
      form={form}
      className="form distribute-token-form"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      layout="horizontal"
      onFinish={onFinish}
      validateMessages={{ required: REQUIRED() }}
      onFinishFailed={onFinishFailed}
    >
      <div className="select-token-manager">
        <CustomCollapse active={submited}>
          <FormItem label="Token Contract Address" name="address" labelCol={{ span: 24 }}>
            <CustomSelect
              showSearch
              onChange={handleChange}
              placeholder="Enter Token Address"
              disabled={submited || loadingToken}
              onSearch={value => setSearch(value)}
              loading={loading}
              hasMore={hasMore}
              next={next}
            >
              {data.map(({ address, symbol }) => (
                <Select.Option key={address} value={address}>
                  <div className="token-select-item">
                    <span>{address}</span> <span className="token-select-symbol">{symbol}</span>
                  </div>
                </Select.Option>
              ))}
              {!data.find(item => isSameAddress(item.address, search)) && isAddress(search) && (
                <Select.Option value={search}>
                  <div className="token-select-item">
                    <span>{search}</span>
                  </div>
                </Select.Option>
              )}
            </CustomSelect>
          </FormItem>

          {loadingToken && (
            <div className="loading">
              <Spin indicator={<LoadingOutlined />} size="large" />
            </div>
          )}
          {!!invalidToken && <div className="danger-message">Selected address is not a valid token</div>}
          <FormItem noStyle dependencies={["address", "file"]}>
            {() => (
              <CustomCollapse active={!!invalidToken || loadingToken || !value}>
                <FormItem
                  label="Manual Input"
                  name="input"
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      validator: isDistributeTemplateToken(
                        account,
                        balance || undefined,
                        () => !!form.getFieldValue("file")
                      ),
                    },
                  ]}
                >
                  <CustomTextArea
                    rows={5}
                    placeholder="E.g:  0xb4fbf271143f4fbf7b91a5ded31805e42b2208d6,10000"
                    disabled={submited}
                    onChange={() => form.setFieldValue("file", "")}
                  />
                </FormItem>
                <div className="description-input">
                  Insert one address, respective amount and optionally a message per line. Separate the address, amount
                  and message with a comma and no space in between. Please NOTE: tokens will be sent in batches of 200
                  addresses. If your list contains more than 200 addresses, you will have to approve each batch
                  separately.
                </div>
                <Upload
                  name="file"
                  loading={fileLoading}
                  onChange={file => readAndSaveFile(file)}
                  accepts={ACCEPTEDS}
                  invalidMessage="Invalid File"
                  placeholder={
                    <>
                      Drag and drop your file here
                      <br />
                      CSV/ Excel/ TXT
                    </>
                  }
                />
                <FormItem name="file" hidden>
                  <input />
                </FormItem>
              </CustomCollapse>
            )}
          </FormItem>
        </CustomCollapse>
        <CustomCollapse active={!submited}>
          <FormItem dependencies={["input"]}>
            {() => {
              const token = data.find(item => item.address === form.getFieldValue("address"));
              const input = convertCSVToTokenDistribute(form.getFieldValue("input"));
              return (
                <>
                  <div className="table-title">Recient List</div>
                  {token && <DistributeTokenRecientTable data={input} token={token} />}
                  <div className="table-title">Summary</div>
                  {token && <DistributeTokenSumaryTable data={input} token={token} />}
                </>
              );
            }}
          </FormItem>
        </CustomCollapse>
        {!logged && <div className="danger-message">You have to Connect Wallet before distribute a token</div>}
        {!submited ? (
          <GradientButton className="continue-button" disabled={!value} loading={submitting} onClick={handleValidate}>
            Continue
          </GradientButton>
        ) : !approved ? (
          <FormItem dependencies={["address", "input"]}>
            {() => {
              const token = data.find(item => item.address === form.getFieldValue("address"));
              const input = convertCSVToTokenDistribute(form.getFieldValue("input"));
              const amount = input.reduce((sum, { amount }) => sum + Number(amount), 0);
              if (!token) return null;
              return (
                <ApproveButton
                  className="continue-button"
                  token={token.address}
                  address={selectedNetwork?.distribute_address}
                  amount={multipleDecimals(amount, token.decimals)}
                  onApproved={onApproved}
                  onFailed={onFailed}
                />
              );
            }}
          </FormItem>
        ) : (
          <GradientButton className="continue-button" loading={submitting} htmlType="submit">
            Send
          </GradientButton>
        )}
        <FormItem noStyle dependencies={["address"]}>
          {() =>
            form.getFieldValue("address") ? null : (
              <>
                Or{" "}
                <Link className="create-navigate" to={routers.TOKEN_CREATE}>
                  Create Token
                </Link>
              </>
            )
          }
        </FormItem>
      </div>
    </Form>
  );
};

export default DistributeTokenForm;
