import { useSelector } from "react-redux";
import { convertExplorerUrls } from "src/commons/utils/converters/converterExplorerUrls";

type Props = {
  data: TransactionReceipt[];
};

export default function MintSendFaildTab({ data }: Props) {
  const selectedNetwork = useSelector(({ system }: RootState) => system.selectedNetwork);
  const href = convertExplorerUrls(selectedNetwork).transaction;

  return (
    <div className="nft-mint-list">
      {data?.map((item, index) => (
        <div className="nft-mint-item" key={index}>
          <div className="nft-mint-label">Transaction hash</div>
          <div className="nft-mint-hashs">
            <a href={href + item.transactionHash} target="_blank" rel="noreferrer">
              {item.transactionHash}
            </a>
          </div>
        </div>
      ))}
    </div>
  );
}
