import { BaseContract, Contract, ContractInterface } from "ethers";
import { Signer } from "@ethersproject/abstract-signer";
import Web3 from "web3";

export const getContract = <T extends BaseContract>(abi: ContractInterface, address: string, signer?: Signer): T => {
  return new Contract(address, abi, signer) as T;
};

export const getContractInstance = (ABIContract: any, contractAddress: string) => {
  const windowObj = window as any;
  const { ethereum } = windowObj;
  if (ethereum && ethereum.isMetaMask) {
    const web3Instance = new Web3(ethereum);
    return new web3Instance.eth.Contract(ABIContract, contractAddress);
  } else if (windowObj.web3) {
    const web3Instance = new Web3(windowObj.web3.currentProvider);
    return new web3Instance.eth.Contract(ABIContract, contractAddress);
  } else {
    return null;
  }
};
