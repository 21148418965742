import { Modal, ModalProps } from "antd";
import { LogoIcon } from "src/commons/resources/images";
import ResizeIcon from "src/components/08.resize-icon";

import "./styles.scss";

const ModalWithLogo = ({ children, className, ...props }: ModalProps) => {
  return (
    <Modal
      closeIcon={null}
      width={845}
      footer={null}
      centered
      className={`modal-with-logo ${className ?? ""}`}
      {...props}
    >
      <div className="modal-header">
        <ResizeIcon icon={LogoIcon} width={210} />
      </div>
      <div className="modal-content">{children}</div>
    </Modal>
  );
};

export default ModalWithLogo;
