import { Web3ReactHooks, Web3ReactProvider, initializeConnector } from "@web3-react/core";
import React, { PropsWithChildren, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MetaMask } from "@web3-react/metamask";
import { WalletConnect } from "@web3-react/walletconnect-v2";
import { metaMask, metamaskHooks } from "./web3/connectors/metamask";
import { WALLET_CONNECT_ID } from "./web3/constants";
import { setConnectors, setNetworks } from "./store/slices/system";
import { SystemService } from "./services/system-service";
import { ConnectorKey } from "./web3/connectors";

const AppContainer: React.FC<PropsWithChildren> = ({ children }) => {
  const networks = useSelector(({ system }: RootState) => system.networks);
  const [appConnectors, setAppConnectors] = useState<[MetaMask | WalletConnect, Web3ReactHooks][]>([]);

  const dispatch = useDispatch();

  useEffect(() => {
    const getNetworks = async () => {
      try {
        const { data } = await SystemService.getNetworks();
        if (!data) throw new Error();
        dispatch(setNetworks(data));
      } catch (error) {
        console.log("Cannot get networks", error);
      }
    };

    getNetworks();
  }, [dispatch]);

  useEffect(() => {
    if (!networks.length) return;

    const [walletConnect, walletConnectHooks] = initializeConnector<WalletConnect>(
      actions =>
        new WalletConnect({
          actions,
          options: {
            projectId: WALLET_CONNECT_ID,
            chains: networks.map(network => Number(network.network_id)),
            showQrModal: true,
            rpcMap: networks.reduce(
              (rpcs, network) => ({
                ...rpcs,
                [Number(network.network_id)]: network.rpc_urls,
              }),
              {}
            ),
          },
        })
    );
    dispatch(setConnectors({ [ConnectorKey.metaMask]: metaMask, [ConnectorKey.walletConnect]: walletConnect }));

    setAppConnectors([
      [metaMask, metamaskHooks],
      [walletConnect, walletConnectHooks],
    ]);
  }, [networks, dispatch]);

  if (!appConnectors.length) return null;
  return <Web3ReactProvider connectors={appConnectors}>{children}</Web3ReactProvider>;
};

export default AppContainer;
