import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CHAIN_ID_KEY, DEFAULT_CHAIN_ID } from "src/web3/constants";

const initialState: SystemState = {
  publicKey: "",
  chainId: Number(localStorage.getItem(CHAIN_ID_KEY) || 0) || DEFAULT_CHAIN_ID || 11155111,
  requesting: false,
  networks: [],
  connectors: {},
};

// slice
const systemSlice = createSlice({
  name: "system",
  initialState,
  reducers: {
    setPublicKey: (state, actions: PayloadAction<string>) => {
      state.publicKey = actions.payload;
    },
    setChainId: (state, actions: PayloadAction<number>) => {
      localStorage.setItem(CHAIN_ID_KEY, actions.payload.toString());
      state.chainId = actions.payload;
      state.selectedNetwork = state.networks.find(item => Number(item.network_id) === Number(state.chainId));
    },
    setRequesting: (state, actions: PayloadAction<boolean>) => {
      state.requesting = actions.payload;
    },
    setNetworks: (state, actions: PayloadAction<NetworkData[]>) => {
      state.networks = actions.payload;
      const selectedNetwork =
        state.networks.find(item => Number(item.network_id) === state.chainId) || state.networks[0];
      state.selectedNetwork = selectedNetwork;
      state.chainId = Number(selectedNetwork.network_id);
    },
    setConnectors: (state, actions: PayloadAction<Connectors>) => {
      state.connectors = actions.payload;
    },
  },
});

// actions
export const { setPublicKey, setChainId, setRequesting, setNetworks, setConnectors } = systemSlice.actions;

// reducer
export const systemReducer = systemSlice.reducer;
