import "./styles.scss";
import { Modal, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { LogoIcon } from "src/commons/resources/images";
import ResizeIcon from "src/components/08.resize-icon";

interface Props {
  open: boolean;
  description: string;
}

const WaitingModal = ({ open, description }: Props) => {
  return (
    <Modal
      centered
      closeIcon={null}
      width={845}
      footer={null}
      destroyOnClose={true}
      className={"waiting-modal"}
      open={open}
    >
      <div className="waiting-modal-header">
        <ResizeIcon icon={LogoIcon} width={210} />
      </div>
      <div className="waiting-modal-title">Please Wait</div>
      <div className="waiting-modal-loading">
        <Spin indicator={<LoadingOutlined style={{ fontSize: 130 }} spin />} />
      </div>
      <div className="waiting-modal-description">{description}</div>
    </Modal>
  );
};

export default WaitingModal;
