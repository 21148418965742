import { Button, Form } from "antd";
import { FormProps } from "antd/lib";
import { useEffect, useState } from "react";
import { REQUIRED } from "src/commons/messages/validate";
import MethodSubmitButton from "src/components/03.buttons/MethodSubmitButton";
import ModalWithLogo from "src/components/04.modals/ModalWithLogo";
import { useErc720Contract } from "src/web3/contracts/useErc720Contract";
import { customToast } from "src/components/02.toasts";

import "./styles.scss";
import { TokenService } from "src/services/token-service";
import { useSelector } from "react-redux";

type Props = {
  address: string;
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
};

const RenouncingOwnershipModal = ({ address, open, onClose, onSuccess }: Props) => {
  const selectedNetwork = useSelector(({ system }: RootState) => system.selectedNetwork);
  const [form] = Form.useForm<ChangeOwnerValues>();
  const [loading, setLoading] = useState(false);
  const { renounceOwnership } = useErc720Contract(address);

  const onFinish = async () => {
    if (!renounceOwnership || !selectedNetwork) return;
    setLoading(true);
    try {
      const transaction = await renounceOwnership();
      const data = await transaction?.wait(1);
      try {
        await TokenService.updateERC20TokenOwner(address, {
          new_owner: selectedNetwork.erc20_generator_address,
          status: data.status || 1,
          payload: data,
        });
      } catch (error) {
        console.log(error);
      }
      customToast.success("Renoucing Ownership Successfully");
      onClose();
      onSuccess();
    } catch (error: any) {
      console.log({ error });
      const errors: StringObject = error.response?.data?.errors || { name: "Renoucing Ownership Failed" };
      form.setFields(Object.entries(errors).map(([key, value]) => ({ name: key, errors: [value] })));
      form.getFieldInstance(Object.keys(errors)[0] || "name")?.focus();
      const errMessage = Object.values(errors)[0];
      customToast.error(typeof errMessage === "string" ? errMessage : "Renoucing Ownership Failed");
    }
    setLoading(false);
  };

  const onFinishFailed: FormProps["onFinishFailed"] = errorInfo => {
    const namePath = errorInfo.errorFields[0].name?.[0];
    form.getFieldInstance(namePath)?.focus();
  };

  const handleReset = () => {
    form.resetFields();
  };

  useEffect(() => {
    handleReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <ModalWithLogo open={open} footer={false}>
      <Form
        form={form}
        className="form token-method-form"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        layout="horizontal"
        onFinish={onFinish}
        validateMessages={{ required: REQUIRED() }}
        onFinishFailed={onFinishFailed}
      >
        <div className="token-method-title">Renoucing Ownership</div>

        <div className="token-method-confirm">
          <div className="token-method-confirm-title">Confirm Renouncing Ownership?</div>
          <div className="token-method-confirm-description">
            If you renounce, you will lose access to managing the token
          </div>
        </div>

        <div className="form-submit">
          <Button className="cancel-button" onClick={onClose}>
            Back
          </Button>
          <MethodSubmitButton htmlType="submit" type="primary" loading={loading}>
            Confirm
          </MethodSubmitButton>
        </div>
      </Form>
    </ModalWithLogo>
  );
};

export default RenouncingOwnershipModal;
