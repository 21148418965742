import { Form, Tooltip } from "antd";
import { useRef, useState } from "react";
import dayjs from "dayjs";
import { NFTService } from "src/services/nft-service";
import {
  ALLOW_STRING_NUMBER_ONLY,
  ALLOW_STRING_NUMBER_SPECIAL_ONLY,
  EXCEEDS_LENGTH_LIMIT,
  REQUIRED,
} from "src/commons/messages/validate";
import { convertToNFTBody } from "src/commons/utils/converters/nftConverter";
import { EXPAND_CREATE_NFT_PUBLIC_MINTING } from "src/commons/messages/expand";
import { InfoIcon } from "src/commons/resources/icons";
import ResizeIcon from "../08.resize-icon";
import NumberInput from "../07.inputs/NumberInput";
import { useSelector } from "react-redux";
import { FormProps } from "antd/lib";
import WaitingModal from "../04.modals/WaitingModal";
import { useFactoryContract } from "src/web3/contracts/useFactoryContract";
import CustomInput from "../07.inputs";
import CustomSwitch from "../10.switch";
import GradientButton from "../03.buttons/GradientButton";
import { useWeb3React } from "@web3-react/core";
import { TokenStatus } from "src/web3/constants";
import CustomTextArea from "../07.inputs/TextArea";
import CustomDatePicker from "../07.inputs/DatePicker";
import Upload, { UploadRef } from "../07.inputs/Upload";
import { UploadService } from "src/services/upload-service";
import CustomCollapse from "../09.collaspe";
import CreatedNFTModal from "../04.modals/CreatedNFTModal";
import { isAfterNow, isBetWeenMinMax, isLengthMinMax } from "src/commons/utils/functions/validates";
import { range } from "src/commons/utils/functions/range";
import { customToast } from "../02.toasts";

import "./styles.scss";
import { useTransaction } from "src/web3/hooks/useTransaction";

const FormItem = Form.Item<CreateNFTValues>;

const LIMIT = 5 * 1024 * 1024; //5MB
const ACCEPTEDS = ["image/png", "image/jpeg", "image/gif", "video/mp4"];

const CreateNFTForm = () => {
  const selectedNetwork = useSelector(({ system }: RootState) => system.selectedNetwork);
  const isAuth = useSelector(({ user }: RootState) => !!user.currentAccount);
  const { account } = useWeb3React();
  const [form] = Form.useForm<CreateNFTValues>();
  const [loading, setLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [tokenCreated, setNFTCreated] = useState<NFTCreated | null>(null);
  const { deploySmartContractRaw, getAddress } = useFactoryContract();
  const upload = useRef<UploadRef | null>(null);

  const { waitForTransaction } = useTransaction();

  const onFinish = async (values: CreateNFTValues) => {
    if (!deploySmartContractRaw || !getAddress) return;
    setLoading(true);
    try {
      if (!selectedNetwork) return;
      const body = convertToNFTBody(values, selectedNetwork);
      const res = await NFTService.createNFT(body);
      if (!res.data?.payload) throw new Error("Create NFT Failed");
      const { payload, salt } = res.data;

      try {
        const newContract = await deploySmartContractRaw(payload, salt?.toString() ?? "1");

        if (!newContract) throw new Error("Cannot deploy token");

        const address = await getAddress(payload, salt?.toString() ?? "1");

        const { hash } = newContract;
        try {
          await NFTService.updateNFTLog(address.toLowerCase(), {
            status: TokenStatus.SUBMITTED,
            payload: { hash, blockNumber: "0000000" },
          });
        } catch (error) {
          console.log({ error });
        }

        await newContract.wait(1);
        await waitForTransaction(hash);

        const { symbol, media_url } = body;
        customToast.success("Create NFT successfully");

        setNFTCreated({ address, symbol, image: media_url || "", decimals: 0 });
      } catch (error: any) {
        console.log({ error });
        if (error.code === "ACTION_REJECTED") customToast.error("Please accept transaction to complete nft creation");
        else customToast.error("Transaction Error");
      }
    } catch (error: any) {
      console.log({ error });
      const errors: StringObject = error.response?.data?.errors || { name: "Create NFT Failed" };
      form.setFields(Object.entries(errors).map(([key, value]) => ({ name: key, errors: [value] })));
      form.getFieldInstance(Object.keys(errors)[0] || "name")?.focus();
      const errMessage = Object.values(errors)[0];
      customToast.error(typeof errMessage === "string" ? errMessage : "Create NFT Failed");
    }
    setLoading(false);
  };

  const onFinishFailed: FormProps["onFinishFailed"] = errorInfo => {
    const namePath = errorInfo.errorFields[0].name?.[0];
    form.getFieldInstance(namePath)?.focus();
  };

  const handleReset = () => {
    form.resetFields();
    setNFTCreated(null);
    upload.current?.reset();
  };

  const handleUpload = async (file: File) => {
    setUploadLoading(true);
    try {
      const { data } = await UploadService.upload(file);
      if (!data?.resource_uri) throw new Error("Cannot upload file");
      form.setFieldValue("media_url", data.resource_uri);
    } catch (error: any) {
      console.log(error);
      const errors: StringObject = error.response?.data?.errors || { file: "Cannot upload file" };
      form.setFields(Object.entries(errors).map(([key, value]) => ({ name: key, errors: [value] })));
      form.getFieldInstance(Object.keys(errors)[0] || "file")?.focus();
      const errMessage = Object.values(errors)[0];
      customToast.error(typeof errMessage === "string" ? errMessage : "Cannot upload file");
    }
    setUploadLoading(false);
  };

  return (
    <Form
      form={form}
      className="form create-token-form"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      layout="horizontal"
      onFinish={onFinish}
      validateMessages={{ required: REQUIRED() }}
      onFinishFailed={onFinishFailed}
    >
      <FormItem
        colon
        label="NFT Name"
        name="name"
        rules={[
          { required: true, message: "NFT name is required. Please provide a name for your NFT" },
          {
            max: 255,
            message: EXCEEDS_LENGTH_LIMIT(255, "NFT name"),
          },
          { pattern: /^[a-zA-Z0-9-_]+$/, message: ALLOW_STRING_NUMBER_SPECIAL_ONLY("NFT name") },
        ]}
      >
        <CustomInput onKeyDown={e => !/^[a-zA-Z0-9-_]+$/.test(e.key) && e.preventDefault()} />
      </FormItem>
      <FormItem
        colon
        label="Symbol"
        name="symbol"
        rules={[
          { required: true, message: "NFT symbol is required. Please provide a symbol for your NFT" },
          {
            max: 10,
            message: EXCEEDS_LENGTH_LIMIT(10, "NFT symbol", "symbol"),
          },
          { pattern: /^[a-zA-Z0-9]+$/, message: ALLOW_STRING_NUMBER_ONLY("NFT symbol") },
        ]}
      >
        <CustomInput
          onKeyDown={e => !/^[a-zA-Z0-9]+$/.test(e.key) && e.preventDefault()}
          onChange={e => form.setFieldValue("symbol", e.target.value.toUpperCase())}
        />
      </FormItem>
      <FormItem
        colon
        label="Description"
        name="description"
        rules={[{ validator: isLengthMinMax(0, 500, "Description") }]}
      >
        <CustomTextArea rows={5} maxLength={500} showCount />
      </FormItem>
      <div className="description-input">Maximum 500 characters</div>
      <Upload
        name="file"
        accepts={ACCEPTEDS}
        limit={LIMIT}
        preview
        loading={uploadLoading}
        onChange={handleUpload}
        setRef={ref => (upload.current = ref)}
        placeholder={
          <>
            Upload Media
            <br />
            Img/ PNG/ Gif/ Mp4 (max 5MB)
          </>
        }
      />
      <FormItem
        colon
        label="Media URL"
        name="media_url"
        rules={[{ type: "url", message: "This field must be a valid url." }]}
      >
        <CustomInput
          placeholder="https://"
          onKeyDown={() => {
            upload.current?.reset();
            form.setFields([{ name: "file", errors: [] }]);
          }}
          disabled={uploadLoading}
        />
      </FormItem>

      <FormItem colon label="Mint Price" name="mint_price" rules={[{ required: true }]} wrapperCol={{ span: 12 }}>
        <NumberInput
          name="mint_price"
          decimalsLimit={selectedNetwork?.native_currency.decimals}
          placeholder=""
          rules={[{ required: true }]}
        />
      </FormItem>
      <FormItem noStyle dependencies={["global_max_nft_mint"]}>
        {() => (
          <FormItem
            colon
            label="Max NFT mint amount per user"
            name="max_nft_mint_amount_per_user"
            dependencies={["global_max_nft_mint"]}
            rules={[
              {
                validator: isBetWeenMinMax(
                  0,
                  Number(form.getFieldValue("global_max_nft_mint")) || Infinity,
                  "",
                  "Maximum mint per address can’t exceed global max mint ."
                ),
              },
            ]}
            wrapperCol={{ span: 12 }}
          >
            <NumberInput
              name="max_nft_mint_amount_per_user"
              allowDecimals={false}
              placeholder=""
              rules={[
                {
                  validator: isBetWeenMinMax(
                    0,
                    Number(form.getFieldValue("global_max_nft_mint")) || Infinity,
                    "",
                    "Maximum mint per address can’t exceed global max mint ."
                  ),
                },
              ]}
            />
          </FormItem>
        )}
      </FormItem>
      <FormItem colon label="Global Max NFT mint" name="global_max_nft_mint" wrapperCol={{ span: 12 }}>
        <NumberInput name="global_max_nft_mint" allowDecimals={false} placeholder="" />
      </FormItem>
      <FormItem colon label="Enable Public Minting">
        <div className="expand-input">
          <FormItem noStyle name="enable_public_minting" valuePropName="checked">
            <CustomSwitch />
          </FormItem>
          <Tooltip title={EXPAND_CREATE_NFT_PUBLIC_MINTING}>
            <ResizeIcon icon={InfoIcon} className="field-info" width={33} />
          </Tooltip>
        </div>
      </FormItem>

      <FormItem noStyle dependencies={["enable_public_minting"]}>
        {() => (
          <CustomCollapse active={!form.getFieldValue("enable_public_minting")}>
            <FormItem noStyle dependencies={["end_date"]}>
              {() => (
                <FormItem colon label="Start Time" name="start_date" rules={[{ validator: isAfterNow("Start time") }]}>
                  <CustomDatePicker
                    showTime
                    disabledDate={current => {
                      const now = dayjs();
                      if (now && current && current.endOf("day") < now.endOf("day")) return true;
                      const endTime = form.getFieldValue("end_date") as Dayjs | undefined;
                      return !!endTime && current && current.endOf("day") > endTime.endOf("day");
                    }}
                    disabledTime={current => {
                      const now = dayjs();
                      const endTime = form.getFieldValue("end_date") as Dayjs | undefined;
                      if (!now.isSame(current, "date") && (!endTime || !endTime.isSame(current, "date")))
                        return {
                          disabledHours: () => [],
                          disabledMinutes: () => [],
                          disabledSeconds: () => [],
                        };
                      const isNow = now.isSame(current, "date");
                      const nowHour = now.hour();
                      const nowMinute = now.minute();
                      const nowSecond = now.second();
                      if (!endTime) {
                        return {
                          disabledHours: () => (isNow ? range(0, nowHour) : []),
                          disabledMinutes: hour => (isNow && hour === nowHour ? range(0, nowMinute) : []),
                          disabledSeconds: (hour, minus) =>
                            isNow && hour === nowHour && minus === nowMinute ? range(0, nowSecond + 1) : [],
                        };
                      }
                      const isEnd = endTime.isSame(current, "date");
                      const endHour = endTime.hour();
                      const endMinute = endTime.minute();
                      const endSecond = endTime.second();

                      return {
                        disabledHours: () => [
                          ...(isNow ? range(0, nowHour) : []),
                          ...(isEnd ? range(endHour + 1, 24) : []),
                        ],
                        disabledMinutes: hour => [
                          ...(isNow && hour === nowHour ? range(0, nowMinute) : []),
                          ...(isEnd && hour === endHour ? range(endMinute + 1, 60) : []),
                        ],
                        disabledSeconds: (hour, minus) => [
                          ...(isNow && hour === nowHour && minus === nowMinute ? range(0, nowSecond + 1) : []),
                          ...(isEnd && hour === endHour && minus === endMinute ? range(endSecond, 60) : []),
                        ],
                      };
                    }}
                  />
                </FormItem>
              )}
            </FormItem>
            <div className="description-input">
              Start time from when this NFT can be minted. If empty, there is no time limit.
            </div>
            <FormItem noStyle dependencies={["start_date"]}>
              {() => (
                <FormItem colon label="End Time" name="end_date" rules={[{ validator: isAfterNow("End time") }]}>
                  <CustomDatePicker
                    showTime
                    disabledDate={current => {
                      const startTime = form.getFieldValue("start_date") as Dayjs | undefined;
                      return !!startTime && current && current.endOf("day") < startTime.endOf("day");
                    }}
                    disabledTime={current => {
                      const startTime = form.getFieldValue("start_date") as Dayjs | undefined;
                      const lastTime = startTime && startTime.isAfter() ? startTime : dayjs();

                      const isSame = lastTime.isSame(current, "date");
                      const lastHour = lastTime.hour();
                      const lastMinute = lastTime.minute();
                      const lastSecond = lastTime.second();
                      return {
                        disabledHours: () => (isSame ? range(0, lastHour) : []),
                        disabledMinutes: hour => (isSame && hour === lastHour ? range(0, lastMinute) : []),
                        disabledSeconds: (hour, minus) =>
                          isSame && hour === lastHour && minus === lastMinute ? range(0, lastSecond + 1) : [],
                      };
                    }}
                  />
                </FormItem>
              )}
            </FormItem>
            <div className="description-input">
              End time from when this NFT can be minted. If empty, there is no time limit.
            </div>
          </CustomCollapse>
        )}
      </FormItem>
      {(!account || !isAuth) && <div className="danger-message">You have to Connect Wallet before creating a NFT</div>}

      <div className="form-submit">
        <GradientButton htmlType="submit" type="primary" disabled={!account || !isAuth} loading={loading}>
          Create NFT
        </GradientButton>
      </div>
      <WaitingModal open={loading} description="Your NFT is being created" />
      <CreatedNFTModal nft={tokenCreated} onClose={handleReset} />
    </Form>
  );
};

export default CreateNFTForm;
