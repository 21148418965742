import dayjs from "dayjs";
import { TabsProps } from "antd";

import { CanMintIcon } from "src/commons/resources/icons";
import { formatNumber } from "src/commons/utils/functions/formatNumber";
import CopyButton from "../../11.copy-button";
import ResizeIcon from "../../08.resize-icon";
import MethodButton from "../../03.buttons/MethodButton";
import CustomTabs from "src/components/13.tabs";
import MintSendingTab from "./mint-tabs/sending";
import MintSendTab from "./mint-tabs/send";
import MintSendFaildTab from "./mint-tabs/send-failed";
import { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";
import { useSelector } from "react-redux";
import { divDecimals } from "src/commons/utils/functions/divDecimals";
import NFTABI from "src/web3/abis/FullFeatureNFT.json";
import { getContractInstance } from "src/web3/helpers/getContract";
import { customToast } from "src/components/02.toasts";
import { convertExplorerUrls } from "src/commons/utils/converters/converterExplorerUrls";

import "./styles.scss";

type Props = {
  nft: NFTType;
  address: string;
};

const NFTMint = ({ nft, address }: Props) => {
  const { global_max_nft_mint, max_nft_mint_amount_per_user, mint_price, start_date, end_date, enable_public_minting } =
    nft;
  const { account } = useWeb3React();
  const [loading, setLoading] = useState(false);
  const [transaction, setTransaction] = useState<TransactionReceipt | null>(null);
  const [sendings, setSendings] = useState<TransactionReceipt[]>([]);
  const [sends, setSends] = useState<TransactionReceipt[]>([]);
  const [faileds, setFaileds] = useState<TransactionReceipt[]>([]);
  const selectedNetwork = useSelector((state: RootState) => state.system.selectedNetwork);
  const explorer = convertExplorerUrls(selectedNetwork);

  useEffect(() => {
    if (!selectedNetwork || !transaction) return;

    const waitingTransaction = async () => {
      try {
        setSendings(sendings => [transaction, ...sendings]);

        const provider = new ethers.providers.JsonRpcProvider(selectedNetwork.rpc_urls[0]);
        const result = await provider.getTransactionReceipt(transaction.transactionHash);

        if (result.status !== 1) throw new Error();

        setSends(sends => [transaction, ...sends]);
      } catch (error) {
        console.log(error);
        setFaileds(faileds => [transaction, ...faileds]);
      }
      setSendings(sendings => sendings.filter(sending => sending.transactionHash !== transaction.transactionHash));
      setLoading(false);
    };

    waitingTransaction();
  }, [transaction, selectedNetwork]);

  const handleMint = async () => {
    setLoading(true);
    try {
      if (!account || !selectedNetwork) return;

      const nftContract = getContractInstance(NFTABI, address);

      if (!nftContract) return;
      const transaction = await nftContract.methods.mint(account).send({
        from: account,
        to: address,
        value: mint_price,
        gasPrice: 10000000000,
      });

      setTransaction(transaction);

      customToast.success("Mint NFT successfully");
    } catch (error: any) {
      console.log({ error });
      if (error.code === "ACTION_REJECTED") customToast.error("Please accept transaction to mint nft");
      customToast.error("Mint NFT failed");
      setLoading(false);
    }
  };

  const tabs: TabsProps["items"] = [
    { key: "sending", label: `In Sending Queue (${sendings.length})`, children: <MintSendingTab data={sendings} /> },
    { key: "send", label: `Sent (${sends.length})`, children: <MintSendTab data={sends} /> },
    { key: "failed", label: `Failed to send (${faileds.length})`, children: <MintSendFaildTab data={faileds} /> },
  ];
  return (
    <div className="nft-mint">
      <div className="nft-mint-header">
        <div className="nft-mint-info">
          <div className="nft-info-label">Contract Address</div>
          <div className="nft-info-value">
            <div className="highlight-text">
              <a href={explorer.address + address}>{address}</a>
            </div>
            <div className="manual-verify-copy-button">
              <CopyButton text={address} />
            </div>
          </div>
        </div>
        <div className="nft-mint-info">
          <div className="nft-info-label">NFT Type </div>
          <div className="nft-info-value">{"Single asset"}</div>
        </div>
        {!!global_max_nft_mint && (
          <div className="nft-mint-info">
            <div className="nft-info-label">Global max mint amount</div>
            <div className="nft-info-value">{formatNumber(global_max_nft_mint)}</div>
          </div>
        )}
        {!!max_nft_mint_amount_per_user && (
          <div className="nft-mint-info">
            <div className="nft-info-label">Max mint amount per user</div>
            <div className="nft-info-value">{formatNumber(max_nft_mint_amount_per_user)}</div>
          </div>
        )}
        {!!mint_price && (
          <div className="nft-mint-info">
            <div className="nft-info-label">Mint Price</div>
            <div className="nft-info-value">
              {formatNumber(divDecimals(mint_price, selectedNetwork?.native_currency.decimals || 0))}{" "}
              {selectedNetwork?.native_currency.symbol}
            </div>
          </div>
        )}
        {!!start_date && (
          <div className="nft-mint-info">
            <div className="nft-info-label">Start Time</div>
            <div className="nft-info-value">{dayjs(start_date).format("DD MMM YYYY")}</div>
          </div>
        )}
        {!!end_date && (
          <div className="nft-mint-info">
            <div className="nft-info-label">End Time</div>
            <div className="nft-info-value">{dayjs(end_date).format("DD MMM YYYY")}</div>
          </div>
        )}
        <div className="nft-mint-info">
          <div className="nft-info-label">Minting Permission</div>
          <div className="nft-info-value">{enable_public_minting ? "Public" : "Private"}</div>
        </div>
      </div>
      <MethodButton className="nft-method-mint" onClick={handleMint} loading={loading}>
        {!loading && <ResizeIcon icon={CanMintIcon} height={16} />}
        Mint NFT
      </MethodButton>
      {!!(sendings.length || sends.length || faileds.length) && (
        <>
          <div className="nft-method-title">Minting</div>
          <CustomTabs items={tabs} defaultActiveKey="sending" />
        </>
      )}
    </div>
  );
};

export default NFTMint;
