import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: string = "Light";

// slice
const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    setTheme: (state, actions: PayloadAction<string>) => (state = actions.payload),
  },
});

// actions 

// reducer
export const themeReducer = themeSlice.reducer;
