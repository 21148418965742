import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: AuthState = {
  isAuthChecking: true,
  isAuth: false,
  isLoading: false,
};

// slice
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setIsAuthChecking: (state, actions: PayloadAction<boolean>) => {
      state.isAuthChecking = actions.payload;
    },
    setIsAuth: (state, actions: PayloadAction<boolean>) => {
      state.isAuth = actions.payload;
    },
    setIsLoading: (state, actions: PayloadAction<boolean>) => {
      state.isLoading = actions.payload;
    },
  },
});

// actions
const authActions = authSlice.actions;
export const { setIsAuthChecking, setIsAuth, setIsLoading } = authActions;

// reducer
export const authReducer = authSlice.reducer;
