import "./styles.scss";

import { Button, Col, Form, Tooltip, Radio, Row, Select } from "antd";
import { FormProps } from "antd/lib";
import dayjs from "dayjs";
import { REQUIRED } from "src/commons/messages/validate";
import MethodSubmitButton from "src/components/03.buttons/MethodSubmitButton";
import CustomInput from "src/components/07.inputs";
import NumberInput from "src/components/07.inputs/NumberInput";
import ModalWithLogo from "src/components/04.modals/ModalWithLogo";
import { isAddress, isAfterNow, isBetWeenMinMax } from "src/commons/utils/functions/validates";
import { formatNumber } from "src/commons/utils/functions/formatNumber";
import CustomRangePicker from "../07.inputs/RangePicker";
import CustomSelect from "../12.select";
import CustomSwitch from "../10.switch";
import { EXPAND_VESTING_TOKEN_LOCK_PERIOD } from "src/commons/messages/expand";
import { InfoIcon } from "src/commons/resources/icons";
import ResizeIcon from "../08.resize-icon";
import CustomCollapse from "../09.collaspe";
import CustomDatePicker from "../07.inputs/DatePicker";
import { range } from "src/commons/utils/functions/range";
import { useEffect } from "react";
import { divDecimals } from "src/commons/utils/functions/divDecimals";

type Props = {
  token: TokenType;
  open: boolean;
  onClose: () => void;
  totalSupply: string;
  onSubmit: (values: VestingTokenItemValues) => void;
  onEdit: VestingTokenItemValues | null;
};

const FormItem = Form.Item<VestingTokenItemValues>;

const candences = [
  { value: 1, label: "Per Second" },
  { value: 60, label: "Per Minute" },
  { value: 60 * 60, label: "Per Hour" },
  { value: 24 * 60 * 60, label: "Per Day" },
  { value: 7 * 24 * 60 * 60, label: "Per Week" },
  { value: 3 * 30 * 24 * 60 * 60, label: "Every Three months" },
  { value: 365 * 60 * 60, label: "Every Year" },
];

const AddVestingTokenForm = ({ token, open, totalSupply, onClose, onSubmit, onEdit }: Props) => {
  const [form] = Form.useForm<VestingTokenItemValues>();
  const { name, decimals } = token;

  const onFinishFailed: FormProps["onFinishFailed"] = errorInfo => {
    const namePath = errorInfo.errorFields[0].name?.[0];
    form.getFieldInstance(namePath)?.focus();
  };

  useEffect(() => {
    if (onEdit) form.setFieldsValue(onEdit);
    else form.resetFields();
  }, [onEdit, form, open]);

  return (
    <ModalWithLogo open={open} footer={false}>
      <Form
        form={form}
        className="form add-vesting-token-form"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        layout="horizontal"
        onFinish={onSubmit}
        validateMessages={{ required: REQUIRED() }}
        onFinishFailed={onFinishFailed}
        initialValues={{ isEmployee: 1, candence: candences[0].value }}
      >
        <div className="add-vesting-token-title">Create Vesting Plan</div>
        <div className="token-detail-info">
          <div className="token-info-label">Token Name</div>
          <div className="token-info-value">{name}</div>
        </div>
        <div className="token-detail-info">
          <div className="token-info-label">Total Supply</div>
          <div className="token-info-value">{formatNumber(totalSupply, decimals)}</div>
        </div>
        <Row gutter={24}>
          <Col span={24} md={12}>
            <FormItem
              colon
              label="Wallet Address"
              name="address"
              rules={[{ required: true }, { validator: isAddress("Wallet ") }]}
            >
              <CustomInput placeholder="Enter wallet address" />
            </FormItem>
          </Col>
          <Col span={24} md={12}>
            <FormItem colon label="Wallet Address Name " name="name">
              <CustomInput placeholder="Enter wallet address" />
            </FormItem>
          </Col>
        </Row>
        <FormItem name="isEmployee">
          <Radio.Group className="vesting-create-type" name="isEmployee">
            <Radio value={1}>Employee</Radio>
            <Radio value={0}>Investor</Radio>
          </Radio.Group>
        </FormItem>
        <Row gutter={24}>
          <Col span={24} md={12}>
            <FormItem
              colon
              label="Number Of Tokens"
              name="amount"
              rules={[
                { required: true },
                {
                  validator: isBetWeenMinMax(
                    0,
                    Number(divDecimals(totalSupply, decimals)),
                    undefined,
                    "Can input number of token exceed total supply"
                  ),
                },
              ]}
            >
              <NumberInput
                name="amount"
                rules={[
                  { required: true },
                  {
                    validator: isBetWeenMinMax(
                      0,
                      Number(divDecimals(totalSupply, decimals)),
                      undefined,
                      "Can input number of token exceed total supply"
                    ),
                  },
                ]}
                decimalsLimit={decimals}
                placeholder="e.g: 10 000 000"
              />
            </FormItem>
            <div className="description-input">Enter the number of token this address can claim</div>
          </Col>
          <Col span={24} md={12}>
            <FormItem colon label="Vesting Period " name="vestingPeriod" rules={[{ required: true }]}>
              <CustomRangePicker
                showTime
                disabledDate={current => {
                  const now = dayjs();
                  return now && current && current.endOf("day") < now.endOf("day");
                }}
                disabledTime={current => {
                  const now = dayjs();
                  if (!now.isSame(current, "date"))
                    return {
                      disabledHours: () => [],
                      disabledMinutes: () => [],
                      disabledSeconds: () => [],
                    };
                  const nowHour = now.hour();
                  const nowMinute = now.minute();
                  const nowSecond = now.second();
                  return {
                    disabledHours: () => range(0, nowHour),
                    disabledMinutes: hour => (hour === nowHour ? range(0, nowMinute) : []),
                    disabledSeconds: (hour, minus) =>
                      hour === nowHour && minus === nowMinute ? range(0, nowSecond + 1) : [],
                  };
                }}
              />
            </FormItem>
            <div className="description-input">Select Start and End date of Vesting</div>
          </Col>
        </Row>
        <FormItem label="Vesting Candence" name="candence" labelCol={{ span: 24 }} rules={[{ required: true }]}>
          <CustomSelect showSearch placeholder="Select Vesting Candence">
            {candences.map(({ value, label }) => (
              <Select.Option key={value} value={value}>
                <div className="token-select-item">
                  <span>{label}</span>
                </div>
              </Select.Option>
            ))}
          </CustomSelect>
        </FormItem>
        <FormItem colon label="Lock Period">
          <div className="expand-input">
            <FormItem noStyle name="lock" valuePropName="checked">
              <CustomSwitch onChange={checked => !checked && form.setFieldValue("lockTime", null)} />
            </FormItem>
            <Tooltip title={EXPAND_VESTING_TOKEN_LOCK_PERIOD}>
              <ResizeIcon icon={InfoIcon} className="field-info" width={33} />
            </Tooltip>
          </div>
        </FormItem>
        <FormItem noStyle dependencies={["lock"]}>
          {() => (
            <CustomCollapse active={!form.getFieldValue("lock")}>
              <Row gutter={24}>
                <Col span={24} md={12}>
                  <FormItem
                    colon
                    label="Lock Until"
                    name="lockTime"
                    rules={[{ required: form.getFieldValue("lock") }, { validator: isAfterNow("Lock Period") }]}
                  >
                    <CustomDatePicker
                      placeholder=""
                      showTime
                      disabledDate={current => {
                        const now = dayjs();
                        return now && current && current.endOf("day") < now.endOf("day");
                      }}
                      disabledTime={current => {
                        const now = dayjs();
                        if (!now.isSame(current, "date"))
                          return {
                            disabledHours: () => [],
                            disabledMinutes: () => [],
                            disabledSeconds: () => [],
                          };
                        const nowHour = now.hour();
                        const nowMinute = now.minute();
                        const nowSecond = now.second();
                        return {
                          disabledHours: () => range(0, nowHour),
                          disabledMinutes: hour => (hour === nowHour ? range(0, nowMinute) : []),
                          disabledSeconds: (hour, minus) =>
                            hour === nowHour && minus === nowMinute ? range(0, nowSecond + 1) : [],
                        };
                      }}
                    />
                  </FormItem>
                  <div className="description-input">The time when this address can claim tokens</div>
                </Col>
              </Row>
            </CustomCollapse>
          )}
        </FormItem>
        <div className="form-submit">
          <Button className="cancel-button" onClick={onClose}>
            Back
          </Button>
          <MethodSubmitButton htmlType="submit" type="primary">
            {onEdit ? "Update" : "Add"}
          </MethodSubmitButton>
        </div>
      </Form>
    </ModalWithLogo>
  );
};

export default AddVestingTokenForm;
