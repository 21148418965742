import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { NFTService } from "src/services/nft-service";
import NotFound from "../NotFound";
import { useSelector } from "react-redux";
import { useWeb3React } from "@web3-react/core";
import NFTMinting from "src/components/nft-minting/NFTMinting";
import { isSameAddress } from "src/web3/helpers";

import "./styles.scss";

const NFTMintingPage = () => {
  const [loading, setLoading] = useState(false);
  const [nft, setNFT] = useState<NFTType | null>(null);
  const { address } = useParams<{ address?: string }>();
  const isAuth = useSelector(({ user }: RootState) => !!user.currentAccount);
  const { account } = useWeb3React();

  const logged = isAuth && account;

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      try {
        if (address) {
          const { data } = await NFTService.getNFTDetail(address.toLowerCase());
          setNFT(data);
        }
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };

    if (logged) getData();
  }, [address, logged]);

  if (loading) return null;

  if (!address || !isAuth || !account || !nft || !isSameAddress(account, nft.owner_address)) return <NotFound />;

  return (
    <div>
      <div className="form-title">Manage NFT</div>
      <NFTMinting address={address} nft={nft} />
    </div>
  );
};

export default NFTMintingPage;
