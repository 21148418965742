import { Button, Form, Select } from "antd";
import { FormProps } from "antd/lib";
import { useEffect, useState } from "react";
import { REQUIRED } from "src/commons/messages/validate";
import MethodSubmitButton from "src/components/03.buttons/MethodSubmitButton";
import NumberInput from "src/components/07.inputs/NumberInput";
import ModalWithLogo from "src/components/04.modals/ModalWithLogo";
import { formatNumber } from "src/commons/utils/functions/formatNumber";
import { TokenService } from "src/services/token-service";
import CustomSelect from "src/components/12.select";
import { multipleDecimals } from "src/commons/utils/functions/multipleDecimals";
import ResizeIcon from "src/components/08.resize-icon";
import { InfoIcon } from "src/commons/resources/icons";
import { customToast } from "src/components/02.toasts";

import "./styles.scss";

type Props = {
  address: string;
  token: TokenType;
  open: boolean;
  onClose: () => void;
  totalSupply: string;
};

const FormItem = Form.Item<AddToLiquidityPoolValues>;

const AddToLiquidityPoolModal = ({ address, token, open, totalSupply, onClose }: Props) => {
  const [form] = Form.useForm<AddToLiquidityPoolValues>();
  const [loading, setLoading] = useState(false);
  const [tokens, setTokens] = useState<TokenItem[]>([]);
  const [tokensLoading, setTokensLoading] = useState(false);

  const { name, decimals } = token;

  const onFinish = async (values: AddToLiquidityPoolValues) => {
    setLoading(true);
    try {
      const { address, amount1, amount2 } = values;
      const body = {
        address,
        amount1: multipleDecimals(amount1, decimals),
        amount2: multipleDecimals(amount2, decimals),
      };
      console.log(body);
    } catch (error: any) {
      console.log({ error });
      const errors: StringObject = error.response?.data?.errors || { name: "Change fee transaction Failed" };
      form.setFields(Object.entries(errors).map(([key, value]) => ({ name: key, errors: [value] })));
      form.getFieldInstance(Object.keys(errors)[0] || "name")?.focus();
      const errMessage = Object.values(errors)[0];
      customToast.error(typeof errMessage === "string" ? errMessage : "Change fee transaction Failed");
    }
    setLoading(false);
  };

  const onFinishFailed: FormProps["onFinishFailed"] = errorInfo => {
    const namePath = errorInfo.errorFields[0].name?.[0];
    form.getFieldInstance(namePath)?.focus();
  };

  const handleReset = () => {
    form.resetFields();
  };

  useEffect(() => {
    const getData = async () => {
      setTokensLoading(true);
      try {
        const { data } = await TokenService.getERC20Tokens();
        setTokens(data || []);
      } catch (error) {
        console.log(error);
      }
      setTokensLoading(false);
    };

    if (open) getData();
  }, [open]);

  useEffect(() => {
    handleReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <ModalWithLogo open={open} footer={false}>
      <Form
        form={form}
        className="form token-method-form add-to-liquidity-pool"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        layout="horizontal"
        onFinish={onFinish}
        validateMessages={{ required: REQUIRED() }}
        onFinishFailed={onFinishFailed}
      >
        <div className="token-method-title">Burn Fee ( Deflationary Token)</div>
        <div className="token-detail-info">
          <div className="token-info-label">Token Name</div>
          <div className="token-info-value">{name}</div>
        </div>
        <div className="token-detail-info">
          <div className="token-info-label">Total Supply</div>
          <div className="token-info-value">{formatNumber(totalSupply, decimals)}</div>
        </div>
        <div className="token-method-note">
          <div className="highlight-text">
            If your token has a whitelist, you need to add the address {address} to the whitelist!
          </div>
          <ResizeIcon className="token-method-note-icon" icon={InfoIcon} width={24} height={24} />
        </div>
        <div className="token-method-note">
          <div className="highlight-text">
            If your token has burn or tax, it needs to be set to 0. After adding the liquidity pool, modify it back. If
            it fails, please close the tax on your tokens.
          </div>
          <ResizeIcon className="token-method-note-icon" icon={InfoIcon} width={24} height={24} />
        </div>
        <div className="token-method-note danger">
          <div className="highlight-text">Can only be added once. Please fill in carefully!</div>
          <ResizeIcon className="token-method-note-icon" icon={InfoIcon} width={24} height={24} />
        </div>
        <div className="token-detail-info">
          <div className="token-info-label">DEX Platform</div>
          <div className="token-info-value">TBD</div>
        </div>
        <div className="token-liquidity-rate">1 Token 1 = X Token 2 (HT)</div>
        <FormItem colon label="Token 1 Contract Address" name="address" rules={[{ required: true }]}>
          <CustomSelect showSearch placeholder="Enter Token Address" loading={tokensLoading}>
            {tokens.map(({ address, symbol }) => (
              <Select.Option key={address} value={address}>
                <div className="token-select-item">
                  <span>{address}</span> <span className="token-select-symbol">{symbol}</span>
                </div>
              </Select.Option>
            ))}
          </CustomSelect>
        </FormItem>
        <FormItem colon label="Token 1 amount" name="amount1" rules={[{ required: true }]}>
          <NumberInput
            name="amount1"
            rules={[{ required: true }]}
            decimalsLimit={decimals}
            placeholder="E.g: 100,000"
          />
        </FormItem>
        <FormItem colon label="Token 2 amount" name="amount2" rules={[{ required: true }]}>
          <NumberInput
            name="amount2"
            rules={[{ required: true }]}
            decimalsLimit={decimals}
            placeholder="E.g: 100,000"
          />
        </FormItem>

        <div className="form-submit">
          <Button className="cancel-button" onClick={onClose}>
            Back
          </Button>
          <MethodSubmitButton htmlType="submit" type="primary" loading={loading}>
            Submit
          </MethodSubmitButton>
        </div>
      </Form>
    </ModalWithLogo>
  );
};

export default AddToLiquidityPoolModal;
