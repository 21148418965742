import { useWeb3React } from "@web3-react/core";
import { Dropdown, Layout, Spin } from "antd";
import React, { useMemo, useState } from "react";
import ProfileDetail from "./ProfileDetail";
import { ellipseAddress } from "src/commons/utils/functions/ellipseAddress";
import { ConnectWalletUrl } from "src/commons/resources/icons";
import { useSelector } from "react-redux";
import ConnectWalletModal from "src/components/04.modals/ConnectWalletModal";
import GradientButton from "src/components/03.buttons/GradientButton";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import { useConnectWallet } from "src/web3/hooks";

import "./styles.scss";
import { LoadingOutlined } from "@ant-design/icons";

const HeaderTop: React.FC = () => {
  const isAuth = useSelector(({ user }: RootState) => !!user.currentAccount);
  const publicKey = useSelector(({ system }: RootState) => system.publicKey);
  const chainId = useSelector(({ system }: RootState) => system.chainId);
  const networks = useSelector(({ system }: RootState) => system.networks);
  const selectedNetwork = useSelector(({ system }: RootState) => system.selectedNetwork);
  const requesting = useSelector(({ system }: RootState) => system.requesting);
  const { account } = useWeb3React();
  const [isOpenConnectWalletModal, setIsOpenConnectWalletModal] = useState(false);
  const [open, setOpen] = useState(false);
  const { requestChangeNetwork } = useConnectWallet();

  const items: ItemType[] = useMemo(() => {
    const renderItem = ({ network_id, name, icon_urls }: NetworkData): ItemType => ({
      key: network_id,
      label: <div onClick={() => requestChangeNetwork(Number(network_id))}>{name}</div>,
      icon: <img src={icon_urls[0]} alt={name} />,
      className: network_id === chainId.toString() ? "network-selected" : "",
    });
    return [
      { key: "mainnet", label: "Mainnets", disabled: true, className: "network-type" },
      ...networks.filter(item => !item.is_testnet).map(renderItem),
      { key: "testnet", label: "Testnets", disabled: true, className: "network-type" },
      ...networks.filter(item => item.is_testnet).map(renderItem),
    ];
  }, [chainId, requestChangeNetwork, networks]);

  return (
    <Layout.Header id="header">
      <Dropdown
        placement="bottom"
        className="select-network"
        menu={{ items }}
        rootClassName="select-network-menu"
        trigger={["click"]}
        disabled={requesting}
      >
        <div onClick={() => setOpen(!open)}>
          {requesting ? (
            <Spin indicator={<LoadingOutlined />} size="small" />
          ) : (
            <img src={selectedNetwork?.icon_urls[0]} alt={selectedNetwork?.name} />
          )}
          {selectedNetwork?.name}
        </div>
      </Dropdown>
      {isAuth && account ? (
        <ProfileDetail>
          <GradientButton className="user-wallet-button">
            <img src={ConnectWalletUrl} alt="connect-wallet" />
            <span>{ellipseAddress(account, 6, 5)}</span>
          </GradientButton>
        </ProfileDetail>
      ) : (
        <GradientButton
          className="connect-wallet-button"
          onClick={() => setIsOpenConnectWalletModal(true)}
          loading={!publicKey}
        >
          <img src={ConnectWalletUrl} alt="connect-wallet" />
          Connect Wallet
        </GradientButton>
      )}
      <ConnectWalletModal
        open={isOpenConnectWalletModal}
        onCancel={() => setIsOpenConnectWalletModal(false)}
        closeModal={() => setIsOpenConnectWalletModal(false)}
      />
    </Layout.Header>
  );
};

export default React.memo(HeaderTop);
