import { BaseService } from "./base-service";

export class NFTService extends BaseService {
  static path = "nfts";

  static createNFT = (body: CreateNFTBody) => this.post<CreateNFTResponse>(`/${this.path}/initialize`, body);

  static updateNFTLog = (address: string, body: UpdateNFTLogBody) =>
    this.post<UpdateNFTLogResponse>(`/${this.path}/${address.toLowerCase()}/log`, body);

  static getNFTs = () => this.get<NFTItem[]>(`/${this.path}`);

  static getNFTDetail = (address: string) => this.get<NFTType>(`/${this.path}/${address.toLowerCase()}`);

  static getNFTVerification = (address: string) =>
    this.get<NFTVerification>(`/${this.path}/${address.toLowerCase()}/manual-verify`);

  static getNFTBuildInfo = (address: string) =>
    this.get<NFTVerification>(`/${this.path}/${address.toLowerCase()}/build-info`);
}
