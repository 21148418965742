import { useCallback, useEffect, useState } from "react";
import { ethers } from "ethers";
import { useSelector } from "src/store";

export const useTransaction = (hash?: string) => {
  const selectedNetwork = useSelector(({ system }: RootState) => system.selectedNetwork);
  const [data, setData] = useState<TransactionReceipt | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState(false);

  const waitForTransaction = useCallback(
    async (hash: string) => {
      if (!selectedNetwork) return;
      const provider = new ethers.providers.JsonRpcProvider(selectedNetwork.rpc_urls[0]);
      return await provider.getTransactionReceipt(hash);
    },
    [selectedNetwork]
  );

  useEffect(() => {
    const getResult = async () => {
      if (!hash) return;
      setLoading(true);
      try {
        const result = await waitForTransaction(hash);
        if (result) setData(result);
      } catch (error) {
        console.log(error);
        setError(error as Error);
      }
      setLoading(false);
    };

    getResult();
  }, [setLoading, setData, setError, hash, waitForTransaction]);

  return { data, error, loading, waitForTransaction };
};
