import { useWeb3React } from "@web3-react/core";
import { useEffect, useState } from "react";
import { useSelector } from "src/store";
import { ethers } from "ethers";
import { useErc720Contract } from "../contracts/useErc720Contract";

export const useGetBalances = (address: string) => {
  const isAuth = useSelector((state: RootState) => state.auth.isAuthChecking);
  const selectedNetwork = useSelector((state: RootState) => state.system.selectedNetwork);
  const { account } = useWeb3React();
  const [nativeBalance, setNativeBalance] = useState<string | null>(null);
  const [tokenBalance, setTokenBalance] = useState<string | null>(null);

  const { balanceOf } = useErc720Contract(address);

  useEffect(() => {
    if (account && isAuth && selectedNetwork) {
      setNativeBalance(null);

      const getNativeToken = async () => {
        const provider = new ethers.providers.JsonRpcProvider(selectedNetwork.rpc_urls[0]);
        const nativeBalance = await provider.getBalance(account);
        setNativeBalance(nativeBalance.toString());
      };
      getNativeToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, isAuth, selectedNetwork]);

  useEffect(() => {
    if (account && isAuth && selectedNetwork && balanceOf) {
      setTokenBalance(null);

      const getTokenBalance = async () => {
        const nativeBalance = await balanceOf(account);
        setTokenBalance(nativeBalance.toString());
      };

      getTokenBalance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, isAuth, selectedNetwork, balanceOf]);

  return { nativeBalance, tokenBalance };
};
