import { TabsProps } from "antd";

import CustomTabs from "src/components/13.tabs";
import SendTab from "./tabs/send";
import SendingTab from "./tabs/sending";
import SendFaildTab from "./tabs/send-failed";
import { useEffect, useState } from "react";

import "./styles.scss";

type Props = {
  address: string;
  transactions: TransactionResponse[];
};

const DistributeResult = ({ transactions }: Props) => {
  const [sendings, setSendings] = useState<TransactionResponse[]>([]);
  const [sends, setSends] = useState<TransactionResponse[]>([]);
  const [faileds, setFaileds] = useState<TransactionResponse[]>([]);

  useEffect(() => {
    setSendings(transactions);
    transactions.map(async item => {
      try {
        const result = await item.wait(1);
        if (result.status !== 1) throw new Error();
        setSends(sends => [item, ...sends]);
      } catch (error) {
        console.log(error);
        setFaileds(faileds => [item, ...faileds]);
      }
      setSendings(sendings => sendings.filter(sending => sending.hash !== item.hash));
    });
  }, [transactions]);

  const tabs: TabsProps["items"] = [
    { key: "sending", label: `In Sending Queue (${sendings.length})`, children: <SendingTab data={sendings} /> },
    { key: "send", label: `Send (${sends.length})`, children: <SendTab data={sends} /> },
    { key: "failed", label: `Faied to send (${faileds.length})`, children: <SendFaildTab data={faileds} /> },
  ];

  return (
    <div className="token-distribute">
      <CustomTabs items={tabs} defaultActiveKey="sending" />
    </div>
  );
};

export default DistributeResult;
