import "./styles.scss";

import { Button } from "antd";
import MethodSubmitButton from "src/components/03.buttons/MethodSubmitButton";
import ModalWithLogo from "src/components/04.modals/ModalWithLogo";
import { formatNumber } from "src/commons/utils/functions/formatNumber";
import { useState } from "react";
import { useVestingContract } from "src/web3/contracts/useVestingContract";
import { customToast } from "../02.toasts";
import { useTransaction } from "src/web3/hooks/useTransaction";

type Props = {
  data: ClaimItem | null;
  amount: string;
  open: boolean;
  onClose: () => void;
  onSuccess: () => Promise<void>;
};

const ClaimTokenForm = ({ open, data, onClose, amount, onSuccess }: Props) => {
  const { vesting_address, vesting } = data || {};
  const { erc20 } = vesting || {};

  const [loading, setLoading] = useState(false);
  const { release } = useVestingContract(vesting_address);
  const { waitForTransaction } = useTransaction();

  const handleSubmit = async () => {
    if (!release || !amount || !data) return;
    setLoading(true);
    try {
      const transaction = await release(data.schedule_id, amount);

      if (!transaction) throw new Error("Cannot Claim Vesting");
      await transaction.wait(1);
      await onSuccess();
      await waitForTransaction(transaction.hash);

      customToast.success("Claim Vesting Successfuly");
      onClose();
    } catch (error: any) {
      console.log({ error });
      if (error.code === "ACTION_REJECTED") customToast.error("Please accept transaction to complete vesting claiming");
      else customToast.error("Transaction Error");
    }

    setLoading(false);
  };

  return (
    <ModalWithLogo open={open} footer={false} closable={!loading} onCancel={onClose}>
      <div className="add-vesting-token-title">Claim Tokens</div>
      <div className="token-detail-info">
        <div className="token-info-label">Token Name</div>
        <div className="token-info-value">{erc20?.name}</div>
      </div>
      <div className="token-detail-info">
        <div className="token-info-label">Claim amount</div>
        <div className="token-info-value">{formatNumber(amount, erc20?.decimals)}</div>
      </div>
      <div className="token-detail-info">
        <div className="token-info-label">Address</div>
        <div className="token-info-value">{vesting_address}</div>
      </div>
      <div className="form-submit">
        <Button className="cancel-button" onClick={onClose} disabled={loading}>
          Back
        </Button>
        <MethodSubmitButton type="primary" onClick={handleSubmit} loading={loading}>
          Claim
        </MethodSubmitButton>
      </div>
    </ModalWithLogo>
  );
};

export default ClaimTokenForm;
