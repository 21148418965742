import { Col, Row } from "antd";
import CreateTokenForm from "src/components/05.forms/CreateTokenForm";
import ResizeIcon from "src/components/08.resize-icon";
import { IdeaIcon } from "src/commons/resources/icons";

import "./styles.scss";

const ideas = [
  "Effortless, speedy, and user-friendly token creator",
  "Effortless Smart Contracts",
  "Gain complete ownership of the tokens you create",
  "Gain complete ownership of the tokens you create",
  "Gain complete ownership of the tokens you create",
];

const CreateTokenPage = () => {
  return (
    <div>
      <div className="form-title">CREATE YOUR ERC-20 TOKEN</div>
      <Row>
        <Col span={24} order={2} lg={{ span: 16, order: 1 }}>
          <div className="token-page-form">
            <CreateTokenForm />
          </div>
        </Col>
        <Col span={24} order={1} lg={{ span: 8, order: 2 }}>
          <ul className="token-ideas">
            {ideas.map((idea, index) => (
              <li key={index}>
                <ResizeIcon icon={IdeaIcon} width={15} />
                {idea}
              </li>
            ))}
          </ul>
        </Col>
      </Row>
    </div>
  );
};

export default CreateTokenPage;
