import { useWeb3React } from "@web3-react/core";
import { useEffect, useRef } from "react";
import { useConnectWallet } from "./useConnectWallet";
import { useSelector } from "react-redux";
import { convertDecimalToHex } from "src/commons/utils/converters/convertDecimalToHex";

/**
 * Use for network and injected - logs user in
 * and out after checking what network they're on
 */

export function useWalletListener() {
  const { connector, account, chainId } = useWeb3React();
  const { disconnectWallet, requestChangeNetwork } = useConnectWallet();

  const accountRef = useRef<string>();

  const isLoading = useSelector((state: RootState) => state.auth.isLoading);
  const networks = useSelector(({ system }: RootState) => system.networks);
  // const history = useHistory();

  const handleLogout = (accounts: string[], removeCache = true) => {
    if (accounts[0] !== accountRef.current) {
      disconnectWallet(removeCache);
    }
    accountRef.current = accounts[0];
  };

  const handleChainChange = (chainHex: string) => {
    const selectedNetwork = networks.find(item => chainHex === convertDecimalToHex(Number(item.network_id)));
    if (!isLoading) {
      if (!selectedNetwork) disconnectWallet(true);
      else {
        requestChangeNetwork(Number(selectedNetwork.network_id));
      }
    }
  };

  useEffect(() => {
    if (chainId && !networks.find(item => item.network_id === chainId.toString()) && !isLoading) {
      disconnectWallet(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId, isLoading, networks]);

  useEffect(() => {
    if (account) {
      accountRef.current = account;
    }
  }, [account]);

  useEffect(() => {
    if (connector && connector.provider) {
      connector.provider?.on("chainChanged", handleChainChange);
      connector.provider?.on("accountsChanged", handleLogout);
    } else {
      connector.provider?.removeListener("chainChanged", handleChainChange);
      connector.provider?.removeListener("accountsChanged", handleLogout);
    }

    if (window) {
      (window as any).logout = handleLogout;
    }

    return () => {
      connector.provider?.removeListener("chainChanged", handleChainChange);
      connector.provider?.removeListener("accountsChanged", handleLogout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connector.provider, account]);
}
