import BigNumber from "bignumber.js";
import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

import Layout from "./components/01.layout";
import ToastContext from "./contexts/toast";
import { persistor, store } from "./store";
import Routers from "./Routers";

import "react-toastify/dist/ReactToastify.css";
import "src/commons/resources/scss/_themes.scss";
import "src/commons/resources/scss/variable.scss";
import "./App.scss";
import AppContainer from "./AppContainer";

dayjs.extend(relativeTime);

BigNumber.config({
  ROUNDING_MODE: BigNumber.ROUND_DOWN,
  EXPONENTIAL_AT: 999,
});

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter basename="/">
          <AppContainer>
            <Layout>
              <ToastContext />
              <Routers />
            </Layout>
          </AppContainer>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
};

export default App;
