import { useState } from "react";
import { Col, Row } from "antd";
import ResizeIcon from "src/components/08.resize-icon";
import { IdeaIcon } from "src/commons/resources/icons";
import DistributeTokenForm from "src/components/05.forms/DistributeTokenForm";
import DistributeResult from "src/components/token-distribute/distribute-result";

import "./styles.scss";

const ideas = [
  "A multifunctional token sender dApp designed for all your digital assets",
  "Efficiently dispatch your tokens to multiple recipients with ease.",
  "Ideal for transmitting stable coins or cryptocurrencies for dividend or coupon disbursements.",
  "Manually input lists of addresses and their respective amounts.",
];

const defaultIdeas = ["Streamline distribution through automatic CSV uploads, akin to acquiring a holder snapshot."];
const selectedIdeas = ["Streamline distribution through automatic CSV uploads, Download a template here"];

const DistributeTokenPage = () => {
  const [address, setAddress] = useState("");
  const [transactions, setTransactions] = useState<TransactionResponse[]>([]);

  return (
    <div>
      <div className="form-title">Distribute Token</div>
      <Row>
        <Col span={24} order={2} lg={{ span: 16, order: 1 }}>
          {transactions.length ? (
            <DistributeResult address={address} transactions={transactions} />
          ) : (
            <DistributeTokenForm onSelect={setAddress} onSubmit={setTransactions} />
          )}
        </Col>
        <Col span={24} order={1} lg={{ span: 8, order: 2 }}>
          <ul className="token-ideas">
            {ideas.map((idea, index) => (
              <li key={index}>
                <ResizeIcon icon={IdeaIcon} width={15} />
                {idea}
              </li>
            ))}
            {(address ? selectedIdeas : defaultIdeas).map((idea, index) => (
              <li key={index}>
                <ResizeIcon icon={IdeaIcon} width={15} />
                {idea}
              </li>
            ))}
          </ul>
        </Col>
      </Row>
    </div>
  );
};

export default DistributeTokenPage;
