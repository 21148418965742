import { PATHS, routers, titles } from "./routers";
import {
  NFTCreateIcon,
  TokenCreateIcon,
  TokenDistributeIcon,
  NFTManageIcon,
  TokenManageIcon,
  PricingIcon,
  ViewProfileIcon,
  VestingIcon,
} from "src/commons/resources/icons";

export const menus = [
  {
    path: PATHS.createToken,
    icon: TokenCreateIcon,
    label: titles[routers.TOKEN_CREATE],
  },
  {
    path: PATHS.managerToken(),
    icon: TokenManageIcon,
    label: titles[routers.TOKEN_MANAGER],
  },
  {
    path: PATHS.distributeToken,
    icon: TokenDistributeIcon,
    label: titles[routers.TOKEN_DISTRIBUTE],
  },
  {
    path: PATHS.createNFT,
    icon: NFTCreateIcon,
    label: titles[routers.NFT_CREATE],
  },
  {
    path: PATHS.managerNFT(),
    icon: NFTManageIcon,
    label: titles[routers.NFT_MANAGER],
  },
  {
    path: PATHS.profile(),
    icon: ViewProfileIcon,
    label: titles[routers.VIEW_PROFILE],
  },
  {
    path: PATHS.vestingToken,
    icon: VestingIcon,
    label: titles[routers.TOKEN_VESTING],
  },
  {
    path: PATHS.claimToken(),
    icon: VestingIcon,
    label: titles[routers.TOKEN_CLAIM],
  },
  {
    path: PATHS.pricing,
    icon: PricingIcon,
    label: titles[routers.PRICING],
  },
];
