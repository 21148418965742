import { BaseService } from "./base-service";

export class TokenService extends BaseService {
  static path = "erc20-tokens";
  static TOKEN_LIST = `/${this.path}`;

  static createERC20Token = (body: CreateTokenBody) => this.post<CreateTokenResponse>(`/${this.path}/initialize`, body);

  static updateERC20TokenLog = (address: string, body: UpdateTokenLogBody) =>
    this.post<UpdateTokenLogResponse>(`/${this.path}/${address.toLowerCase()}/log`, body);

  static getERC20Tokens = () => this.get<TokenItem[]>(`/${this.path}`);

  static getERC20TokenDetail = (address: string) => this.get<TokenType>(`/${this.path}/${address.toLowerCase()}`);

  static updateERC20TokenOwner = (address: string, body: ChangeOwnerBody) =>
    this.post<UpdateTokenLogResponse>(`/${this.path}/${address.toLowerCase()}/change-owner`, body);

  static getERC20TokenVerification = (address: string) =>
    this.get<TokenVerification>(`/${this.path}/${address.toLowerCase()}/manual-verify`);

  static getERC20TokenBuildInfo = (address: string) =>
    this.get<TokenVerification>(`/${this.path}/${address.toLowerCase()}/build-info`);
}
