import { TabsProps } from "antd";
import CustomTabs from "../13.tabs";
import NFTMint from "./methods/mint";
import NFTList from "./methods/nft-list";
import { useSelector } from "react-redux";

import "./styles.scss";

type Props = {
  nft: NFTType;
  address: string;
};

const NFTMinting = ({ address, nft }: Props) => {
  const {
    name,
    symbol,
    provider: { network_id },
  } = nft;

  const networks = useSelector(({ system }: RootState) => system.networks);
  const nftNetwork = networks.find(item => item.network_id === network_id);

  const tabs: TabsProps["items"] = [
    { key: "mint", label: "Mint", children: <NFTMint nft={nft} address={address} /> },
    { key: "list", label: "NFT List", children: <NFTList /> },
  ];

  return (
    <div className="nft-minting">
      <div className="nft-minting-header">
        <div className="nft-minting-info">
          <div className="nft-info-label">
            <div className="nft-summary">
              {name} ({symbol})
            </div>
            <div className="nft-address">{address}</div>
          </div>
          <div className="nft-info-value">
            {!!nftNetwork && (
              <div className="nft-network">
                <img src={nftNetwork.icon_urls[0]} alt={nftNetwork.name} />
                <span>{nftNetwork.name}</span>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="nft-preview">{nft.media_url && <img src={nft.media_url} alt="media url" />}</div>
      <CustomTabs defaultActiveKey="mint" items={tabs} />
    </div>
  );
};

export default NFTMinting;
