import React from "react";
import { DatePicker } from "antd";
import { RangePickerRef } from "antd/lib/date-picker/generatePicker/interface";   

import "./styles.scss";
import { RangePickerProps } from "antd/lib/date-picker";

const CustomRangePicker = React.forwardRef(({ className, ...props }: RangePickerProps, ref: RangePickerRef<Dayjs>) => {
  return <DatePicker.RangePicker ref={ref} className={`custom-input ${className ?? ""}`} {...props} />;
});

export default CustomRangePicker;
