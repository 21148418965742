import { Button, Form } from "antd";
import { FormProps } from "antd/lib";
import { useEffect, useState } from "react";
import { REQUIRED } from "src/commons/messages/validate";
import MethodSubmitButton from "src/components/03.buttons/MethodSubmitButton";
import NumberInput from "src/components/07.inputs/NumberInput";
import ModalWithLogo from "src/components/04.modals/ModalWithLogo";
import { formatNumber } from "src/commons/utils/functions/formatNumber";
import { useErc720Contract } from "src/web3/contracts/useErc720Contract";
import { multipleDecimals } from "src/commons/utils/functions/multipleDecimals";
import { divDecimals } from "src/commons/utils/functions/divDecimals";
import { customToast } from "src/components/02.toasts";
import { isGreateThan } from "src/commons/utils/functions/validates";

import "./styles.scss";

type Props = {
  token: TokenType;
  address: string;
  open: boolean;
  onClose: () => void;
  totalSupply: string;
  maxToken: string;
  getMaxToken: () => Promise<void>;
};

const FormItem = Form.Item<ChangeLimitTokensValues>;

const ChangeLimitTokenModal = ({ token, address, open, totalSupply, maxToken, onClose, getMaxToken }: Props) => {
  const [form] = Form.useForm<ChangeLimitTokensValues>();
  const [loading, setLoading] = useState(false);
  const { setMaxTokenAmountPerAddress } = useErc720Contract(address);

  const { name, decimals } = token;

  const onFinish = async (values: ChangeLimitTokensValues) => {
    if (!setMaxTokenAmountPerAddress) return;
    setLoading(true);
    try {
      const { amount } = values;
      const transaction = await setMaxTokenAmountPerAddress(multipleDecimals(amount, decimals));
      await transaction?.wait(1);
      await getMaxToken();
      onClose();
      customToast.success("Change Token Per Address Limit Successfully");
    } catch (error: any) {
      console.log({ error });
      form.setFieldsValue({ amount: Number(maxTokenAmount) });
      const errors: StringObject = error.response?.data?.errors || { name: "Change Token Per Address Limit Failed" };
      form.setFields(Object.entries(errors).map(([key, value]) => ({ name: key, errors: [value] })));
      form.getFieldInstance(Object.keys(errors)[0] || "name")?.focus();
      const errMessage = Object.values(errors)[0];
      customToast.error(typeof errMessage === "string" ? errMessage : "Change Token Per Address Limit Failed");
    }
    setLoading(false);
  };

  const onFinishFailed: FormProps["onFinishFailed"] = errorInfo => {
    const namePath = errorInfo.errorFields[0].name?.[0];
    form.getFieldInstance(namePath)?.focus();
  };

  const maxTokenAmount = divDecimals(maxToken, decimals);

  useEffect(() => {
    if (!open) form.setFieldsValue({ amount: Number(maxTokenAmount) });
  }, [maxTokenAmount, form, open]);

  return (
    <ModalWithLogo open={open} footer={false}>
      <Form
        form={form}
        className="form token-method-form"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        layout="horizontal"
        onFinish={onFinish}
        validateMessages={{ required: REQUIRED() }}
        onFinishFailed={onFinishFailed}
      >
        <div className="token-method-title">Change Token Per Address Limit</div>
        <div className="token-detail-info">
          <div className="token-info-label">Token Name</div>
          <div className="token-info-value">{name}</div>
        </div>
        <div className="token-detail-info">
          <div className="token-info-label">Total Supply</div>
          <div className="token-info-value">{formatNumber(totalSupply, decimals)}</div>
        </div>
        <FormItem
          colon
          label="Number Of Tokens"
          name="amount"
          rules={[
            { required: true, message: "Maximum tokens per address is required" },
            { validator: isGreateThan(maxTokenAmount, "The maximum tokens per address") },
          ]}
        >
          <NumberInput
            name="amount"
            rules={[
              { required: true, message: "Maximum tokens per address is required" },
              { validator: isGreateThan(maxTokenAmount, "The maximum tokens per address") },
            ]}
            decimalsLimit={decimals}
            placeholder="e.g: 10 000 000"
          />
        </FormItem>
        <div className="description-input">This limit sets maximum token balance per individual address.</div>
        <div className="form-submit">
          <Button className="cancel-button" onClick={onClose}>
            Back
          </Button>
          <MethodSubmitButton htmlType="submit" type="primary" loading={loading}>
            Submit
          </MethodSubmitButton>
        </div>
      </Form>
    </ModalWithLogo>
  );
};

export default ChangeLimitTokenModal;
