import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { formatNumber } from "src/commons/utils/functions/formatNumber";
import CopyButton from "../11.copy-button";

import "./styles.scss";

const DistributeTokenRecientTable = ({ data, token }: { data: DistributeTokenItem[]; token: TokenItem }) => {
  const columns: ColumnsType<DistributeTokenItem> = [
    {
      title: "Wallet address",
      dataIndex: "address",
      key: "address",
      width: "60%",
      render: (address: string) => (
        <div className="wallet-address">
          {address} <CopyButton text={address} />
        </div>
      ),
    },
    {
      title: <div className="head-title">Amount ({token.name})</div>,
      dataIndex: "amount",
      key: "amount",
      width: "60%",
      render: (amount: number) => <div className="amount">{formatNumber(amount)}</div>,
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={data}
      pagination={false}
      className="distribute-token-recient-table"
      rowKey="address"
    />
  );
};

export default DistributeTokenRecientTable;
