import { useCallback, useEffect, useRef, useState } from "react";

export const useDebounce = <T>(fn: (...params: T[]) => void, time = 500) => {
  const [params, setParams] = useState<T[]>([]);
  const initialized = useRef(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (initialized.current) fn(...params);
    }, time);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, time]);

  const debonceFn = useCallback(
    (...params: T[]) => {
      initialized.current = true;
      setParams(params);
    },
    [setParams]
  );

  return debonceFn;
};
