import VestingABI from "src/web3/abis/VestringFactory.json";
import { useContract } from "./useContract";
import { BaseContract } from "ethers";

interface VestingContract {
  batchCreateVestingSchedule?: (data: VestingData[]) => Promise<TransactionResponse>;
  release?: (vestingScheduleId: string, amount: string) => Promise<TransactionResponse>;
  getVestingSchedule?: (vestingScheduleId: string) => Promise<VestingSchedule>;
  computeNextVestingScheduleIdForHolder?: (address: string) => Promise<string>;
  getVestingSchedulesCountByBeneficiary?: (address: string) => Promise<BigInt>;
  computeVestingScheduleIdForAddressAndIndex?: (address: string, index: number) => Promise<string>;
  computeReleasableAmount?: (vestingScheduleId: string) => Promise<BigInt>;
}

export const useVestingContract = (address?: string): VestingContract => {
  const vestingContract = useContract<BaseContract & VestingContract>(VestingABI, address || "");

  return vestingContract || {};
};
