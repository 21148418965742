import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from "@reduxjs/toolkit/query/react";
import { Mutex } from "async-mutex";
import { RootState } from ".";
import { API_ENDPOINT } from "src/commons/constants";

const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
  baseUrl: API_ENDPOINT,
  prepareHeaders: (headers, { getState, endpoint }) => {
    const token = (getState() as RootState).user.accessToken;
    if (!!token && endpoint !== "refresh") {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock();

  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    // checking whether the mutex is locked
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();

      try {
        (window as any)?.logout && (window as any)?.logout([], false);
      } finally {
        // release must be called once the mutex should be released again.
        release();
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock();
      // result = await baseQuery(args, api, extraOptions);
    }
  }

  return result;
};

export const baseQueryApi = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    "offer",
    "listed-nft-list",
    "unlisted-nft-list",
    "loan-info",
    "loan-info-owner",
    "loan-info-previous-loan",
    "loan-info-charts",
    "offer-renegotiate",
    "user-info",
    "bundle-list",
    "bundle-detail",
    "collection-offer-list",
    "collection-info",
    " -list",
    "list-vesting",
  ],
  endpoints: () => ({}),
  keepUnusedDataFor: 0,
});
