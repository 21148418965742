import { multipleDecimals } from "../functions/multipleDecimals";
import { decodeCSV } from "../functions/decodeCSV";
import dayjs from "dayjs";

export const convertToTokenBody = (
  values: CreateTokenValues,
  address: string,
  selectedNetwork: NetworkData
): CreateTokenBody => {
  const {
    hasDifferentOwner,
    initial_supply,
    decimals,
    transaction_fee,
    burn_transaction_fee,
    tokens_per_address,
    ...rest
  } = values;

  return {
    ...rest,
    initial_supply: initial_supply.toString(),
    decimals: Number(decimals),
    transaction_fee: Number(transaction_fee) * 100,
    burn_transaction_fee: rest.is_apply_burn_fee ? Number(burn_transaction_fee) * 100 : 0,
    tokens_per_address: values.is_limited_tokens_per_address ? multipleDecimals(tokens_per_address, decimals) : "0",
    owner_address: hasDifferentOwner ? rest.owner_address : address,
    document_url: rest.is_have_documentation ? rest.document_url : null,
    provider: {
      network_id: selectedNetwork.network_id,
      name: selectedNetwork.name,
    },
  };
};

export const convertCSVToTokenDistribute = (value: string): DistributeTokenItem[] => {
  const rows = decodeCSV(value);
  return rows.map(([address, amount]) => ({
    address,
    amount,
  }));
};

export const convertCSVToTokenDistributeParams = (
  value: string,
  decimals: number
): { addresses: string[]; amounts: string[] }[] => {
  const rows = decodeCSV(value);
  const params: { addresses: string[]; amounts: string[] }[] = [];
  for (let i = 0; i < rows.length; i += 200) {
    const slices = rows.slice(i, i + 200);
    params.push({
      addresses: slices.map(item => item[0]),
      amounts: slices.map(item => multipleDecimals(item[1], decimals)),
    });
  }
  return params;
};

export const convertCSVToTokenVesting = (value: string): VestingTokenItemValues[] => {
  const rows = decodeCSV(value).slice(1);
  return rows.map(
    ([
      address,
      name,
      isEmployee,
      amount,
      vestingPeriodSart,
      vestingPeriodEnd,
      candence,
      lock,
      lockTime,
      lockPercent,
    ]) => ({
      address,
      name,
      isEmployee: +(isEmployee === "Employee"),
      amount: Number(amount),
      vestingPeriod: [dayjs(vestingPeriodSart), dayjs(vestingPeriodEnd)],
      candence: Number(candence),
      lock: lock === "true",
      lockTime: dayjs(lockTime),
      lockPercent: Number(lockPercent),
    })
  );
};
