import { useSelector } from "react-redux";
import { convertExplorerUrls } from "src/commons/utils/converters/converterExplorerUrls";

type Props = {
  data: { hash: string }[];
};

export default function SendingTab({ data }: Props) {
  const selectedNetwork = useSelector(({ system }: RootState) => system.selectedNetwork);
  const href = convertExplorerUrls(selectedNetwork).transaction;

  return (
    <div className="token-distribute-list">
      {data?.map((item, index) => (
        <div className="token-distribute-item" key={index}>
          <div className="token-distribute-label">Transaction hash</div>
          <div className="token-distribute-hashs">
            <a href={href + item.hash} target="_blank" rel="noreferrer">
              {item.hash}
            </a>
          </div>
        </div>
      ))}
    </div>
  );
}
