import { useEffect, useState } from "react";
import { useErc720Contract } from "../contracts/useErc720Contract";
import { isAddress } from "ethers/lib/utils";

export const useDecimals = (address: string) => {
  const [decimals, setDecimals] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const { decimals: getDecimals } = useErc720Contract(address);

  useEffect(() => {
    if (address) {
      if (!getDecimals) return setError("Not Found");
      if (!isAddress(address)) setError("Invalid Address");
      const getData = async () => {
        setLoading(true);
        try {
          const decimals = await getDecimals();
          setDecimals(Number(decimals.toString()));
          setError(null);
        } catch (error) {
          console.log(error);
          setError((error as any)?.message || "Cannot get decimals");
        }
        setLoading(false);
      };
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDecimals, address]);

  return { decimals, error, loading };
};
