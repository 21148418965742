import { AxiosError } from "axios";
import jwt from "jwt-decode";
import { customToast } from "src/components/02.toasts";
import { setIsAuth, setIsLoading } from "src/store/slices/auth";
import { clearCurrentAccount, clearWallet, setCurrentAccount } from "src/store/slices/user";
import { ConnectorKey } from "src/web3/connectors";
import { useConnectWallet } from "src/web3/hooks";
import { PROVIDER, REFRESH_TOKEN_KEY, TOKEN_KEY } from "../constants";
import { useDispatch, useSelector } from "react-redux";
import { AuthService } from "src/services/auth-service";
import { handleRefreshToken } from "src/services/axios";
import { useCallback } from "react";
import { CHAIN_ID_KEY, ConnectError } from "src/web3/constants";

export const useAuth = () => {
  const connectors = useSelector(({ system }: RootState) => system.connectors);
  const chainId = useSelector(({ system }: RootState) => system.chainId);
  const { connectWalletAndSignMessage } = useConnectWallet();
  const dispatch = useDispatch();

  const loginLocal = useCallback(async () => {
    const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
    if (refreshToken) {
      const decode = jwt<UserDecode>(refreshToken);
      if (decode.exp >= Date.now() / 1000) {
        const token = await handleRefreshToken();
        if (token) {
          dispatch(setIsAuth(true));
          return token;
        }
      }
    }
  }, [dispatch]);

  const logout = () => {
    connectors[ConnectorKey.walletConnect]?.resetState();
    connectors[ConnectorKey.walletConnect]?.deactivate?.();
    localStorage.clear();
    localStorage.setItem(CHAIN_ID_KEY, chainId.toString());
    dispatch(clearWallet());
    dispatch(clearCurrentAccount());
  };

  const login = async (connectorKey: ConnectorKey) => {
    try {
      dispatch(setIsLoading(true));
      const { signature, account } = await connectWalletAndSignMessage(connectorKey);
      const body = {
        address: account,
        signature,
        provider: PROVIDER.METAMASK,
      };
      const { data } = await AuthService.postLoginSign(body);
      const accessToken = data?.access_token;
      const refreshToken = data?.access_token;
      if (accessToken) {
        localStorage.setItem(TOKEN_KEY, accessToken);
        localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
        dispatch(setCurrentAccount(account as string));
        dispatch(setIsAuth(true));
      } else {
        throw new Error();
      }
    } catch (error: any) {
      console.log(error);
      if (connectorKey === ConnectorKey.walletConnect) {
        logout();
      }
      if (error?.code === AxiosError.ERR_NETWORK) {
        customToast.error("Network error");
        return;
      }
      if (error.code === -32002) {
        const lockError: WEB3_ERROR = {
          type: ConnectError.metamask_lock,
          message: error?.message,
          description: error,
        };
        throw lockError;
      }
      const baseError: WEB3_ERROR = {
        type: ConnectError.user_reject,
        message: error?.message,
        description: error,
      };
      throw baseError;
    }
    dispatch(setIsLoading(false));
  };

  return { login, logout, loginLocal };
};
