import { toast } from "react-toastify";

enum TOAST_TYPE {
  SUCCESS = "success",
  ERROR = "error",
  WARNING = "warning",
  INFO = "info",
}

export const customToast = (() => {
  const toastRender = (type: TOAST_TYPE) => {
    return (headContent?: React.ReactNode) => toast[type](headContent);
  };

  return {
    ...toast,
    [TOAST_TYPE.INFO]: toastRender(TOAST_TYPE.INFO),
    [TOAST_TYPE.ERROR]: toastRender(TOAST_TYPE.ERROR),
    [TOAST_TYPE.WARNING]: toastRender(TOAST_TYPE.WARNING),
    [TOAST_TYPE.SUCCESS]: toastRender(TOAST_TYPE.SUCCESS),
  };
})();
