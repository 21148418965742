import { FC, useEffect, useState } from "react";
import { Col, Row } from "antd";
import ResizeIcon from "src/components/08.resize-icon";
import {
  ContactIcon,
  DiscordIcon,
  Feature1Icon,
  Feature2Icon,
  Feature3Icon,
  LinkedInIcon,
  TelegramIcon,
  TitterIcon,
  YoutubeIcon,
} from "src/commons/resources/icons";
import { Link } from "react-router-dom";
import { PATHS } from "src/commons/constants/routers";
import { SystemService } from "src/services/system-service";

import "./styles.scss";
import { SOCIAL_KEY } from "src/commons/constants";

const SosicalIcon: { [key in SOCIAL_KEY]: React.FC } = {
  [SOCIAL_KEY.TOKEN_FACTORY_PARAMETER_CONFIG_TELEGRAM]: TelegramIcon,
  [SOCIAL_KEY.TOKEN_FACTORY_PARAMETER_CONFIG_TWITTER]: TitterIcon,
  [SOCIAL_KEY.TOKEN_FACTORY_PARAMETER_CONFIG_LINKEDIN]: LinkedInIcon,
  [SOCIAL_KEY.TOKEN_FACTORY_PARAMETER_CONFIG_YOUTUBE]: YoutubeIcon,
  [SOCIAL_KEY.TOKEN_FACTORY_PARAMETER_CONFIG_DISCORD]: DiscordIcon,
  [SOCIAL_KEY.TOKEN_FACTORY_PARAMETER_CONFIG_CONTACT_US]: ContactIcon,
};

const LandingPage: FC = () => {
  const [data, setData] = useState<MetaData[]>([]);
  useEffect(() => {
    const getMetaDatas = async () => {
      try {
        const { data } = await SystemService.getMetaDatas();
        if (!data) throw new Error();
        setData(data);
      } catch (error) {
        console.log("Cannot get meta data", error);
      }
    };
    getMetaDatas();
  }, []);

  return (
    <div className="landing-page">
      <div className="banner-container">
        <div className="banner">
          <div className="banner-title">
            Effortless <span className="highlight-text">Tokenization</span>
          </div>
          <div className="banner-description">
            Create, manage and vest tokens, effortlessly – Token Factory by Morpheus Labs is the leading tokenization
            app
          </div>
        </div>
      </div>
      <div className="landing-section">
        <div className="features">
          <Row gutter={96}>
            <Col span={24} sm={8}>
              <Link to={PATHS.createToken} className="feature-item">
                <ResizeIcon icon={Feature1Icon} height={50} />
                <div className="feature-name">Generate Token Contract</div>
              </Link>
            </Col>
            <Col span={24} sm={8}>
              <Link to={PATHS.vestingToken} className="feature-item">
                <ResizeIcon icon={Feature2Icon} height={50} />
                <div className="feature-name">Generate Vesting Contract</div>
              </Link>
            </Col>
            <Col span={24} sm={8}>
              <Link to={PATHS.createNFT} className="feature-item">
                <ResizeIcon icon={Feature3Icon} height={50} />
                <div className="feature-name">
                  Mint your
                  <br />
                  NFT
                </div>
              </Link>
            </Col>
          </Row>
        </div>
      </div>
      <div className="landing-section">
        <div className="subcribe-container">
          <div className="subcribe">
            <div className="subcribe-title">Subscribe to our newsletter</div>
            <div className="subcribe-button">Subscribe Now</div>
          </div>
        </div>
      </div>
      <div className="landing-section">
        <div className="social-container">
          <Row gutter={[40, 34]}>
            {data.map(({ description, key, value: { url } }) => {
              const Icon = SosicalIcon[key];
              if (!Icon) return null;
              return (
                <Col key={key} span={24} sm={12}>
                  <a className="social-item" href={url} target="_blank" rel="noreferrer">
                    <div className="social-icon">{Icon && <Icon />}</div>
                    <span>{description}</span>
                  </a>
                </Col>
              );
            })}
          </Row>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
