export const routers = {
  DASHBOARD: "/",
  TOKEN_CREATE: "/token/create",
  TOKEN_MANAGER: "/token/manage/:address?",
  TOKEN_DISTRIBUTE: "/token/distribute",
  TOKEN_VESTING: "/token/vesting",
  TOKEN_CLAIM: "/token/claim/:address?",
  NFT_CREATE: "/nft/create",
  NFT_MANAGER: "/nft/manage/:address?",
  NFT_MINTING: "/nft/minting/:address?",
  VIEW_PROFILE: "/profile/:address?",
  PRICING: "/pricing",
  NOT_FOUND: "/*",
};

export const titles = {
  [routers.DASHBOARD]: "Token Factory",
  [routers.TOKEN_CREATE]: "Create Token",
  [routers.TOKEN_MANAGER]: "Manage Token",
  [routers.TOKEN_DISTRIBUTE]: "Distribute Token",
  [routers.TOKEN_VESTING]: "Vesting",
  [routers.TOKEN_CLAIM]: "Claiming",
  [routers.NFT_CREATE]: "Create NFT",
  [routers.NFT_MANAGER]: "Manage NFT",
  [routers.NFT_MINTING]: "Manage NFT",
  [routers.VIEW_PROFILE]: "View Profile",
  [routers.PRICING]: "Pricing",
};

export const PATHS = {
  default: routers.DASHBOARD,
  createToken: routers.TOKEN_CREATE,
  managerToken: (adddress?: string) => routers.TOKEN_MANAGER.replace(":address?", adddress || ""),
  distributeToken: routers.TOKEN_DISTRIBUTE,
  vestingToken: routers.TOKEN_VESTING,
  claimToken: (adddress?: string) => routers.TOKEN_CLAIM.replace(":address?", adddress || ""),
  createNFT: routers.NFT_CREATE,
  managerNFT: (adddress?: string) => routers.NFT_MANAGER.replace(":address?", adddress || ""),
  mintingNFT: (adddress?: string) => routers.NFT_MINTING.replace(":address?", adddress || ""),
  profile: (adddress?: string) => routers.VIEW_PROFILE.replace(":address?", adddress || ""),
  pricing: routers.PRICING,
  tokenClaim: routers.PRICING,
};
