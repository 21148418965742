import "./styles.scss";
import { Button, Form, Radio, Select } from "antd";
import { ChangeEvent, MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import * as XLSX from "xlsx";

import { REQUIRED } from "src/commons/messages/validate";
import { FormProps } from "antd/lib";
import GradientButton from "../03.buttons/GradientButton";
import { useErc720Contract } from "src/web3/contracts/useErc720Contract";
import { formatNumber } from "src/commons/utils/functions/formatNumber";
import { DeleteIcon, DuplicateIcon, EditIcon } from "src/commons/resources/icons";
import AddVestingTokenForm from "./AddVestingTokenForm";
import CustomCollapse from "../09.collaspe";
import { isVestingTemplateToken } from "src/commons/utils/functions/validates";
import CustomInput from "../07.inputs";
import Upload, { UploadRef } from "../07.inputs/Upload";
import { ellipseAddress } from "src/commons/utils/functions/ellipseAddress";
import { customToast } from "../02.toasts";
import { convertCSVToTokenVesting } from "src/commons/utils/converters/tokenConverter";

import { useVestingContract } from "src/web3/contracts/useVestingContract";
import { useTransaction } from "src/web3/hooks/useTransaction";
import { multipleDecimals } from "src/commons/utils/functions/multipleDecimals";

import ApproveButton from "../approve-button";
import useFetchInfinit from "src/commons/hooks/useFetchInfinit";
import { APIS } from "src/commons/constants/apis";
import { useSelector } from "react-redux";
import CustomSelect from "../12.select";
import { useFactoryContract } from "src/web3/contracts/useFactoryContract";
import { VestingService } from "src/services/vesting-service";
import { TokenStatus } from "src/web3/constants";
import BigNumber from "bignumber.js";
import VestedTokenModal from "../04.modals/VestedTokenModal";

const FormItem = Form.Item<VestingTokenValues>;

const EXCELS = ["application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
const ACCEPTEDS = ["text/csv", "text/plain", ...EXCELS];

interface Props {
  token: TokenType;
  address: string;
}

const perPage = 10;

const VestingTokenForm = ({ token, address }: Props) => {
  const selectedNetwork = useSelector(({ system }: RootState) => system.selectedNetwork);
  const isAuth = useSelector(({ user }: RootState) => !!user.currentAccount);
  const [form] = Form.useForm<VestingTokenValues>();
  const [loading, setLoading] = useState(false);
  const [loadingDeploy, setLoadingDeploy] = useState(false);
  const [search, setSearch] = useState("");
  const [totalSupply, setTotalSupply] = useState("0");
  const [isAdd, setIsAdd] = useState(true);
  const [fileLoading, setFileLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [onEdit, setOnEdit] = useState<VestingTokenItemValues | null>(null);
  const [onEditIndex, setOnEditIndex] = useState<number | null>(null);
  const [counter, setCounter] = useState(0);
  const [approved, setApproved] = useState(false);
  const [contract, setContract] = useState("");
  const [values, setValues] = useState<VestingTokenItemValues[]>([]);
  const [vestingData, setVestingData] = useState<VestingData[] | null>(null);
  const fileRef = useRef<UploadRef | null>(null);

  const totalAmount = useMemo(() => {
    return values.reduce((sum, item) => sum.plus(item.amount), new BigNumber(0)).toString();
  }, [values]);

  const network_id = useSelector(({ system }: RootState) => system.chainId);
  const {
    data,
    loading: loadingVesting,
    hasMore,
    next,
  } = useFetchInfinit<TokenItem>(isAuth ? APIS.VESTING_LIST : "", {
    perPage,
    search_value: search,
    network_id,
    token_address: address,
  });

  const handleChange = (address: string) => {
    setContract(address);
    setSearch("");
  };
  const { deploySmartContractRaw, getAddress } = useFactoryContract();

  const { totalSupply: contractTotalSupply } = useErc720Contract(address);
  const { batchCreateVestingSchedule } = useVestingContract(contract);

  const { waitForTransaction } = useTransaction();

  const getTotalSupply = useCallback(async () => {
    if (!contractTotalSupply) return;
    try {
      const totalSupply = await contractTotalSupply();
      setTotalSupply(totalSupply.toString());
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, contractTotalSupply]);

  useEffect(() => {
    getTotalSupply();
  }, [getTotalSupply]);

  const onFinish = async () => {
    if (!batchCreateVestingSchedule) return;
    setLoading(true);
    try {
      const data: VestingData[] = values.map((item): VestingData => {
        const {
          address,
          amount,
          candence,
          isEmployee,
          vestingPeriod: [start, end],
        } = item;

        return [
          address,
          start.unix(),
          0,
          end.unix() - start.unix(),
          candence,
          !!isEmployee,
          "0",
          multipleDecimals(amount, token.decimals),
        ];
      });
      const result = await batchCreateVestingSchedule(data);
      if (!result) throw new Error("Cannot Create Vesting");

      const { hash } = result;
      await result.wait(1);
      await waitForTransaction(hash);
      setVestingData(data);
      customToast.success("Create Vesting Successfully");
    } catch (error: any) {
      console.log({ error });
      const errors: StringObject = error.response?.data?.errors || { name: "Create Vesting Failed" };
      form.setFields(Object.entries(errors).map(([key, value]) => ({ name: key, errors: [value] })));
      form.getFieldInstance(Object.keys(errors)[0] || "name")?.focus();
      const errMessage = Object.values(errors)[0];
      customToast.error(typeof errMessage === "string" ? errMessage : "Create Vesting Failed");
    }
    setLoading(false);
  };

  const onFinishFailed: FormProps["onFinishFailed"] = errorInfo => {
    const namePath = errorInfo.errorFields[0].name?.[0];
    form.getFieldInstance(namePath)?.focus();
  };

  const handleAddVestingItem = (newItem: VestingTokenItemValues) => {
    const rows = (form.getFieldValue("rows") as VestingTokenItemValues[]) || [];
    if (onEditIndex === null) form.setFieldsValue({ rows: [...rows, newItem] });
    else {
      rows[onEditIndex] = newItem;
      form.setFieldsValue({ rows: [...rows] });
    }
    setValues([]);
    setOnEdit(null);
    setOnEditIndex(null);
    setVisible(false);
  };

  const readAndSaveFile = (file: File, e?: ChangeEvent<HTMLInputElement>) => {
    setFileLoading(true);
    const reader = new FileReader();
    reader.onload = () => {
      setFileLoading(false);
      let data = reader.result?.toString() || "";
      if (EXCELS.includes(file.type)) {
        const wb = XLSX.read(reader.result, { type: "binary" });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        data = XLSX.utils.sheet_to_csv(ws);
      }
      try {
        const rows = convertCSVToTokenVesting(data);
        setValues([]);
        form.setFieldValue("file", rows);
        form.validateFields(["file"]);
      } catch (error) {}
      if (e) e.target.value = "";
    };
    reader.onerror = () => {
      setFileLoading(false);
    };
    reader.readAsBinaryString(file);
  };

  const handleCreateContract = async () => {
    if (!selectedNetwork || !deploySmartContractRaw || !getAddress) return;
    setLoadingDeploy(true);
    try {
      const res = await VestingService.createVestingContract({
        token_address: address,
        provider: { name: selectedNetwork.name, network_id: selectedNetwork.network_id.toString() },
      });
      if (!res.data?.payload) throw new Error("Create Vesting Contract Failed");
      const { payload, salt } = res.data;
      try {
        const newContract = await deploySmartContractRaw(payload, salt?.toString() ?? "1");
        if (!newContract) throw new Error("Cannot Deploy Vesting Contract");
        const { hash } = newContract;
        const address = await getAddress(payload, salt?.toString() ?? "1");
        const data = await newContract.wait(1);
        try {
          await VestingService.updateVestingContractLog(address.toLowerCase(), {
            status: TokenStatus.SUBMITTED,
            payload: { hash, blockNumber: data.blockNumber.toString() },
          });
        } catch (error) {
          console.log({ error });
        }
        await waitForTransaction(hash);
        customToast.success("Create Vesting Contract Successfully");
        setContract(address);
      } catch (error: any) {
        console.log({ error });
        if (error.code === "ACTION_REJECTED") customToast.error("Please accept transaction to complete token creation");
        else customToast.error("Transaction Error");
      }
    } catch (error) {}
    setLoadingDeploy(false);
  };

  const onApproved = () => {
    setApproved(true);
    customToast.success("Approved Successfully");
  };

  const onFailed = () => {
    customToast.error("User Reject");
  };

  const handleDownload = async (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const template = [
      [
        "Wallet Address",
        "Wallet Address Name",
        "Employee / Investor",
        "Number Of Tokens",
        "Start Date",
        "End Date",
        "Vesting Candence (seconds)",
        "Lock Period",
        "Lock Period Date",
        "Lock Period Percent",
      ],
      [
        "0xfEc0c026B5b438B83f5Bb092F72fb27107168B78",
        "Wallet Name 1",
        "Employee",
        10,
        new Date().toISOString(),
        new Date(Date.now() + 30 * 24 * 60 * 60).toISOString(),
        24 * 60 * 60,
        true,
        new Date().toISOString(),
        0,
      ],
      [
        "0xa9cD394Cd85c882b36c96AE652f22C6EBf058Cb8",
        "Wallet Name 2",
        "Investor",
        1000,
        new Date().toISOString(),
        new Date(Date.now() + 365 * 24 * 60 * 60).toISOString(),
        30 * 24 * 60 * 60,
        true,
        new Date(Date.now() + 30 * 24 * 60 * 60).toISOString(),
        10,
      ],
    ];
    console.log(template.map(row => row.join("\t")).join("\n"));
    const data = encodeURIComponent(template.map(row => row.join(",")).join("\n"));
    const a = document.createElement("a");
    a.href = "data:text/csv;charset=utf-8," + data;
    a.download = "template.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  console.log(token);

  return (
    <Form
      form={form}
      className="form vesting-token-form"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      layout="horizontal"
      onFinish={onFinish}
      validateMessages={{ required: REQUIRED() }}
      onFinishFailed={onFinishFailed}
      initialValues={{ rows: [] }}
    >
      <div className="token-detail-header">
        <div className="token-detail-info">
          <div className="token-info-label">Total Supply</div>
          <div className="token-info-value">{formatNumber(totalSupply, token.decimals)}</div>
        </div>
        <div className="token-detail-info">
          <div className="token-info-label">Vesting Detail</div>
        </div>
      </div>
      <Radio.Group
        className="vesting-create-type"
        value={+isAdd}
        onChange={() => {
          fileRef.current?.reset();
          setIsAdd(true);
          form.setFieldValue("file", []);
        }}
      >
        <Radio value={1}>Create New Vesting Plan</Radio>
      </Radio.Group>

      <CustomCollapse active={!isAdd}>
        <FormItem dependencies={["rows"]} noStyle>
          {() => {
            const rows = (form.getFieldValue("rows") as VestingTokenItemValues[]) || [];
            if (!rows?.length) return null;
            return (
              <div className="vesting-list">
                {rows.map((item, index) => (
                  <div className="vesting-item" key={index}>
                    <div>
                      {index + 1}. {ellipseAddress(item.address, 6, 4)}
                    </div>
                    <div>
                      {item.amount} {token.symbol}
                    </div>
                    <div className="vesting-item-action">
                      <DeleteIcon
                        onClick={() => {
                          form.setFieldValue(
                            "rows",
                            rows.filter((_, _index) => index !== _index)
                          );
                          setCounter(counter + 1);
                          setValues([]);
                        }}
                      />
                      <DuplicateIcon
                        onClick={() => {
                          form.setFieldValue("rows", [...rows.slice(0, index), { ...item }, ...rows.slice(index)]);
                          setCounter(counter + 1);
                          setValues([]);
                        }}
                      />
                      <EditIcon
                        onClick={() => {
                          setVisible(true);
                          setOnEdit(item);
                          setOnEditIndex(index);
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            );
          }}
        </FormItem>
        <FormItem
          label="Manual Input"
          name="rows"
          labelCol={{ span: 24 }}
          rules={[{ required: isAdd, message: REQUIRED() }, { validator: isVestingTemplateToken("Input") }]}
          noStyle
        >
          <CustomInput type="hidden" />
        </FormItem>
        <Button className="add-vesting-button" onClick={() => setVisible(!visible)}>
          Add Address
        </Button>
      </CustomCollapse>
      <Radio.Group
        className="vesting-create-type"
        value={+isAdd}
        onChange={() => {
          setIsAdd(false);
          form.setFieldValue("rows", []);
        }}
      >
        <Radio value={0}>Upload File</Radio>
      </Radio.Group>

      <CustomCollapse active={isAdd}>
        <FormItem dependencies={["file"]} noStyle>
          {() => {
            const rows = (form.getFieldValue("file") as VestingTokenItemValues[]) || [];
            if (!rows?.length) return null;
            return (
              <div className="vesting-list">
                <div className="vesting-item">
                  <div>Wallet Address</div>
                  <div>Wallet Name</div>
                  <div>Token</div>
                  <div>Vesting Amount</div>
                  <div>Vesting Calender</div>
                </div>
                {rows.map((item, index) => (
                  <div className="vesting-item" key={index}>
                    <div>{ellipseAddress(item.address, 6, 4)}</div>
                    <div>{item.name}</div>
                    <div>{token.symbol}</div>
                    <div>{item.amount}</div>
                    <div>{item.candence}</div>
                  </div>
                ))}
              </div>
            );
          }}
        </FormItem>
        <FormItem
          label="Manual Input"
          name="file"
          labelCol={{ span: 24 }}
          rules={[{ required: !isAdd, message: REQUIRED() }, { validator: isVestingTemplateToken("Input") }]}
          noStyle
        >
          <CustomInput type="hidden" />
        </FormItem>
        <Upload
          name="file"
          loading={fileLoading}
          onChange={file => readAndSaveFile(file)}
          accepts={ACCEPTEDS}
          placeholder={
            <div className="vesting-upload-label">
              Upload File
              <br />
              CSV/ Excel, please make sure all fields are filled.
              <br />
              <a href="#template" onClick={handleDownload}>
                See template here
              </a>
            </div>
          }
          setRef={ref => (fileRef.current = ref)}
        />
      </CustomCollapse>
      {values.length ? (
        !contract ? (
          <CustomSelect
            showSearch
            onChange={handleChange}
            placeholder="Enter Smart Contract Address"
            disabled={loadingVesting || loading}
            onSearch={value => setSearch(value)}
            loading={loadingVesting || loading}
            hasMore={hasMore}
            next={next}
          >
            {data.map(({ address, symbol }) => (
              <Select.Option key={address} value={address}>
                <div className="token-select-item">
                  <span>{address}</span> <span className="token-select-symbol">{symbol}</span>
                </div>
              </Select.Option>
            ))}
          </CustomSelect>
        ) : (
          <div className="vesting-contract">
            <div className="vesting-contract-label">Smart Contract</div>
            <div className="vesting-contract-value">{ellipseAddress(contract, 6, 4)}</div>
          </div>
        )
      ) : null}
      <div className="form-footer">
        {!values.length ? (
          <FormItem dependencies={["rows", "file"]} noStyle>
            {() => {
              const rows = (form.getFieldValue("rows") as VestingTokenItemValues[]) || [];
              const file = (form.getFieldValue("file") as VestingTokenItemValues[]) || [];
              const values = isAdd ? rows : file;
              const disabled =
                !values.length || !!form.getFieldsError().find(item => item.errors.length || item.warnings.length);
              return (
                <GradientButton className="continue-button" disabled={disabled} onClick={() => setValues(values)}>
                  Continue
                </GradientButton>
              );
            }}
          </FormItem>
        ) : !contract ? (
          <div className="submit-vesting-contract">
            <Button className="cancel-button" onClick={handleCreateContract} loading={loadingDeploy} disabled={loading}>
              Create Smart Contract
            </Button>
            <GradientButton className="continue-button" disabled>
              Continue
            </GradientButton>
          </div>
        ) : !approved ? (
          <ApproveButton
            className="continue-button"
            token={token.address}
            address={contract}
            amount={multipleDecimals(totalAmount, token.decimals)}
            onApproved={onApproved}
            onFailed={onFailed}
          />
        ) : (
          <GradientButton
            className="continue-button"
            disabled={loading}
            loading={loading}
            htmlType="submit"
            onClick={() => console.log(form.getFieldsError())}
          >
            Create Vesting plan
          </GradientButton>
        )}
      </div>
      <AddVestingTokenForm
        open={visible}
        token={token}
        totalSupply={totalSupply}
        onClose={() => setVisible(false)}
        onSubmit={handleAddVestingItem}
        onEdit={onEdit}
      />
      <VestedTokenModal data={vestingData} address={contract} />
    </Form>
  );
};

export default VestingTokenForm;
