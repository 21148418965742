import "./styles.scss";

type Props = {};

const Breadcrumb = (props: Props) => {
  return (
    <div className="breadcrumb">
      <div className="power-text">Powered By Morpheus LAB</div>
      <div className="breadcrumb-title">TOKEN FACTORY</div>
    </div>
  );
};

export default Breadcrumb;
