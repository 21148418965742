import { Axios } from "axios";
import defaultAxios, { uploadAxios } from "./axios";

export class BaseService {
  static get: Axios["get"] = (...args) => defaultAxios.get(...args);
  static post: Axios["post"] = (...args) => defaultAxios.post(...args);
  static put: Axios["put"] = (...args) => defaultAxios.put(...args);
  static delete: Axios["delete"] = (...args) => defaultAxios.delete(...args);
  static patch: Axios["patch"] = (...args) => defaultAxios.patch(...args);

  static postUpload: Axios["post"] = (...args) => uploadAxios.post(...args);
  static putUpload: Axios["put"] = (...args) => uploadAxios.put(...args);
}
