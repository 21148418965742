import { Col, Form, Tooltip, Row } from "antd";
import { useState } from "react";
import { TokenService } from "src/services/token-service";
import {
  ALLOW_STRING_NUMBER_ONLY,
  ALLOW_STRING_NUMBER_SPECIAL_ONLY,
  EXCEEDS_LENGTH_LIMIT,
  REQUIRED,
} from "src/commons/messages/validate";
import { convertToTokenBody } from "src/commons/utils/converters/tokenConverter";
import {
  EXPAND_CREATE_TOKEN_APPLY_BURN_FEE,
  EXPAND_CREATE_TOKEN_CAN_BLACKLIST,
  EXPAND_CREATE_TOKEN_CAN_BURN,
  EXPAND_CREATE_TOKEN_CAN_CHARGE_TX_FEE,
  EXPAND_CREATE_TOKEN_CAN_MINT,
  EXPAND_CREATE_TOKEN_CAN_PAUSE,
  EXPAND_CREATE_TOKEN_CAN_TRANSFERRED,
  EXPAND_CREATE_TOKEN_DIFFIRENT_OWNER,
  EXPAND_CREATE_TOKEN_DOCUMENT,
  EXPAND_CREATE_TOKEN_LIMIT_TOKEN,
  EXPAND_CREATE_TOKEN_REQUIRED_WHITELIST,
} from "src/commons/messages/expand";
import { InfoIcon } from "src/commons/resources/icons";
import ResizeIcon from "../08.resize-icon";
import NumberInput from "../07.inputs/NumberInput";
import { useSelector } from "react-redux";
import CustomCollapse from "../09.collaspe";
import { FormProps } from "antd/lib";
import WaitingModal from "../04.modals/WaitingModal";
import { useFactoryContract } from "src/web3/contracts/useFactoryContract";
import CreatedTokenModal from "../04.modals/CreatedTokenModal";
import CustomInput from "../07.inputs";
import CustomSwitch from "../10.switch";
import GradientButton from "../03.buttons/GradientButton";
import { useWeb3React } from "@web3-react/core";
import { TokenStatus } from "src/web3/constants";
import { isNotSame, isAddress, isBetWeenMinMax } from "src/commons/utils/functions/validates";
import { customToast } from "../02.toasts";

import "./styles.scss";
import { useTransaction } from "src/web3/hooks/useTransaction";

const FormItem = Form.Item<CreateTokenValues>;

const CreateTokenForm = () => {
  const selectedNetwork = useSelector(({ system }: RootState) => system.selectedNetwork);
  const isAuth = useSelector(({ user }: RootState) => !!user.currentAccount);
  const { account } = useWeb3React();
  const [form] = Form.useForm<CreateTokenValues>();
  const [loading, setLoading] = useState(false);
  const [tokenCreated, setTokenCreated] = useState<TokenCreated | null>(null);
  const { deploySmartContractRaw, getAddress } = useFactoryContract();
  const { waitForTransaction } = useTransaction();

  const onFinish = async (values: CreateTokenValues) => {
    setLoading(true);
    try {
      if (!account || !selectedNetwork || !deploySmartContractRaw || !getAddress) return;
      const body = convertToTokenBody(values, account, selectedNetwork);
      const res = await TokenService.createERC20Token(body);
      if (!res.data?.payload) throw new Error("Create Token Failed");
      const { payload, salt } = res.data;

      try {
        const newContract = await deploySmartContractRaw(payload, salt?.toString() ?? "1");

        if (!newContract) throw new Error("Cannot Deploy token");

        const { hash } = newContract;

        const address = await getAddress(payload, salt?.toString() ?? "1");
        const data = await newContract.wait(1);

        try {
          await TokenService.updateERC20TokenLog(address.toLowerCase(), {
            status: TokenStatus.SUBMITTED,
            payload: { hash, blockNumber: data.blockNumber.toString() },
          });
        } catch (error) {
          console.log({ error });
        }

        await waitForTransaction(hash);

        const { symbol, decimals } = body;
        customToast.success("Create token successfully");
        setTokenCreated({ address, symbol, decimals, image: "" });
      } catch (error: any) {
        console.log({ error });
        if (error.code === "ACTION_REJECTED") customToast.error("Please accept transaction to complete token creation");
        else customToast.error("Transaction Error");
      }
    } catch (error: any) {
      console.log({ error });
      const errors: StringObject = error.response?.data?.errors || { name: "Create Token Failed" };
      form.setFields(Object.entries(errors).map(([key, value]) => ({ name: key, errors: [value] })));
      form.getFieldInstance(Object.keys(errors)[0] || "name")?.focus();
      const errMessage = Object.values(errors)[0];
      customToast.error(typeof errMessage === "string" ? errMessage : "Create Token Failed");
    }

    setLoading(false);
  };

  const onFinishFailed: FormProps["onFinishFailed"] = errorInfo => {
    const namePath = errorInfo.errorFields[0].name?.[0];
    form.getFieldInstance(namePath)?.focus();
  };

  const handleReset = () => {
    form.resetFields();
    setTokenCreated(null);
  };

  return (
    <Form
      form={form}
      className="form create-token-form"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      layout="horizontal"
      onFinish={onFinish}
      validateMessages={{ required: REQUIRED() }}
      onFinishFailed={onFinishFailed}
    >
      <FormItem
        colon
        label="Token Name"
        name="name"
        rules={[
          { required: true, message: "Token name is required. Please provide a name for your token" },
          {
            max: 255,
            message: EXCEEDS_LENGTH_LIMIT(255, "Token name"),
          },
          { pattern: /^[a-zA-Z0-9-_\s]+$/, message: ALLOW_STRING_NUMBER_SPECIAL_ONLY("Token name") },
        ]}
      >
        <CustomInput onKeyDown={e => !/^[a-zA-Z0-9-_\s]+$/.test(e.key) && e.preventDefault()} />
      </FormItem>
      <FormItem
        colon
        label="Symbol"
        name="symbol"
        rules={[
          { required: true, message: "Token symbol is required. Please provide a symbol for your token" },
          {
            max: 10,
            message: EXCEEDS_LENGTH_LIMIT(10, "Token symbol", "symbol"),
          },
          { pattern: /^[a-zA-Z0-9]+$/, message: ALLOW_STRING_NUMBER_ONLY("Token symbol") },
        ]}
      >
        <CustomInput
          onKeyDown={e => !/^[a-zA-Z0-9]+$/.test(e.key) && e.preventDefault()}
          onChange={e => form.setFieldValue("symbol", e.target.value.toUpperCase())}
        />
      </FormItem>
      <FormItem noStyle dependencies={["decimals"]}>
        {() => (
          <FormItem
            colon
            label="Initial supply"
            name="initial_supply"
            rules={[{ required: true, message: "Initial supply is required" }]}
            wrapperCol={{ span: 12 }}
          >
            <NumberInput name="initial_supply" rules={[{ required: true }]} allowDecimals={false} />
          </FormItem>
        )}
      </FormItem>
      <FormItem
        colon
        label="Decimals (0-18)"
        name="decimals"
        rules={[
          {
            required: true,
            message: "Decimals value is required. Please provide the number of decimals for your token",
          },
          { validator: isBetWeenMinMax(0, 18, "Decimals") },
        ]}
        wrapperCol={{ span: 12 }}
      >
        <NumberInput
          name="decimals"
          rules={[{ required: true }, { validator: isBetWeenMinMax(0, 18, "Decimals") }]}
          allowDecimals={false}
        />
      </FormItem>
      <Row>
        <Col span={12}>
          <FormItem colon label="Can Burn">
            <div className="expand-input">
              <FormItem noStyle name="can_burn" valuePropName="checked">
                <CustomSwitch />
              </FormItem>
              <Tooltip title={EXPAND_CREATE_TOKEN_CAN_BURN}>
                <ResizeIcon icon={InfoIcon} className="field-info" width={33} />
              </Tooltip>
            </div>
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem colon label="Can Mint">
            <div className="expand-input">
              <FormItem noStyle name="can_mint" valuePropName="checked">
                <CustomSwitch />
              </FormItem>
              <Tooltip title={EXPAND_CREATE_TOKEN_CAN_MINT}>
                <ResizeIcon icon={InfoIcon} className="field-info" width={33} />
              </Tooltip>
            </div>
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem colon label="Can Pause">
            <div className="expand-input">
              <FormItem noStyle name="can_pause" valuePropName="checked">
                <CustomSwitch />
              </FormItem>
              <Tooltip title={EXPAND_CREATE_TOKEN_CAN_PAUSE}>
                <ResizeIcon icon={InfoIcon} className="field-info" width={33} />
              </Tooltip>
            </div>
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem colon label="Can Blacklist">
            <div className="expand-input">
              <FormItem noStyle name="can_blacklist" valuePropName="checked">
                <CustomSwitch />
              </FormItem>
              <Tooltip title={EXPAND_CREATE_TOKEN_CAN_BLACKLIST}>
                <ResizeIcon icon={InfoIcon} className="field-info" width={33} />
              </Tooltip>
            </div>
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem colon label="Can Be Force Transferred">
            <div className="expand-input">
              <FormItem noStyle name="can_be_forced_transferred" valuePropName="checked">
                <CustomSwitch />
              </FormItem>
              <Tooltip title={EXPAND_CREATE_TOKEN_CAN_TRANSFERRED}>
                <ResizeIcon icon={InfoIcon} className="field-info" width={33} />
              </Tooltip>
            </div>
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem colon label="Require Whitelist">
            <div className="expand-input">
              <FormItem noStyle name="is_require_whitelist" valuePropName="checked">
                <CustomSwitch />
              </FormItem>
              <Tooltip title={EXPAND_CREATE_TOKEN_REQUIRED_WHITELIST}>
                <ResizeIcon icon={InfoIcon} className="field-info" width={33} />
              </Tooltip>
            </div>
          </FormItem>
        </Col>
      </Row>

      <FormItem colon label="Can Charge Transaction Fee">
        <div className="expand-input">
          <FormItem noStyle name="can_charge_transaction_fee" valuePropName="checked">
            <CustomSwitch />
          </FormItem>
          <Tooltip title={EXPAND_CREATE_TOKEN_CAN_CHARGE_TX_FEE}>
            <ResizeIcon icon={InfoIcon} className="field-info" width={33} />
          </Tooltip>
        </div>
      </FormItem>
      <FormItem noStyle dependencies={["can_charge_transaction_fee"]}>
        {() => (
          <CustomCollapse active={!form.getFieldValue("can_charge_transaction_fee")}>
            <FormItem
              colon
              label="Recipient Address"
              name="recipient_address"
              rules={[
                {
                  required: !!form.getFieldValue("can_charge_transaction_fee"),
                  message: "Recipient address is required",
                },
                { validator: isAddress("recipient ") },
              ]}
            >
              <CustomInput placeholder="Enter Wallet Address" maxLength={42} />
            </FormItem>
            <div className="description-input">Can be updated after token being minted</div>

            <FormItem
              colon
              label="Fee on transaction"
              name="transaction_fee"
              rules={[
                {
                  required: !!form.getFieldValue("can_charge_transaction_fee"),
                  message: "The fee percentage field is required",
                },
                { validator: isBetWeenMinMax(0, 100, "", "Transaction fee percentage is out of the allowed range") },
              ]}
            >
              <NumberInput name="transaction_fee" decimalsLimit={2} placeholder="e.g: 0.1%" />
            </FormItem>
            <div className="description-input">
              By percentage of transaction value. E.g: Transaction uses 100 token, fee is 1%, transaction fee will be 1
              token. Can be updated after token being minted
            </div>
          </CustomCollapse>
        )}
      </FormItem>
      <FormItem colon label="Apply Burn Fee">
        <div className="expand-input">
          <FormItem noStyle name="is_apply_burn_fee" valuePropName="checked">
            <CustomSwitch />
          </FormItem>
          <Tooltip title={EXPAND_CREATE_TOKEN_APPLY_BURN_FEE}>
            <ResizeIcon icon={InfoIcon} className="field-info" width={33} />
          </Tooltip>
        </div>
      </FormItem>
      <FormItem noStyle dependencies={["is_apply_burn_fee"]}>
        {() => (
          <CustomCollapse active={!form.getFieldValue("is_apply_burn_fee")}>
            <FormItem
              colon
              label="Burn fee for deflationary token"
              name="burn_transaction_fee"
              rules={[
                {
                  required: !!form.getFieldValue("is_apply_burn_fee"),
                  message: "The fee percentage field is required",
                },
                { validator: isBetWeenMinMax(0, 100, "", "Burn fee percentage is out of the allowed range") },
              ]}
            >
              <NumberInput name="burn_transaction_fee" decimalsLimit={2} placeholder="e.g: 0.1%" />
            </FormItem>
            <div className="description-input">
              If activated, the specified burn fee as a portion of every transfer of the token will be burnt
            </div>
          </CustomCollapse>
        )}
      </FormItem>

      <FormItem colon label="Different Owner">
        <div className="expand-input">
          <FormItem noStyle name="hasDifferentOwner" valuePropName="checked">
            <CustomSwitch />
          </FormItem>
          <Tooltip title={EXPAND_CREATE_TOKEN_DIFFIRENT_OWNER}>
            <ResizeIcon icon={InfoIcon} className="field-info" width={33} />
          </Tooltip>
        </div>
      </FormItem>
      <FormItem noStyle dependencies={["hasDifferentOwner"]}>
        {() => (
          <CustomCollapse active={!form.getFieldValue("hasDifferentOwner")}>
            <FormItem
              colon
              label="Owner Address"
              name="owner_address"
              rules={
                form.getFieldValue("hasDifferentOwner")
                  ? [
                      { required: true, message: "Owner address is required." },
                      { validator: isAddress("owner ") },
                      { validator: isNotSame(account, "Owner address must be different from creator address.") },
                    ]
                  : []
              }
            >
              <CustomInput placeholder="Enter Wallet Address" maxLength={42} />
            </FormItem>
            <div className="description-input">
              Configure default owner of the token, if token owner is different from token creation address, you might
              lose access to the token after creation
            </div>
          </CustomCollapse>
        )}
      </FormItem>

      <FormItem colon label="Limit Maximum tokens per address">
        <div className="expand-input">
          <FormItem noStyle name="is_limited_tokens_per_address" valuePropName="checked">
            <CustomSwitch />
          </FormItem>
          <Tooltip title={EXPAND_CREATE_TOKEN_LIMIT_TOKEN}>
            <ResizeIcon icon={InfoIcon} className="field-info" width={33} />
          </Tooltip>
        </div>
      </FormItem>
      <FormItem noStyle dependencies={["is_limited_tokens_per_address", "decimals"]}>
        {() => (
          <CustomCollapse active={!form.getFieldValue("is_limited_tokens_per_address")}>
            <FormItem
              colon
              label="Maximum tokens per address"
              name="tokens_per_address"
              rules={[
                {
                  required: !!form.getFieldValue("is_limited_tokens_per_address"),
                  message: "Maximum tokens per address is required",
                },
              ]}
            >
              <NumberInput
                name="tokens_per_address"
                decimalsLimit={Number(form.getFieldValue("decimals") || 18)}
                placeholder="e.g: 100,000"
              />
            </FormItem>
          </CustomCollapse>
        )}
      </FormItem>

      <FormItem colon label="Documentation">
        <div className="expand-input">
          <FormItem noStyle name="is_have_documentation" valuePropName="checked">
            <CustomSwitch />
          </FormItem>
          <Tooltip title={EXPAND_CREATE_TOKEN_DOCUMENT}>
            <ResizeIcon icon={InfoIcon} className="field-info" width={33} />
          </Tooltip>
        </div>
      </FormItem>
      <FormItem noStyle dependencies={["is_have_documentation"]}>
        {() => (
          <CustomCollapse active={!form.getFieldValue("is_have_documentation")}>
            <FormItem
              colon
              label="Document URL"
              name="document_url"
              rules={
                form.getFieldValue("is_have_documentation")
                  ? [
                      {
                        type: "url",
                        message: "The URL format is invalid. Please ensure it starts with 'http://' or 'https://.",
                      },
                      {
                        required: true,
                        message: "Document URL is required.",
                      },
                    ]
                  : []
              }
            >
              <CustomInput placeholder="https://" />
            </FormItem>
          </CustomCollapse>
        )}
      </FormItem>

      {(!account || !isAuth) && (
        <div className="danger-message">You have to Connect Wallet before creating a token</div>
      )}

      <div className="form-submit">
        <GradientButton htmlType="submit" type="primary" disabled={!account || !isAuth} loading={loading}>
          Create Token
        </GradientButton>
      </div>
      <WaitingModal open={loading} description="Your token is being created" />
      <CreatedTokenModal
        token={tokenCreated}
        onClose={handleReset}
        isOwner={!form.getFieldValue("hasDifferentOwner")}
      />
    </Form>
  );
};

export default CreateTokenForm;
