import React from "react";
import { DatePicker, DatePickerProps } from "antd";
import { DatePickRef } from "antd/lib/date-picker/generatePicker/interface";

import "./styles.scss";

const CustomDatePicker = React.forwardRef(({ className, ...props }: DatePickerProps, ref: DatePickRef<Dayjs>) => {
  return <DatePicker ref={ref} className={`custom-input ${className ?? ""}`} {...props} />;
});

export default CustomDatePicker;
