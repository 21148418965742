import { useWeb3React } from "@web3-react/core";
import { useEffect } from "react";
import { baseQueryApi } from "src/store/baseQueryApi";
import { setIsAuth, setIsAuthChecking } from "src/store/slices/auth";
import { clearAccessToken, clearWallet } from "src/store/slices/user";
import { ConnectorKey } from "../connectors";
import { useDispatch, useSelector } from "react-redux";

/**
 * Trying eager connect to connectors at first time.
 * @returns `tried` tried eager connect done or not
 */
export function useEagerConnect() {
  const { isActive } = useWeb3React();
  const accessToken = useSelector((state: RootState) => state.user.accessToken);
  const connectors = useSelector(({ system }: RootState) => system.connectors);
  const dispatch = useDispatch();
  const wallet = useSelector((state: RootState) => state.user.wallet);

  const handleClearData = () => {
    dispatch(clearAccessToken());
    dispatch(clearWallet());
    baseQueryApi.util.resetApiState();
  };

  useEffect(() => {
    if (!isActive) {
      if (wallet === ConnectorKey.walletConnect) {
        connectors[wallet]
          ?.connectEagerly()
          .then(() => {
            if (accessToken) {
              dispatch(setIsAuth(true));
            }
          })
          .catch(() => {
            handleClearData();
          });
      } else if (wallet === ConnectorKey.metaMask) {
        (window.ethereum as any)?._metamask.isUnlocked().then((isUnlock: any) => {
          if (isUnlock) {
            connectors[wallet]?.connectEagerly().then(() => {
              if (accessToken) {
                dispatch(setIsAuth(true));
              }
            });
          } else {
            handleClearData();
          }
        });
      }
      return;
    }

    // Update `tried` only when isActive was `true`
    dispatch(setIsAuthChecking(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
