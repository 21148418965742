import React, { useState } from "react";
import { MetamaskIcon, WalletConnectIcon } from "src/commons/resources/icons";
import { ConnectorKey } from "src/web3/connectors";
import CustomModal, { ICustomModalProps } from "..";
import { useAuth } from "src/commons/hooks/useAuth";
import { TOKEN_KEY } from "src/commons/constants";
import { customToast } from "src/components/02.toasts";

import "./styles.scss";

interface IConnectWalletModalProps extends ICustomModalProps {
  closeModal?: () => void;
  onLoginSuccess?: () => void;
}
interface IConnectBoxProps {
  text: string;
  icon: string;
  isActive: boolean;
  onClick: () => void;
}

const ConnectBox: React.FC<IConnectBoxProps> = (props: IConnectBoxProps) => {
  const { icon, isActive, onClick, text } = props;

  return (
    <div className={`box-connect ${isActive ? "active-connect" : ""}`} onClick={onClick}>
      <div className="group-content">
        <img src={icon} alt="icon" />
        <div className="text">{text}</div>
      </div>
    </div>
  );
};

const ConnectWalletModal: React.FC<IConnectWalletModalProps> = (props: IConnectWalletModalProps) => {
  const { login, logout } = useAuth();

  const [isInstallMetaMask, setInstallMetaMask] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isWelcome, setIsWelcome] = useState<boolean>(false);
  const { open, closeModal, onLoginSuccess } = props;

  const handleCloseModal = () => {
    if (closeModal) {
      setInstallMetaMask(true);
      closeModal();
    }
  };

  const handleClick = async (connectorKey: ConnectorKey) => {
    if (connectorKey === ConnectorKey.metaMask && !window?.ethereum?.isMetaMask) return setInstallMetaMask(false);
    else if (connectorKey === ConnectorKey.walletConnect && isInstallMetaMask) setInstallMetaMask(true);
    try {
      if (localStorage.getItem(TOKEN_KEY)) {
        logout();
      }
      if (connectorKey === ConnectorKey.metaMask) {
        setIsLoading(true);

        await login(ConnectorKey.metaMask);
        onLoginSuccess && onLoginSuccess();
      } else {
        handleCloseModal();
        await login(ConnectorKey.walletConnect);
      }
      handleCloseModal();
      setIsWelcome(true);
    } catch (error: any) {
      console.log(error);
      handleError(error);
    }
    setIsLoading(false);
  };

  // };

  const handleError = (error: WEB3_ERROR) => {
    switch (error.type) {
      case "metamask_lock":
        customToast.error("MetaMask locked");
        break;
      case "user_reject":
        customToast.error("User Reject");
        break;
      case "un_support_chain":
        customToast.error("Un supported network");
        break;
      case "no_eth_provider":
        setInstallMetaMask(false);
        break;
      default:
        customToast.error("Failed to connect");
    }
  };

  const handleCancel = () => {
    setInstallMetaMask(true);
    handleCloseModal();
  };

  const metamaskAppDeepLink = `https://metamask.app.link/dapp/${window.location.host}${window.location.pathname}`;

  return (
    <>
      <CustomModal
        open={open}
        onCancel={handleCancel}
        width={500}
        title="Connect Wallet"
        wrapClassName={`connect-wallet-modal`}
      >
        {!isInstallMetaMask && (
          <div className="error-message-metamask">
            Unavailable extension.{" "}
            <a href={metamaskAppDeepLink} target="_blank" rel="noreferrer">
              Click here
            </a>{" "}
            to install
          </div>
        )}
        {!isLoading && !isWelcome && (
          <div className="type-connect-group">
            <ConnectBox
              icon={MetamaskIcon}
              onClick={() => {
                handleClick(ConnectorKey.metaMask);
              }}
              text="MetaMask"
              isActive={true}
            />
            <ConnectBox
              icon={WalletConnectIcon}
              onClick={() => {
                handleClick(ConnectorKey.walletConnect);
              }}
              text="WalletConnect"
              isActive={true}
            />
          </div>
        )}
        {isLoading && !isWelcome && (
          <div className="type-connect-group">
            <div className="connect-guide">
              <img src={MetamaskIcon} alt="Metamask Icon" className="connect-guide-icon" />
              <span className="connect-guide-title">Opening MetaMask</span>
              <span className="connect-guide-text">Please confirm and sign in metamask to proceed</span>
            </div>
          </div>
        )}
      </CustomModal>
      <CustomModal
        open={isWelcome}
        onCancel={() => setIsWelcome(false)}
        width={448}
        wrapClassName={`connect-wallet-modal`}
        className="welcome-modal"
      >
        <div>
          <h1 className="welcome-title">
            🎉 Welcome to <br /> Token Factory
          </h1>
          <p className="welcome-content">Wellcome to Token Factory</p>
        </div>
      </CustomModal>
    </>
  );
};

export default ConnectWalletModal;
