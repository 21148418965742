import { Button, Form } from "antd";
import { FormProps } from "antd/lib";
import { useEffect, useState } from "react";
import { REQUIRED } from "src/commons/messages/validate";
import MethodSubmitButton from "src/components/03.buttons/MethodSubmitButton";
import CustomInput from "src/components/07.inputs";
import NumberInput from "src/components/07.inputs/NumberInput";
import ModalWithLogo from "src/components/04.modals/ModalWithLogo";
import { isAddress, isGreateThan0, isNotExceed, isNotSame } from "src/commons/utils/functions/validates";
import { formatNumber } from "src/commons/utils/functions/formatNumber";
import { useErc720Contract } from "src/web3/contracts/useErc720Contract";
import { multipleDecimals } from "src/commons/utils/functions/multipleDecimals";
import { useWeb3React } from "@web3-react/core";
import { BigNumber as EtherBigNumber } from "ethers";
import { customToast } from "src/components/02.toasts";

import "./styles.scss";

type Props = {
  token: TokenType;
  address: string;
  open: boolean;
  onClose: () => void;
  totalSupply: string;
  getUserBalance: () => void;
  balanceOf: (address: string) => Promise<EtherBigNumber | null>;
};

const FormItem = Form.Item<TransferTokenValues>;

const TransferTokenModal = (props: Props) => {
  const { token, address, open, totalSupply, getUserBalance, onClose, balanceOf } = props;
  const [form] = Form.useForm<TransferTokenValues>();
  const [loading, setLoading] = useState(false);
  const { transferFrom } = useErc720Contract(address);
  const { account } = useWeb3React();

  const { name, decimals } = token;

  const onFinish = async (values: TransferTokenValues) => {
    if (!transferFrom) return;
    setLoading(true);
    try {
      const { from, to } = values;
      const amount = multipleDecimals(values.amount, decimals);
      const transaction = await transferFrom(from, to, amount);
      await transaction?.wait(1);
      customToast.success("Transfer Token Successfully");
      if (from === account || account === to) {
        getUserBalance();
      }
      onClose();
    } catch (error: any) {
      console.log({ error });
      const errors: StringObject = error.response?.data?.errors || { name: "Transfer Token Failed" };
      form.setFields(Object.entries(errors).map(([key, value]) => ({ name: key, errors: [value] })));
      form.getFieldInstance(Object.keys(errors)[0] || "name")?.focus();
      const errMessage = Object.values(errors)[0];
      customToast.error(typeof errMessage === "string" ? errMessage : "Transfer Token Failed");
    }
    setLoading(false);
  };

  const onFinishFailed: FormProps["onFinishFailed"] = errorInfo => {
    const namePath = errorInfo.errorFields[0].name?.[0];
    form.getFieldInstance(namePath)?.focus();
  };

  const handleReset = () => {
    form.resetFields();
  };

  useEffect(() => {
    handleReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <ModalWithLogo open={open} footer={false}>
      <Form
        form={form}
        className="form token-method-form"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        layout="horizontal"
        onFinish={onFinish}
        validateMessages={{ required: REQUIRED() }}
        onFinishFailed={onFinishFailed}
      >
        <div className="token-method-title">Transfer Token</div>
        <div className="token-detail-info">
          <div className="token-info-label">Token Name</div>
          <div className="token-info-value">{name}</div>
        </div>
        <div className="token-detail-info">
          <div className="token-info-label">Total Supply</div>
          <div className="token-info-value">{formatNumber(totalSupply, decimals)}</div>
        </div>
        <FormItem
          colon
          label="Holder Address"
          name="from"
          rules={[{ required: true, message: "Holder address is required" }, { validator: isAddress("holder ") }]}
        >
          <CustomInput
            placeholder="Enter Address"
            maxLength={42}
            // onChange={() => form.getFieldValue("to") && form.validateFields(["to"])}
          />
        </FormItem>
        <div className="description-input">
          In order to force the transaction, you need to enter the holder address, number of tokens sent and recipient
          address.
        </div>

        <FormItem shouldUpdate={() => true} noStyle>
          {() => (
            <FormItem
              colon
              label="Recipient Address"
              name="to"
              rules={[
                { required: true, message: "Recipient address is required" },
                { validator: isAddress("recipient ") },
                {
                  validator: isNotSame(
                    form.getFieldValue("from"),
                    "Recipient address must be different from holder address"
                  ),
                },
              ]}
            >
              <CustomInput placeholder="Enter wallet address" />
            </FormItem>
          )}
        </FormItem>
        <FormItem dependencies={["from", "to"]} noStyle>
          {() => (
            <FormItem
              colon
              label="Number Of Tokens"
              name="amount"
              rules={[
                { required: true, message: "Number of tokens is required" },
                { validator: isGreateThan0("The number of tokens transfered") },
                {
                  validator: isNotExceed(
                    form.getFieldValue("from") ? balanceOf(form.getFieldValue("from")) : null,
                    decimals,
                    "Holder address doesn’t have any balance"
                  ),
                },
              ]}
            >
              <NumberInput
                name="numOfTokens"
                rules={[
                  { required: true, message: "Number of tokens is required" },
                  {
                    validator: isNotExceed(
                      form.getFieldValue("from") ? balanceOf(form.getFieldValue("from")) : null,
                      decimals,
                      "Tokens can’t exceed holder’s balance."
                    ),
                  },
                ]}
                decimalsLimit={decimals}
                placeholder="e.g: 10 000 000"
              />
            </FormItem>
          )}
        </FormItem>
        <div className="form-submit">
          <Button className="cancel-button" onClick={onClose}>
            Back
          </Button>
          <MethodSubmitButton htmlType="submit" type="primary" loading={loading}>
            Submit
          </MethodSubmitButton>
        </div>
      </Form>
    </ModalWithLogo>
  );
};

export default TransferTokenModal;
