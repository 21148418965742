import React from "react";
import { Select, SelectProps } from "antd";
import { BaseSelectRef } from "rc-select";
import { ChevronDownIcon } from "src/commons/resources/icons";
import ResizeIcon from "../08.resize-icon";

import "./styles.scss";

interface CustomSelectProps extends SelectProps {
  hasMore?: boolean;
  next?: () => void;
}

const CustomSelect = React.forwardRef<BaseSelectRef, CustomSelectProps>((props, ref) => {
  const { className, popupClassName, hasMore, next, onPopupScroll, loading, ...rest } = props;

  const handleScroll: React.UIEventHandler<HTMLDivElement> = e => {
    const target = e.target as any as HTMLDivElement;
    if (target.scrollTop + target.offsetHeight >= target.scrollHeight - 100 && hasMore && !loading) next?.();
    onPopupScroll?.(e);
  };

  return (
    <Select
      ref={ref}
      className={`custom-select ${className ?? ""}`}
      popupClassName={`custom-select-popup ${popupClassName ?? ""}`}
      suffixIcon={<ResizeIcon icon={ChevronDownIcon} width={22} />}
      onPopupScroll={handleScroll}
      loading={loading}
      {...rest}
    />
  );
});

export default CustomSelect;
