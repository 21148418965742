import DistributeABI from "src/web3/abis/Distribute.json";
import { useContract } from "./useContract";
import { BaseContract } from "ethers";
import { useSelector } from "react-redux";

interface DistributeContract {
  batchDistribute?: (token: string, beneficiaries: string[], amount: string[]) => Promise<TransactionResponse>;
}

export const useDistributeContract = (): DistributeContract => {
  const selectedNetwork = useSelector(({ system }: RootState) => system.selectedNetwork);

  const DistributeContract = useContract<BaseContract & DistributeContract>(
    DistributeABI,
    selectedNetwork?.distribute_address || ""
  );

  return DistributeContract || {};
};
