import dayjs from "dayjs";
import ResizeIcon from "../08.resize-icon";
import { formatNumber } from "src/commons/utils/functions/formatNumber";
import CopyButton from "../11.copy-button";
import MethodButton from "../03.buttons/MethodButton";
import { ManualVerifyIcon } from "src/commons/resources/icons";
import { Link } from "react-router-dom";
import { PATHS } from "src/commons/constants/routers";
import { divDecimals } from "src/commons/utils/functions/divDecimals";
import { useSelector } from "react-redux";

import "./styles.scss";
import { useEffect, useState } from "react";
import { NFTService } from "src/services/nft-service";
import ManualVerifyModal from "./methods/manual-verify";

type Props = {
  nft: NFTType;
  address: string;
};

const NFTDetail = ({ address, nft }: Props) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [verification, setVerification] = useState<NFTVerification | null>(null);
  const selectedNetwork = useSelector((state: RootState) => state.system.selectedNetwork);

  const {
    name,
    symbol,
    global_max_nft_mint,
    max_nft_mint_amount_per_user,
    mint_price,
    start_date,
    end_date,
    enable_public_minting,
    provider: { network_id },
  } = nft;

  useEffect(() => {
    let unmount = false;

    const getNFTVerification = async () => {
      setLoading(true);
      setVerification(null);
      try {
        const { data } = await NFTService.getNFTVerification(address);
        if (!unmount) setVerification(data);
      } catch (error) {
        console.log({ error });
      }
      setLoading(false);
    };

    getNFTVerification();

    return () => {
      unmount = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  const origin = window.location.origin;

  return (
    <div className="nft-detail">
      <div className="nft-detail-header">
        <div className="nft-detail-info">
          <div className="nft-info-label">
            <div className="nft-summary">
              {name} ({symbol})
            </div>
            <div className="nft-address">{address}</div>
          </div>
          <div className="nft-info-value network-name">
            {!!selectedNetwork && (
              <div className="nft-network">
                <img src={selectedNetwork.icon_urls[0]} alt={network_id} />
                <span>{selectedNetwork.name}</span>
              </div>
            )}
          </div>
        </div>
        <div className="nft-detail-info">
          <div className="nft-info-label">Contract Address</div>
          <div className="nft-info-value">
            <div className="highlight-text">{address}</div>
            <div className="manual-verify-copy-button">
              <CopyButton text={address} />
            </div>
          </div>
        </div>
        <div className="nft-detail-info">
          <div className="nft-info-label">Minting URL</div>
          <div className="nft-info-value">
            <Link className="highlight-text" to={PATHS.mintingNFT(address)}>
              {`${origin}${PATHS.mintingNFT(address)}`}
            </Link>
            <div className="manual-verify-copy-button">
              <CopyButton text={`${origin}${PATHS.mintingNFT(address)}`} />
            </div>
          </div>
        </div>
        <div className="nft-detail-info">
          <div className="nft-info-label">NFT Type </div>
          <div className="nft-info-value">{"Single asset"}</div>
        </div>
        {!!global_max_nft_mint && (
          <div className="nft-detail-info">
            <div className="nft-info-label">Global max mint amount</div>
            <div className="nft-info-value">{formatNumber(global_max_nft_mint)}</div>
          </div>
        )}
        {!!max_nft_mint_amount_per_user && (
          <div className="nft-detail-info">
            <div className="nft-info-label">Max mint amount per user</div>
            <div className="nft-info-value">{formatNumber(max_nft_mint_amount_per_user)}</div>
          </div>
        )}
        {!!mint_price && (
          <div className="nft-detail-info">
            <div className="nft-info-label">Mint Price</div>
            <div className="nft-info-value">
              {formatNumber(divDecimals(mint_price, selectedNetwork?.native_currency.decimals || 0))}{" "}
              {selectedNetwork?.native_currency.symbol}
            </div>
          </div>
        )}
        {!!start_date && (
          <div className="nft-detail-info">
            <div className="nft-info-label">Start Time</div>
            <div className="nft-info-value">{dayjs(start_date).format("DD MMM YYYY")}</div>
          </div>
        )}
        {!!end_date && (
          <div className="nft-detail-info">
            <div className="nft-info-label">End Time</div>
            <div className="nft-info-value">{dayjs(end_date).format("DD MMM YYYY")}</div>
          </div>
        )}
        <div className="nft-detail-info">
          <div className="nft-info-label">Minting Permission</div>
          <div className="nft-info-value">{enable_public_minting ? "Public" : "Private"}</div>
        </div>
      </div>
      <MethodButton className="manual-verify" disabled={loading || !verification} onClick={() => setVisible(true)}>
        <ResizeIcon icon={ManualVerifyIcon} height={16} />
        Manual Verify
      </MethodButton>
      <ManualVerifyModal verification={verification} open={visible} onClose={() => setVisible(false)} />
    </div>
  );
};

export default NFTDetail;
