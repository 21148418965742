import { FC, ReactNode, useMemo } from "react";
import { useEffect } from "react";
import { Layout, Menu } from "antd";
import { menus } from "src/commons/constants/menus";
import { LogoIcon } from "src/commons/resources/images";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEagerConnect, useWalletListener } from "src/web3/hooks";
import HeaderTop from "src/components/01.layout/header/HeaderTop";
import { setPublicKey } from "src/store/slices/system";
import { useDispatch } from "src/store";
import { PATHS, routers } from "src/commons/constants/routers";
import { AuthService } from "src/services/auth-service";
import { setUserProfile } from "src/store/slices/user";
import { useAuth } from "src/commons/hooks/useAuth";
import Breadcrumb from "src/components/06.breadcrumb";
import ResizeIcon from "../08.resize-icon";

import "./styles.scss";

const LayoutDefault: FC<{ children: ReactNode }> = ({ children }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { loginLocal } = useAuth();

  useEagerConnect();
  useWalletListener();

  useEffect(() => {
    const getPublicKey = async () => {
      try {
        const { data } = await AuthService.getPublicKey();
        if (!data?.publicKey) throw new Error();
        dispatch(setPublicKey(data.publicKey));
      } catch (error) {
        console.log("Cannot get public-key", error);
      }
    };

    const getProfile = async () => {
      try {
        const isLoged = await loginLocal();
        if (!isLoged) return;
        const { data } = await AuthService.getProfile();
        if (!data) throw new Error();
        dispatch(setUserProfile(data));
      } catch (error) {
        console.log("Cannot get user info", error);
      }
    };

    getPublicKey();
    getProfile();
  }, [dispatch, loginLocal]);

  const activePaths = useMemo(
    () =>
      Object.values(PATHS)
        .map(path => (typeof path === "function" ? path() : path))
        .filter(path => pathname.includes(path)),
    [pathname]
  );

  return (
    <Layout id="layout">
      <Layout.Sider trigger={null} width={238} theme="light">
        <Link to={routers.DASHBOARD} className="logo-link">
          <ResizeIcon icon={LogoIcon} width={210} />
        </Link>
        <Menu
          id="sidebar-menu"
          theme="light"
          mode="inline"
          selectedKeys={activePaths}
          items={menus.map(({ path, icon: Icon, label }) => ({
            key: path,
            icon: <Icon />,
            label,
          }))}
          onSelect={item => navigate(item.key)}
          onDeselect={item => navigate(item.key)}
        />
      </Layout.Sider>
      <Layout>
        <HeaderTop />
        {pathname !== PATHS.default ? (
          <Layout.Content className="main">
            <Breadcrumb />
            <div className="page-content">{children}</div>
          </Layout.Content>
        ) : (
          <Layout.Content className="main">
            <div className="landing-page-content">{children}</div>
          </Layout.Content>
        )}
      </Layout>
    </Layout>
  );
};

export default LayoutDefault;
