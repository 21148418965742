import { Rule } from "antd/es/form";
import { ethers, BigNumber as EtherBigNumber } from "ethers";
import {
  BETWEEN_MIN_MAX,
  LENGTH_MIN_MAX,
  MUST_BE_ADDRESS,
  MUST_BE_AFTER_NOW,
  MUST_BE_GREATE_THAN,
  MUST_BE_POSITIVE_NUMBER,
} from "src/commons/messages/validate";
import { decodeCSV } from "./decodeCSV";
import BigNumber from "bignumber.js";
import { isSameAddress } from "src/web3/helpers";

export const isBetWeenMinMax =
  (min: number, max: number, name?: string, message?: string) => (_: Rule, value: string) => {
    if (!value || (Number(value) >= min && Number(value) <= max)) return Promise.resolve();
    return Promise.reject(message || BETWEEN_MIN_MAX(min, max, name));
  };

export const isGreateThan0 = (name?: string) => (_: Rule, value: string) => {
  if (Number(value) || !value) return Promise.resolve();
  return Promise.reject(MUST_BE_POSITIVE_NUMBER(name));
};

export const isGreateThan = (min: number | string, name?: string) => (_: Rule, value: string) => {
  if (Number(value) > Number(min)) return Promise.resolve();
  return Promise.reject(MUST_BE_GREATE_THAN(min, name));
};

export const isLengthMinMax = (min: number, max: number, name?: string) => (_: Rule, value: string) => {
  if (!value || (value.length >= min && value.length <= max)) return Promise.resolve();
  return Promise.reject(LENGTH_MIN_MAX(min, max, name));
};

export const isAddress = (name?: string) => (_: Rule, value: string) => {
  if (!value || ethers.utils.isAddress(value)) return Promise.resolve();
  return Promise.reject(MUST_BE_ADDRESS(name));
};

export const isDistributeTemplateToken =
  (owner?: string, balance?: string, isFile?: () => boolean) => (_: Rule, value: string) => {
    const rows = decodeCSV(value);
    const fileInvalidMessage = isFile?.() ? "File incorrect format" : "";
    if (!rows.length) return Promise.reject(fileInvalidMessage || `Line 1: Missing address`);
    const missingAddressIndex = rows.findIndex(([address]) => !address);
    if (missingAddressIndex + 1)
      return Promise.reject(fileInvalidMessage || `Line ${missingAddressIndex + 1}: Missing address`);
    const invalidSpearatorIndex = rows.findIndex(values => values.find(item => !/^[a-zA-Z0-9.]+$/.test(item)));
    if (invalidSpearatorIndex + 1)
      return Promise.reject(
        fileInvalidMessage || `Line ${invalidSpearatorIndex + 1}: Invalid separator, please use comma, no space`
      );
    const invalidAddressIndex = rows.findIndex(([address]) => !ethers.utils.isAddress(address));
    if (invalidAddressIndex + 1)
      return Promise.reject(fileInvalidMessage || `Line ${invalidAddressIndex + 1}: Invalid address`);
    const matchAddressIndex = rows.findIndex(([address]) => address.toLowerCase() === owner?.toLowerCase());
    if (matchAddressIndex + 1)
      return Promise.reject(
        fileInvalidMessage || `Line ${matchAddressIndex + 1}: Recipient address must be different from holder address`
      );
    const missingAmountIndex = rows.findIndex(([_, amount]) => !amount);
    if (missingAmountIndex + 1)
      return Promise.reject(fileInvalidMessage || `Line ${missingAmountIndex + 1}: Missing number of tokens`);
    const invalidAmountIndex = rows.findIndex(([_, amount]) => !(Number(amount) >= 0));
    if (invalidAmountIndex + 1)
      return Promise.reject(fileInvalidMessage || `Line ${invalidAmountIndex + 1}: Invalid number of token`);
    if (rows.some(([_, __, other]) => other !== undefined))
      return Promise.reject(fileInvalidMessage || "Invalid input format");
    if (rows.reduce((sum, [_, amount]) => Number(amount) + sum, 0) > Number(balance))
      return Promise.reject("Tokens can’t exceed holder’s balance.");
    return Promise.resolve();
  };

export const isAfterNow = (name?: string) => (_: Rule, value?: Dayjs) => {
  if (!value || value.isAfter()) return Promise.resolve();
  return Promise.reject(MUST_BE_AFTER_NOW(name));
};

export const isVestingTemplateToken = (name?: string) => (_: Rule, rows: string[][]) => {
  // try {
  // if (rows.some(([address]) => !ethers.utils.isAddress(address))) return Promise.reject(MUST_BE_ADDRESS("Address"));
  // if (rows.some(([_, amount]) => !amount || !(Number(amount) >= 0))) return Promise.reject(MUST_BE_NUMBER("Amount"));
  // if (rows.some(([_, __, other]) => other !== undefined)) return Promise.reject(INCORRECT_FORMAT(name));
  return Promise.resolve();
  // } catch (error) {}
  // return Promise.reject(INCORRECT_FORMAT(name));
};

export const isNotSame = (origin?: string, message?: string) => (_: Rule, value: string) => {
  if (!value || !isSameAddress(origin, value)) return Promise.resolve();
  return Promise.reject(message);
};

export const isNotExceed =
  (getBalance: Promise<EtherBigNumber | null> | null, decimals: number, message: string) =>
    async (_: Rule, value: string) => {
      if (!getBalance || !value) return Promise.resolve();
      const balance = await getBalance;
      if (new BigNumber(balance?.toString() || 0).div(10 ** decimals).gte(value)) return Promise.resolve();
      return Promise.reject(message);
    };
