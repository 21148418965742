import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: UserState = {
  accessToken: null,
  currentAccount: null,
  wallet: null,
  userProfile: null,
};

// slice
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setAccessToken: (state, actions: PayloadAction<string | null>) => {
      state.accessToken = actions.payload;
    },
    clearAccessToken: state => {
      state.accessToken = null;
    },
    setCurrentAccount: (state, actions: PayloadAction<string | null>) => {
      state.currentAccount = actions.payload;
    },
    setWallet: (state, actions: PayloadAction<ConnectorKey | null>) => {
      state.wallet = actions.payload;
    },
    clearWallet: state => {
      state.wallet = null;
    },
    clearCurrentAccount: state => {
      state.currentAccount = null;
    },
    setUserProfile: (state, actions: PayloadAction<UserProfile>) => {
      state.userProfile = actions.payload;
    },
  },
});

// actions
export const { 
  setCurrentAccount,
  clearCurrentAccount,
  setWallet,
  clearWallet,
  clearAccessToken,
  setUserProfile,
} = userSlice.actions;

// reducer
export const userReducer = userSlice.reducer;
