import { Button } from "antd";
import ModalWithLogo from "src/components/04.modals/ModalWithLogo";
import ResizeIcon from "src/components/08.resize-icon";
import { InfoIcon } from "src/commons/resources/icons";
import CopyButton from "src/components/11.copy-button";
import { useSelector } from "react-redux";
import Web3 from "web3";
import NFTABI from "src/web3/abis/FullFeatureNFT.json";
import { NFTService } from "src/services/nft-service";
import { useMemo, useState } from "react";
import { convertExplorerUrls } from "src/commons/utils/converters/converterExplorerUrls";

import "./styles.scss";

type Props = {
  verification: NFTVerification | null;
  open: boolean;
  onClose: () => void;
};

const ManualVerifyModal = ({ verification, open, onClose }: Props) => {
  const selectedNetwork = useSelector(({ system }: RootState) => system.selectedNetwork);
  const [loading, setLoading] = useState(false);

  const encodeABI = useMemo(() => {
    if (!verification) return "";
    const params = [
      verification.name,
      verification.symbol,
      verification.global_max_nft_mint,
      verification.max_nft_mint_amount_per_user,
      verification.mint_price,
      verification.owner_address,
      verification.enable_public_minting,
      verification.start_date ? Math.floor(new Date(verification.start_date).getTime() / 1000) : 0,
      verification.end_date ? Math.floor(new Date(verification.end_date).getTime() / 1000) : 0,
      verification.media_url,
    ];

    const web3 = (window as any).ethereum ? new Web3((window as any).ethereum) : undefined;
    return web3?.eth.abi.encodeParameters(NFTABI[0].inputs, params).slice(2);
  }, [verification]);

  const attributes = [
    { value: verification?.compilerType, name: "Compiler Type" },
    { value: verification?.compilerVersion, name: "Compiler Version" },
    { value: verification?.openSourceLicenseType, name: "Open Source License Type" },
    { value: encodeABI, name: "Constructor arguments ABI-encoded" },
  ];

  const handleDownload = async () => {
    if (!verification?.address) return;
    setLoading(true);

    try {
      const res = await NFTService.getNFTBuildInfo(verification.address);
      if (!res.data) return;

      const data = "text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(res.data));
      const a = document.createElement("a");
      a.href = "data:" + data;
      a.download = "data.json";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {}
    setLoading(false);
  };

  return (
    <ModalWithLogo open={open} footer={false}>
      <div className="form nft-method-form manual-veriry">
        <div className="nft-method-title">Manual Verification</div>
        <div className="nft-method-note">
          <div className="highlight-text">
            Some block explorers require contacting their support to verify your nft, depending on the chain being used
          </div>
          <ResizeIcon className="nft-method-note-icon" icon={InfoIcon} width={24} height={24} />
        </div>

        <div className="nft-detail-info">
          <div className="nft-info-label">Verification Link</div>
          <div className="nft-info-value">
            <a
              href={`${convertExplorerUrls(selectedNetwork).verify}${verification?.address}`}
              className="highlight-text"
              target="_blank"
              rel="noreferrer"
            >
              {convertExplorerUrls(selectedNetwork).verify}
              {verification?.address}
            </a>
            <div className="manual-verify-copy-button">
              {<CopyButton text={`${convertExplorerUrls(selectedNetwork).verify}${verification?.address}`} />}
            </div>
          </div>
        </div>
        {attributes.map(({ value, name }) => (
          <div key={name} className="nft-detail-info">
            <div className="nft-info-label">{name}</div>
            <div className="nft-info-value">
              {verification && <div className="highlight-text">{value}</div>}
              <div className="manual-verify-copy-button">
                <CopyButton text={value} />
              </div>
            </div>
          </div>
        ))}
        <div className="manual-verify-download">
          <Button onClick={handleDownload} type="link" loading={loading}>
            Download Json
          </Button>
        </div>
        <div className="form-submit">
          <Button className="cancel-button" onClick={onClose}>
            Back
          </Button>
        </div>
      </div>
    </ModalWithLogo>
  );
};

export default ManualVerifyModal;
