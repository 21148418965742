import { stringify } from "qs";
import { BaseService } from "./base-service";

export class VestingService extends BaseService {
  static path = "vestings";
  static TOKEN_LIST = `/${this.path}`;

  static createVestingContract = (body: CreateVestingBody) =>
    this.post<CreateVestingResponse>(`/${this.path}/initialize`, body);

  static updateVestingContractLog = (address: string, body: UpdateVestingLogBody) =>
    this.post<UpdateVestingLogResponse>(`/${this.path}/${address.toLowerCase()}/log`, body);

  static getVestingContracts = (params?: any) => this.get<VestingItem[]>(`/${this.path}?${stringify(params)}`);

  static getVestingContractDetail = (address: string) =>
    this.get<VestingData>(`/${this.path}/${address.toLowerCase()}`);
}
