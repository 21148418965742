import { Tabs, TabsProps } from "antd";
import React from "react";

import "./styles.scss";

const CustomTabs: React.FC<TabsProps> = ({ className, ...props }) => {
  return <Tabs className={`custom-tabs ${className ?? ""}`} {...props} indicatorSize={0} />;
};

export default CustomTabs;
