import { useState } from "react";
import { useSelector } from "react-redux";
import { useErc720Contract } from "src/web3/contracts/useErc720Contract";
import GradientButton from "../03.buttons/GradientButton";
import { useTransaction } from "src/web3/hooks/useTransaction";

type Props = {
  token: string;
  address?: string;
  amount: string;
  onApproved: () => void;
  onFailed: () => void;
  className?: string;
};
const ApproveButton = ({ address, token, amount, onApproved, onFailed, className }: Props) => {
  const [loading, setLoading] = useState(false);
  const { approve } = useErc720Contract(token);
  const { waitForTransaction } = useTransaction();

  const handleApprove = async () => {
    if (!approve || !address) return;

    try {
      setLoading(true);
      const transaction = await approve(address, amount);
      await transaction.wait(1);
      await waitForTransaction(transaction.hash);
      setLoading(false);
      return onApproved();
    } catch (error) {
      setLoading(false);
      console.log(error);
    }

    return onFailed();
  };

  return (
    <GradientButton className={className} loading={loading} onClick={handleApprove}>
      {loading ? "Approving" : "Approve"}
    </GradientButton>
  );
};

export default ApproveButton;
