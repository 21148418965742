import { Button, Form, Radio } from "antd";
import { FormProps } from "antd/lib";
import { useEffect, useState } from "react";
import { REQUIRED } from "src/commons/messages/validate";
import MethodSubmitButton from "src/components/03.buttons/MethodSubmitButton";
import ModalWithLogo from "src/components/04.modals/ModalWithLogo";
import { isAddress } from "src/commons/utils/functions/validates";
import { formatNumber } from "src/commons/utils/functions/formatNumber";
import { useErc720Contract } from "src/web3/contracts/useErc720Contract";
import CustomTextArea from "src/components/07.inputs/TextArea";
import { customToast } from "src/components/02.toasts";

import "./styles.scss";

type Props = {
  token: TokenType;
  address: string;
  open: boolean;
  onClose: () => void;
  totalSupply: string;
};

const FormItem = Form.Item<BlacklistAddressValues>;

const BlacklistAddressModal = ({ token, address, totalSupply, open, onClose }: Props) => {
  const [form] = Form.useForm<BlacklistAddressValues>();
  const [loading, setLoading] = useState(false);
  const [isAdd, setIsAdd] = useState(1);
  const { blackList, removeFromBlacklist } = useErc720Contract(address);

  const { name, decimals } = token;

  const onFinish = async (values: BlacklistAddressValues) => {
    if (!blackList || !removeFromBlacklist) return;
    setLoading(true);
    try {
      const { address } = values;
      const transaction = await (isAdd ? blackList : removeFromBlacklist)(address);
      await transaction?.wait(1);
      customToast.success("Update Blacklist Successfully");
      onClose();
    } catch (error: any) {
      console.log({ error });
      const errors: StringObject = error.response?.data?.errors || { name: "Update Blacklist Failed" };
      form.setFields(Object.entries(errors).map(([key, value]) => ({ name: key, errors: [value] })));
      form.getFieldInstance(Object.keys(errors)[0] || "name")?.focus();
      const errMessage = Object.values(errors)[0];
      customToast.error(typeof errMessage === "string" ? errMessage : "Update Blacklist Failed");
    }
    setLoading(false);
  };

  const onFinishFailed: FormProps["onFinishFailed"] = errorInfo => {
    const namePath = errorInfo.errorFields[0].name?.[0];
    form.getFieldInstance(namePath)?.focus();
  };

  const handleReset = () => {
    form.resetFields();
  };

  useEffect(() => {
    handleReset();
    setIsAdd(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <ModalWithLogo open={open} footer={false}>
      <Form
        form={form}
        className="form token-method-form"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        layout="horizontal"
        onFinish={onFinish}
        validateMessages={{ required: REQUIRED() }}
        onFinishFailed={onFinishFailed}
      >
        <div className="token-method-title">Blacklist Address</div>
        <div className="token-detail-info">
          <div className="token-info-label">Token Name</div>
          <div className="token-info-value">{name}</div>
        </div>
        <div className="token-detail-info">
          <div className="token-info-label">Total Supply</div>
          <div className="token-info-value">{formatNumber(totalSupply, decimals)}</div>
        </div>
        <Radio.Group className="token-blacklist-type" value={isAdd} onChange={e => setIsAdd(Number(e.target.value))}>
          <Radio value={1}>Add To Blacklist</Radio>
          <Radio value={0}>Remove From Black List</Radio>
        </Radio.Group>

        <FormItem
          colon
          label="Black List Address"
          name="address"
          rules={[{ required: true }, { validator: isAddress("Blacklist ") }]}
        >
          <CustomTextArea placeholder="Enter Blacklist Address" rows={4} />
        </FormItem>

        <div className="form-submit">
          <Button className="cancel-button" onClick={onClose}>
            Back
          </Button>
          <MethodSubmitButton htmlType="submit" type="primary" loading={loading}>
            Submit
          </MethodSubmitButton>
        </div>
      </Form>
    </ModalWithLogo>
  );
};

export default BlacklistAddressModal;
