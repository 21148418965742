import { Col, Row } from "antd";
import CreateNFTForm from "src/components/05.forms/CreateNFTForm";
import ResizeIcon from "src/components/08.resize-icon";
import { IdeaIcon } from "src/commons/resources/icons";
import { useSelector } from "react-redux";

import "./styles.scss";

const ideas = [
  "Effortless, speedy, and user-friendly NFT Minter",
  "Effortless Smart Contracts",
  "Gain complete ownership of the NFT you create",
];

const CreateNFTPage = () => {
  const selectedNetwork = useSelector(({ system }: RootState) => system.selectedNetwork);

  return (
    <div>
      <div className="form-title">CREATE NFT SMART CONTRACT ON {selectedNetwork?.name}</div>
      <Row>
        <Col span={24} order={2} lg={{ span: 16, order: 1 }}>
          <div className="nft-page-form">
            <CreateNFTForm />
          </div>
        </Col>
        <Col span={24} order={1} lg={{ span: 8, order: 2 }}>
          <ul className="nft-ideas">
            {ideas.map((idea, index) => (
              <li key={index}>
                <ResizeIcon icon={IdeaIcon} width={15} />
                {idea}
              </li>
            ))}
          </ul>
        </Col>
      </Row>
    </div>
  );
};

export default CreateNFTPage;
