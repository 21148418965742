import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { API_ENDPOINT, REFRESH_TOKEN_KEY, TOKEN_KEY } from "../commons/constants";

const getToken = () => {
  try {
    const token = localStorage.getItem(TOKEN_KEY);
    return token;
  } catch {
    return null;
  }
};

export const handleRefreshToken = async (): Promise<string | null> => {
  const refresh_token = localStorage.getItem(REFRESH_TOKEN_KEY);
  if (refresh_token) {
    try {
      const body: RefreshTokenBody = { refresh_token };
      const res = await axios.post<RefreshTokenResponse>(API_ENDPOINT + "/auth/refresh-token", body);

      localStorage.setItem(TOKEN_KEY, res.data.access_token);
      localStorage.setItem(REFRESH_TOKEN_KEY, res.data.refresh_token);

      return res.data.refresh_token;
    } catch (error) {
      console.log(error);
      localStorage.removeItem(TOKEN_KEY);
      localStorage.removeItem(REFRESH_TOKEN_KEY);
    }
  }
  return null;
};

const defaultAxios = axios.create({ baseURL: API_ENDPOINT });

const onSuccess = (config: InternalAxiosRequestConfig<any>, contentType: string) => {
  const token = getToken();
  if (typeof config.headers?.set === "function") {
    if (token) config.headers?.set("Authorization", "Bearer " + token);
    config.headers?.set("allowRetry", true);
    config.headers?.set("Content-Type", contentType);
  }
  return config;
};

const onError = async (error: { config: AxiosRequestConfig; response: AxiosResponse }, instance: AxiosInstance) => {
  const config = error.config;
  const allowRetry = typeof config.headers?.get === "function" && config.headers?.get("allowRetry");
  if (error.response.status === 401 && allowRetry) {
    const token = await handleRefreshToken();
    if (token && typeof config.headers?.set === "function" && typeof config.headers?.get === "function") {
      config.headers?.set("allowRetry", null);
      config.headers?.set("Authorization", "Bearer " + token);
      return instance(config);
    }
  }
  return Promise.reject(error);
};

defaultAxios.interceptors.request.use(config => onSuccess(config, "application/json"));

defaultAxios.interceptors.response.use(undefined, async error => onError(error, defaultAxios));

const uploadAxios = axios.create({ baseURL: API_ENDPOINT });

uploadAxios.interceptors.request.use(config => onSuccess(config, "multipart/form-data"));

uploadAxios.interceptors.response.use(undefined, async error => onError(error, uploadAxios));

export { uploadAxios };

export default defaultAxios;
