import { Button, Modal } from "antd";
import { LogoIcon } from "src/commons/resources/images";
import ResizeIcon from "src/components/08.resize-icon";
import { CheckedIcon, MetamaskIcon } from "src/commons/resources/icons";
import { Link } from "react-router-dom";
import { PATHS } from "src/commons/constants/routers";
import CopyButton from "src/components/11.copy-button";
import { useSelector } from "react-redux";
import { convertExplorerUrls } from "src/commons/utils/converters/converterExplorerUrls";
import { customToast } from "src/components/02.toasts";

import "./styles.scss";

interface Props {
  onClose: () => void;
  token: TokenCreated | null;
  isOwner?: boolean;
}

const CreatedTokenModal = ({ onClose, token, isOwner }: Props) => {
  const selectedNetwork = useSelector(({ system }: RootState) => system.selectedNetwork);
  const href = convertExplorerUrls(selectedNetwork).address + token?.address;

  const ethereum = window.ethereum as Provider;

  const addToMetamask = async () => {
    if (ethereum && token) {
      try {
        const wasAdded = await ethereum.request({
          method: "wallet_watchAsset",
          params: { type: "ERC20", options: token },
        });
        if (wasAdded) return customToast.success("Add to Metamask successfully!");
      } catch (error) {
        console.log(error);
      }
    }
    customToast.error("Please accept add token request!");
  };

  return (
    <Modal centered closeIcon={null} width={845} footer={null} className={"created-modal"} open={!!token}>
      <div className="created-modal-header">
        <ResizeIcon icon={LogoIcon} width={210} />
      </div>
      <div className="created-modal-title">Token Successfully Created</div>
      <div className="created-modal-icon">
        <ResizeIcon icon={CheckedIcon} width={130} />
      </div>
      <div className="created-modal-description">
        Token address:{" "}
        <a href={href} className="highlight-text">
          {token?.address}
        </a>{" "}
        <CopyButton text={token?.address} />
      </div>
      <div className="created-modal-description">
        Token Link:{" "}
        <a href={href} className="highlight-text">
          {href}
        </a>{" "}
        <CopyButton text={href} />
      </div>
      <Link className="goto-manager" to={PATHS.managerToken(isOwner ? token?.address : undefined)}>
        Go to manage token
      </Link>
      {!!ethereum && (
        <Button className="add-to-metamask" onClick={addToMetamask}>
          Add to metamask
          <img src={MetamaskIcon} alt="metamask" />
        </Button>
      )}
      <Button className="close-to-more" type="link" onClick={onClose}>
        Create more tokens
      </Button>
    </Modal>
  );
};

export default CreatedTokenModal;
