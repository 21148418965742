import { Button, ButtonProps } from "antd";
import React from "react";

import "./styles.scss";

const MethodButton: React.FC<ButtonProps> = ({ className, children, ...props }) => {
  return (
    <Button className={`method-button ${className || ""}`} {...props}>
      {children}
    </Button>
  );
};

export default MethodButton;
