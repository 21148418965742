import { useWeb3React } from "@web3-react/core";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { convertExplorerUrls } from "src/commons/utils/converters/converterExplorerUrls";
import { useEffect, useState } from "react";
import { EventService } from "src/services/event-service";

import "./styles.scss";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const NFTList = () => {
  const [loading, setLoading] = useState(true);
  const [nfts, setNFTs] = useState<NFTItem[]>([]);
  const isAuth = useSelector(({ user }: RootState) => !!user.currentAccount);
  const selectedNetwork = useSelector(({ system }: RootState) => system.selectedNetwork);
  const { account } = useWeb3React();
  const logged = isAuth && account;
  useEffect(() => {
    const getData = async () => {
      if (!account) return;
      setLoading(true);
      try {
        const { data } = await EventService.getMintingNFTs(account);
        setNFTs(data);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };

    if (logged) getData();
  }, [logged, account]);

  return (
    <div className="nft-mint-list">
      {loading ? (
        <div className="loading">
          <Spin indicator={<LoadingOutlined size={24} />} />
        </div>
      ) : (
        <>
          <div className="nft-mint-item">
            <div className="nft-mint-label">Name</div>
            <div className="nft-mint-label">Owner</div>
          </div>
          {nfts.map(item => {
            const href = convertExplorerUrls(selectedNetwork).address + account;
            return (
              <div key={item.address} className="nft-mint-item">
                <div className="nft-mint-label">{item.name}</div>
                <div className="nft-mint-hashs">
                  <Link to={href}>{item.owner_address}</Link>
                </div>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};

export default NFTList;
