import { ToastContainer } from "react-toastify";
import { ToastErrorIcon, ToastInfoIcon, ToastSuccessIcon } from "src/commons/resources/icons";

import "./toast.scss";

const ToastContext = () => {
  return (
    <ToastContainer
      closeButton={false}
      containerId="toast"
      className="toast-container"
      icon={props => {
        switch (props.type) {
          case "error":
            return <ToastErrorIcon className="toast-error-icon" />;
          case "success":
            return <ToastSuccessIcon className="toast-success-icon" />;
          default:
            return <ToastInfoIcon className="toast-info-icon" />;
        }
      }}
      bodyClassName="body-toast"
      toastClassName={props => {
        switch (props?.type) {
          case "error":
            return "toast-text-error";
          case "success":
            return "toast-text-success";
          default:
            return "";
        }
      }}
      autoClose={3000}
      draggable={false}
      hideProgressBar
      pauseOnHover={false}
      position="top-right"
      limit={5}
    />
  );
};

export default ToastContext;
