import { Button, Form, Spin } from "antd";
import { FormProps } from "antd/lib";
import { useEffect, useState } from "react";
import { REQUIRED } from "src/commons/messages/validate";
import MethodSubmitButton from "src/components/03.buttons/MethodSubmitButton";
import ModalWithLogo from "src/components/04.modals/ModalWithLogo";
import { formatNumber } from "src/commons/utils/functions/formatNumber";
import { useErc720Contract } from "src/web3/contracts/useErc720Contract";
import ResizeIcon from "src/components/08.resize-icon";
import { InfoIcon } from "src/commons/resources/icons";
import CustomSwitch from "src/components/10.switch";
import { LoadingOutlined } from "@ant-design/icons";
import { customToast } from "src/components/02.toasts";

import "./styles.scss";

type Props = {
  token: TokenType;
  address: string;
  open: boolean;
  onClose: () => void;
  totalSupply: string;
};

const FormItem = Form.Item<PauseTokenValues>;

const PauseTokenModal = ({ token, address, open, totalSupply, onClose }: Props) => {
  const [form] = Form.useForm<PauseTokenValues>();
  const [loading, setLoading] = useState(false);
  const [isChecking, setIsChecking] = useState(false);
  const { paused, pause, unpause } = useErc720Contract(address);

  const { name, decimals } = token;

  const onFinish = async (values: PauseTokenValues) => {
    if (!pause || !unpause) return;
    setLoading(true);
    try {
      const { status } = values;
      const transaction = await (status ? pause : unpause)();
      await transaction?.wait(1);
      customToast.success("Pause Token Successfully");
      onClose();
    } catch (error: any) {
      console.log({ error });
      const errors: StringObject = error.response?.data?.errors || { name: "Pause Token Failed" };
      form.setFields(Object.entries(errors).map(([key, value]) => ({ name: key, errors: [value] })));
      form.getFieldInstance(Object.keys(errors)[0] || "name")?.focus();
      const errMessage = Object.values(errors)[0];
      customToast.error(typeof errMessage === "string" ? errMessage : "Pause Token Failed");
    }
    setLoading(false);
  };

  const onFinishFailed: FormProps["onFinishFailed"] = errorInfo => {
    const namePath = errorInfo.errorFields[0].name?.[0];
    form.getFieldInstance(namePath)?.focus();
  };

  useEffect(() => {
    let unmount = false;
    const getPausedStatus = async () => {
      if (!paused) return;
      setIsChecking(true);
      try {
        const isPaused = await paused();
        if (!unmount) form.setFieldValue("status", isPaused);
      } catch (error) {
        console.log(error);
      }
      if (!unmount) setIsChecking(false);
    };
    if (open && token && paused) getPausedStatus();
    return () => {
      unmount = false;
    };
  }, [open, token, paused, form]);

  return (
    <ModalWithLogo open={open} footer={false}>
      <Form
        form={form}
        className="form token-method-form"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        layout="horizontal"
        onFinish={onFinish}
        validateMessages={{ required: REQUIRED() }}
        onFinishFailed={onFinishFailed}
      >
        <div className="token-method-title">Pause Token</div>
        <div className="token-detail-info">
          <div className="token-info-label">Token Name</div>
          <div className="token-info-value">{name}</div>
        </div>
        <div className="token-detail-info">
          <div className="token-info-label">Total Supply</div>
          <div className="token-info-value">{formatNumber(totalSupply, decimals)}</div>
        </div>
        <FormItem>
          <div className="expand-input">
            <FormItem noStyle name="status" valuePropName="checked">
              <CustomSwitch />
            </FormItem>
            <FormItem noStyle dependencies={["status"]}>
              {() => (
                <div className="field-info">
                  {isChecking ? (
                    <Spin indicator={<LoadingOutlined size={24} />} />
                  ) : form.getFieldValue("status") ? (
                    <b>Pause</b>
                  ) : (
                    ""
                  )}
                </div>
              )}
            </FormItem>
          </div>
        </FormItem>

        <div className="token-method-note">
          <div className="highlight-text">
            The pausing can be used to prevent if being exposed to a malicious attack.
          </div>
          <ResizeIcon className="token-method-note-icon" icon={InfoIcon} width={24} height={24} />
        </div>

        <div className="form-submit">
          <Button className="cancel-button" onClick={onClose}>
            Back
          </Button>
          <MethodSubmitButton htmlType="submit" type="primary" loading={loading}>
            Submit
          </MethodSubmitButton>
        </div>
      </Form>
    </ModalWithLogo>
  );
};

export default PauseTokenModal;
